import ContentButton from 'components/shared/ContentButton';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/AppTitle.scss';

const TitleButton = (props) => <ContentButton as="h1" {...props} />;

class AppTitle extends Component {
  render() {
    const { appSettingsStore } = this.props;
    const { appTitleToggled, toggleAppTitle } = appSettingsStore;

    const langProp = appTitleToggled ? {} : { lang: 'ja' };
    const title = appTitleToggled ? 'Kananji - A Kanji Puzzle Game' : 'カナンジ、漢字パズルゲーム';

    return (
      <TitleButton className="app-title" theme="light" onClick={toggleAppTitle} {...langProp}>
        {title}
      </TitleButton>
    );
  }
}

export default inject('appSettingsStore')(observer(AppTitle));
