import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/MainPanel.scss';
import AnswerPanel from './answers/AnswerPanel';
import KanjiGrid from './kanji/KanjiGrid';
import GamePreferences from './preferences/GamePreferences';

class MainPanel extends Component {
  render() {
    const { gameSettingsStore } = this.props;
    const { keepKanjiInView } = gameSettingsStore;

    return (
      <div
        className={classNames('main-panel', {
          'keep-kanji-in-view': keepKanjiInView,
        })}
      >
        <KanjiGrid />
        <AnswerPanel />
        <GamePreferences />
      </div>
    );
  }
}

export default inject('gameSettingsStore')(observer(MainPanel));
