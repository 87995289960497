import queryString from 'query-string';
import urlHelper from './urlHelper';

const isLocalhost = () => {
  return urlHelper.getURL().includes('localhost');
};

const getParsedQueryString = () => {
  return queryString.parse(urlHelper.getQueryString());
};

const isToggleOn = (name) => {
  const value = getParsedQueryString()[name];
  if (value === 'false') {
    return false;
  }

  return isLocalhost() || value === null || value === 'true';
};

const isDevMode = () => {
  return isToggleOn('dev');
};

export default {
  getParsedQueryString,
  isLocalhost,
  isDevMode,
};
