import { message, notification } from 'antd';
import { reaction } from 'mobx';
import React from 'react';
import MaterialIcon from 'components/shared/MaterialIcon';

const showAPIKeyNotification = () => {
  notification.open({
    message: 'API Key Needed',
    description: (
      <span>
        Only a few levels can be played without a WaniKani API Key. Click the vertical dots in the
        header to add a key.
      </span>
    ),
    icon: (
      <>
        <MaterialIcon>vpn_key</MaterialIcon>
        <br />
        <MaterialIcon>more_vert</MaterialIcon>
      </>
    ),
    duration: 10,
    placement: 'bottomRight',
  });
};

const showNetworkErrorNotification = () => {
  notification.open({
    message: 'Network Error',
    description: <span>Unable to load data from WaniKani. Check your internet connection.</span>,
    icon: <MaterialIcon>wifi_off</MaterialIcon>,
    duration: 6,
    placement: 'bottomRight',
  });
};

const showInputNotification = (message, type = 'error', propOverrides) => {
  notification[type]({
    // Always using the same key ensures that only one notification shows at a time
    key: 'input-notification',
    message,
    duration: 2,
    placement: 'bottomRight',
    className: 'ant-notification-notice-with-empty-description',
    ...propOverrides,
  });
};

const showAlreadyAnsweredNotification = () => {
  showInputNotification('Already answered', 'info');
};

const showKanjiKanaMismatchNotification = () => {
  showInputNotification('Kanji / Kana mismatch');
};

const showIncorrectKanjiNotification = () => {
  showInputNotification('Incorrect kanji');
};

const showIncorrectKanaNotification = () => {
  showInputNotification('Incorrect kana');
};

const showBlankKanjiNotification = () => {
  showInputNotification("What's the kanji?");
};

const showBlankKanaNotification = () => {
  showInputNotification("What's the kana?");
};

const showCorrectAnswerNotification = (completedVocabData) => {
  showInputNotification(completedVocabData.meaningsString, 'success', {
    duration: 4,
  });
};

let removeLoadingMessage;
const showLoadingIndicator = (loading) => {
  if (loading) {
    removeLoadingMessage = message.loading('Loading', 0);
  } else if (removeLoadingMessage) {
    removeLoadingMessage();
  }
};

const registerCounterReaction = (expression, effect) => {
  reaction(expression, (counter) => {
    // Ignore state resets
    if (!counter) return;

    effect(counter);
  });
};

const register = (gameStore) => {
  const { dataStore, answerStats } = gameStore;

  message.config({
    maxCount: 1,
  });

  registerCounterReaction(() => dataStore.apiKeyErrorCount, showAPIKeyNotification);

  registerCounterReaction(() => dataStore.networkErrorCount, showNetworkErrorNotification);

  registerCounterReaction(() => answerStats.duplicateAnswerCount, showAlreadyAnsweredNotification);

  registerCounterReaction(
    () => answerStats.answerWithKanjiKanaMismatchCount,
    showKanjiKanaMismatchNotification,
  );

  registerCounterReaction(
    () => answerStats.answerWithIncorrectKanjiCount,
    showIncorrectKanjiNotification,
  );

  registerCounterReaction(
    () => answerStats.answerWithIncorrectKanaCount,
    showIncorrectKanaNotification,
  );

  registerCounterReaction(() => answerStats.answerWithBlankKanjiCount, showBlankKanjiNotification);

  registerCounterReaction(() => answerStats.answerWithBlankKanaCount, showBlankKanaNotification);

  registerCounterReaction(() => {
    const { completedVocab } = gameStore;
    const { length } = completedVocab;
    if (length === 0) {
      return;
    }

    return completedVocab[0];
  }, showCorrectAnswerNotification);

  reaction(() => gameStore.loading, showLoadingIndicator);
};

export default {
  register,
};
