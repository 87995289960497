export const calculateGridDimensions = (length) => {
  let columnCount = Math.ceil(Math.sqrt(length));

  // Ensure that there is always an odd number of columns so that
  // the initial empty cell can be centered horizontally
  if (columnCount % 2 === 0) {
    columnCount -= 1;
  }

  const rowCount = Math.ceil(length / columnCount);

  return { columnCount, rowCount };
};

export const getFlatIndex = (columnCount, columnIndex, rowIndex) =>
  columnCount * rowIndex + columnIndex;

export const calculateVisualCenterIndex = (columnCount, rowCount) => {
  const centerColumnIndex = Math.ceil(columnCount / 2) - 1;
  const centerRowIndex = Math.ceil(rowCount / 2) - 1;
  return getFlatIndex(columnCount, centerColumnIndex, centerRowIndex);
};

const RELATIVE_NEIGHBOR_COORDS = [
  [-1, -1],
  [0, -1],
  [1, -1],
  [-1, 0],
  [1, 0],
  [-1, 1],
  [0, 1],
  [1, 1],
];

export const getNeighbors = (listItem, list, columnCount, rowCount) => {
  const index = list.indexOf(listItem);
  if (index === -1) return [];

  const columnIndex = index % columnCount;
  const rowIndex = Math.floor(index / columnCount);
  const neighborIndices = RELATIVE_NEIGHBOR_COORDS.map(([nC, nR]) => [
    columnIndex + nC,
    rowIndex + nR,
  ])
    .filter(([c, r]) => c >= 0 && c < columnCount && r >= 0 && r < rowCount)
    .map(([c, r]) => getFlatIndex(columnCount, c, r))
    .filter((i) => i < list.length);

  return neighborIndices.map((i) => list[i]);
};
