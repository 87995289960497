import { Pagination } from 'antd';
import classNames from 'classnames';
import { action, observable, reaction, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'styles/LevelPicker.scss';
import ResetConfirmation from './ResetConfirmation';

class LevelPicker extends Component {
  requestedLevel = 1;

  resetConfirmationTrigger = 0;

  constructor(props) {
    super(props);

    makeObservable(this, {
      requestedLevel: observable,
      resetConfirmationTrigger: observable,
      handleChange: action,
    });
  }

  componentDidMount() {
    const { gameStore } = this.props;

    // Interaction with the DOM is necessary because the Pagination
    // component does not allow the input to be disabled via props.
    const inputElement = ReactDOM.findDOMNode(this).querySelector('input');

    if (inputElement) {
      inputElement.setAttribute('aria-label', 'Current Level');

      reaction(
        () => gameStore.loading,
        (loading) => {
          inputElement.disabled = loading;
        },
      );
    }
  }

  handleChange = (level) => {
    const { gameStore } = this.props;
    if (!gameStore.loading) {
      this.requestedLevel = level;
      this.resetConfirmationTrigger += 1;
    }
  };

  loadLevel = () => {
    const { gameStore } = this.props;
    gameStore.loadLevel(this.requestedLevel);
  };

  render() {
    const { className, gameStore } = this.props;
    const { loading } = gameStore;

    // current must always have a value from the very beginning so that
    // React does not warn about controlled vs uncontrolled components
    const current = gameStore.level || 1;

    return (
      <ResetConfirmation
        forceVisibleTrigger={() => this.resetConfirmationTrigger}
        onReset={this.loadLevel}
      >
        <Pagination
          className={classNames(className, 'level-picker', {
            'level-picker-disabled': loading,
          })}
          simple
          defaultPageSize={1}
          total={60}
          current={loading ? this.requestedLevel : current}
          onChange={this.handleChange}
        />
      </ResetConfirmation>
    );
  }
}

export default inject('gameStore')(observer(LevelPicker));
