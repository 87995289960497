import { observer } from 'mobx-react';
import React, { Component } from 'react';

class NewTabLink extends Component {
  render() {
    const { className, href, children } = this.props;

    if (!href) {
      return <span className={className}>{children}</span>;
    }

    return (
      <a {...this.props} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
}

export default observer(NewTabLink);
