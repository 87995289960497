import { destroy, getEnv, types } from 'mobx-state-tree';
import Selection from './models/Selection';

const SelectionsStore = types
  .model('SelectionsStore', {
    selections: types.array(Selection),
  })
  .views((self) => ({
    get selectionsAsString() {
      return self.selections.map((selection) => selection.value).join('');
    },
    get hasSelections() {
      return self.selections.length > 0;
    },
    isKanjiSelected(kanjiData) {
      return self.selections.some((selection) => selection.kanjiData === kanjiData);
    },
  }))
  .actions((self) => ({
    selectKanji(kanjiData) {
      if (kanjiData.kanji && kanjiData.enabled && !kanjiData.solved) {
        self.selections.push({
          id: getEnv(self).generateId(),
          kanjiData,
        });
      }
    },
    deselect(selection) {
      destroy(selection);
    },
    deselectAll() {
      self.selections.clear();
    },
  }));

export default SelectionsStore;
