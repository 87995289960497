import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/AppContent.scss';
import Game from '../../game/Game';

class AppContent extends Component {
  render() {
    return (
      <main className="app-content">
        <div className="app-content-area">
          <Game />
        </div>
      </main>
    );
  }
}

export default observer(AppContent);
