import {
  extractComponentIds,
  filterByIds,
  filterByLevel,
  parseLevelResponseData,
} from './dataParser';
import fallbackKanjiResponsePayload from './fallbackData/kanjiResponsePayload';
import fallbackVocabResponsePayload from './fallbackData/vocabResponsePayload';
import localStorageHelper from './localStorageHelper';
import waniKaniAPI from './waniKaniAPI';

const LOCAL_STORAGE_LEVEL = 'wk';

const getLocalStorageLevelKey = (level) => LOCAL_STORAGE_LEVEL + level;

const loadLevelFromLocalStorage = (level) => {
  const parsedLevelData = localStorageHelper.getItem(getLocalStorageLevelKey(level));

  return parsedLevelData;
};

const loadLevelFromAPI = async (level, apiKey) => {
  waniKaniAPI.setKey(apiKey);
  const vocabResponse = await waniKaniAPI.getVocabularyByLevel(level);
  const vocabResponseData = vocabResponse.data.data;
  const kanjiIds = extractComponentIds(vocabResponseData);
  const kanjiResponse = await waniKaniAPI.getSubjectsByIds(kanjiIds);
  const kanjiResponseData = kanjiResponse.data.data;
  const parsedLevelData = parseLevelResponseData(level, vocabResponseData, kanjiResponseData);

  localStorageHelper.setItem(getLocalStorageLevelKey(level), parsedLevelData);

  return parsedLevelData;
};

const loadLevelFromFallbackData = (level) => {
  const vocabData = filterByLevel(fallbackVocabResponsePayload.data, level);
  const kanjiIds = extractComponentIds(vocabData);
  const kanjiData = filterByIds(fallbackKanjiResponsePayload.data, kanjiIds);
  const parsedLevelData = parseLevelResponseData(level, vocabData, kanjiData);
  return parsedLevelData;
};

export default {
  loadLevelFromLocalStorage,
  loadLevelFromAPI,
  loadLevelFromFallbackData,
};
