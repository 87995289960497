import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ScalableSelectionIcon.scss';

class ScalableSelectionIcon extends Component {
  render() {
    const { isVisible } = this.props;

    return (
      <svg
        className={classNames('scalable-selection-icon', {
          'scalable-selection-icon-visible': isVisible(),
        })}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <polygon className="scalable-selection-icon-poly" points="0,100 100,0 100,100" />
      </svg>
    );
  }
}

export default observer(ScalableSelectionIcon);
