import { Form, Collapse } from 'antd';
import LabeledItem from 'components/shared/LabeledItem';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/SettingsPanel.scss';
import ContactEmailLink from './links/ContactEmailLink';
import PayPalLink from './links/PayPalLink';
import WaniKaniLink from './links/WaniKaniLink';
import ResetAppButton from './ResetAppButton';
import WaniKaniSettings from './WaniKaniSettings';

const { Panel } = Collapse;

const SettingsPanel = () => {
  const [form] = Form.useForm();

  const panelProps = { showArrow: true };

  return (
    <Collapse className="settings-panel" defaultActiveKey="1" bordered={false} accordion>
      <Panel key="1" header="API Key" {...panelProps}>
        <WaniKaniSettings form={form} />
      </Panel>
      <Panel key="2" header="About the App" {...panelProps}>
        <p>
          Kananji is a Japanese kanji and vocabulary review tool / puzzle game that can be used as
          an unofficial companion app for {<WaniKaniLink />}. Kananji is free (donations welcome)
          but requires a WaniKani subscription beyond level 3. See the How to Play section for
          instructions.
        </p>
        <p>
          WaniKani is a Japanese kanji and vocabulary learning website that uses a Spaced Repetition
          System (SRS) along with mnemonics to teach English speakers how to read Japanese.
        </p>
      </Panel>
      <Panel key="3" header="How to Play" {...panelProps}>
        <p>The game has three main sections:</p>
        <ul>
          <li>A grid of kanji to select from</li>
          <li>An input for full word kana translations</li>
          <li>A list of vocabulary to solve</li>
        </ul>
        <p>
          The goal of the game is to solve all of the listed vocabulary words. To solve a word you
          need to select the kanji used by the word in the correct word order and then type the full
          word (with okurigana) in kana.
        </p>
        <p>
          The catch is that not all kanji are enabled. Only kanji that border an empty cell on the
          horizontal, vertical, or diagonal axis are enabled, and only enabled kanji can be selected
          to solve words. In the beginning of the game a single center cell of the kanji grid will
          be empty and all 8 neighboring kanji will be enabled. As words are solved, additional
          empty cells will replace kanji that are no longer used by unsolved words.
        </p>
      </Panel>
      <Panel key="4" header="Tips" {...panelProps}>
        <ul>
          <li>
            The tabs on the right-hand side of the page show the same information, but with
            different levels of detail. You can use the tabs to adjust the difficulty of the game to
            match your skill level and preferences. You can even look at the answers, assuming you
            know how to read kana. Play however you like.
          </li>
          <li>
            Clicking on the English meanings for a vocabulary word will open the corresponding
            WaniKani page.
          </li>
          <li>Ctrl + Clicking on a kanji in the grid will open the corresponding WaniKani page.</li>
          <li>
            If a level is loaded with all cells enabled, then you won the lottery...the algorithm
            failed to rearrange kanji to make the game solvable. This happens very rarely, but if it
            does you can decide whether to play an easy game or click the Reset Level button to
            generate a new game.
          </li>
          <li>
            If the game has you feeling down because it is difficult, just remember that the game is
            meant to be a challenge even for WaniKani veterans. Each puzzle you solve is an amazing
            accomplishment. You are awesome.
          </li>
          <li>
            If you want a quick gameplay demo, then add ?dev to the end of the URL. This will enable
            special development features such as Auto Solve and Instant Win.
            <br />
            <a href="https://kananji.com/?dev">https://kananji.com/?dev</a>
          </li>
        </ul>
      </Panel>
      <Panel key="5" header="Acknowledgements" {...panelProps}>
        <p>
          I am not affiliated with {<WaniKaniLink />} or Tofugu LLC. I just really like them and
          decided to give back to the community by developing an unofficial 3rd party WaniKani
          companion app that displays WaniKani data in an interesting puzzle format.
        </p>
        <p>
          I have taken special precautions to ensure that WaniKani/Tofugu copyright is not
          infringed:
        </p>
        <ul>
          <li>
            The app will only ever show data that is exposed by the WaniKani public API (so no
            mnemonics)
          </li>
          <li>
            The app requires a WaniKani API Key beyond the levels that are free on WaniKani anyway
          </li>
          <li>Clicking on vocabulary links back to WaniKani</li>
        </ul>
        <p>
          See the Technical Details section for a summary of the open source technologies that were
          used to build this app.
        </p>
      </Panel>
      <Panel key="6" header="Disclaimer" {...panelProps}>
        <p>
          It is up to you to decide how and when to use Kananji so that you do not interfere with,
          or even harm, your WaniKani progression.
        </p>
        <p>Examples of when you might want to use Kananji:</p>
        <ul>
          <li>To study ahead when you have a lot of time and WaniKani is too slow for you</li>
          <li>
            You are a WaniKani graduate with a ton of burned/deactivated words that you are starting
            to forget
          </li>
          <li>
            You want to practice translating English to Japanese instead of Japanese to English
          </li>
        </ul>
      </Panel>
      <Panel key="7" header="Technical Details" {...panelProps}>
        <p>
          This app was created using JavaScript/ES6 and cutting edge open source technologies.
          Mainly React for the User Interface (UI) and Mobx/Mobx-State-Tree for state management.
          Ant Design, a React UI library, was used to build a good looking and feature-rich UI
          quickly. WanaKana, a mobile-friendly and lightweight Japanese Hiragana and Katakana IME,
          was used to build the kana input.
        </p>
        <p>
          This is a responsive single page Progressive Web Application (PWA). Responsive means that
          the app is mobile friendly and can respond to screen-size/window-size changes to make the
          best use of available space. PWA means that the web app complies with the latest web
          standards for performance, security, accessibility, etc. PWAs cache files for an improved
          offline experience and can be installed on mobile devices so that they appear to be native
          mobile apps with an icon on the home screen and a hidden URL bar.
        </p>
        <p>
          The app does not use a dedicated server or database. The API Key provided by you and the
          kanji/vocabulary requested from {<WaniKaniLink />} are stored in your own web browser (via
          localStorage). Data is never sent or stored elsewhere. This keeps things simple, but means
          that you will have to re-enter your API Key if you clear your browser history or click the
          Reset App button.
        </p>
        <p>
          Your progress on the current level is saved to localStorage in real time, so you can close
          the tab at any time and resume later as long as you do not clear your browser history.
          Progress is not saved between levels, so navigating to a different level and then back
          again will clear your progress.
        </p>
      </Panel>
      <Panel key="8" header="What's a Kananji?" {...panelProps}>
        <p>
          Kananji is just a made up word. I picked it because it was short and catchy. It kind of
          sounds like &quot;kana n&#39;kanji&quot; if you say it quickly. Also, ji means
          character/letter in Japanese, so Kananji could be interpreted as conundrum of characters
          since this is a character puzzle game.
        </p>
      </Panel>
      <Panel key="9" header="Contact Me" {...panelProps}>
        <p>
          You can contact me at
          <br />
          <ContactEmailLink />
        </p>
      </Panel>
      <Panel key="10" header="Donate" {...panelProps}>
        <p>
          I developed this app by myself as a passion project. It took a ton of time and effort for
          no pay other than the satisfaction of realizing my vision to create a challenging kanji
          puzzle game that is actually useful to myself and others. If you like the app and want to
          make my day, then consider donating :)
        </p>
        <LabeledItem text="PayPal:">
          <PayPalLink />
        </LabeledItem>
        <br />
      </Panel>
      <Panel key="11" header="Reset App" {...panelProps}>
        <ResetAppButton form={form} />
        <p>
          Deletes all data and resets the app. This should not be needed, but may come in handy if
          you are experiencing problems.
        </p>
      </Panel>
    </Collapse>
  );
};

export default observer(SettingsPanel);
