const withGameStats = (GameStore) =>
  GameStore.views((self) => ({
    get hasProgress() {
      return self.completedVocab.length > 0;
    },
    get isWon() {
      return self.remainingVocab.length === 0;
    },
    get completedKanjiCount() {
      return self.completedKanji.length;
    },
    get totalKanjiCount() {
      // -1 to account for the origin kanji
      return self.kanjiList.length - 1;
    },
    get completedKanjiPercent() {
      return self.completedKanjiCount / self.totalKanjiCount;
    },
    get completedVocabCount() {
      return self.completedVocab.length;
    },
    get totalVocabCount() {
      return self.vocabList.length;
    },
    get completedVocabPercent() {
      return self.completedVocabCount / self.totalVocabCount;
    },
  }));

export default withGameStats;
