import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/AppHeader.scss';
import AppTitle from './AppTitle';
import SettingsButton from './SettingsButton';

class AppHeader extends Component {
  render() {
    return (
      <header className="app-header">
        <div className="app-header-content">
          <AppTitle />
          <div className="app-header-charms">
            <SettingsButton />
          </div>
        </div>
      </header>
    );
  }
}

export default observer(AppHeader);
