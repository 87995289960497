import noScroll from 'no-scroll';

export const focusWithoutScroll = (element) => {
  if (!element) {
    return;
  }

  // Turn off document scrolling.
  // This is needed to prevent Firefox from scrolling input elements
  // into view on focus even when preventScroll is true.
  noScroll.on();

  element.focus({
    // This does not have good browser support yet, but works great in Chrome.
    preventScroll: true,
  });

  // Restore document scrolling.
  noScroll.off();
};
