import { Progress, Tooltip } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/VocabProgressBar.scss';

class VocabProgressBar extends Component {
  formatPercent = (percent) => Math.round(percent * 100);

  render() {
    const { className, gameStore } = this.props;
    const { completedVocabCount, totalVocabCount, completedVocabPercent } = gameStore;

    return (
      <Tooltip title={`${completedVocabCount} / ${totalVocabCount} Vocab`} placement="right">
        <Progress
          className={classNames(className, 'vocab-progress-bar')}
          percent={this.formatPercent(completedVocabPercent)}
        />
      </Tooltip>
    );
  }
}

export default inject('gameStore')(observer(VocabProgressBar));
