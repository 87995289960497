import { BackTop } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/VocabCards.scss';
import VocabCard from './VocabCard';

class VocabCards extends Component {
  render() {
    const { vocabList, showKanaHints, showAnswers, celebrateWhenEmpty, gameStore } = this.props;

    if (vocabList.length > 0) {
      return (
        <>
          <div className="vocab-cards">
            {vocabList.map((vocabData) => (
              <VocabCard
                className="vocab-cards-card"
                key={vocabData.id}
                vocabData={vocabData}
                showKanaHints={showKanaHints}
                showAnswers={showAnswers}
              />
            ))}
          </div>
          <BackTop className="back-to-top-button" />
        </>
      );
    }

    let emptyMessage;
    if (gameStore.loading) {
      emptyMessage = 'Loading...';
    } else if (celebrateWhenEmpty) {
      emptyMessage = 'You Win!';
    } else {
      emptyMessage = 'No Items';
    }

    return (
      <div className="vocab-cards-no-items-message-container">
        <div className="vocab-cards-no-items-message">{emptyMessage}</div>
      </div>
    );
  }
}

export default inject('gameStore')(observer(VocabCards));
