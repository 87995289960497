import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

class MaterialIcon extends Component {
  render() {
    const { className } = this.props;
    return <i {...this.props} className={classNames(className, 'material-icons')} />;
  }
}

export default observer(MaterialIcon);
