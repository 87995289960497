import { types } from 'mobx-state-tree';
import AnswerStats from '../models/AnswerStats';

const findVocabMatch = (vocabList, kanjiAnswer, kanaAnswer) => {
  let hadKanjiMatch;
  let hadKanaMatch;

  const vocabMatch = vocabList.find((vocabData) => {
    // eslint-disable-next-line max-len
    const isKanjiMatch = vocabData.kanjiString === kanjiAnswer;
    if (isKanjiMatch) {
      hadKanjiMatch = true;
    }

    const isKanaMatch = vocabData.readings.includes(kanaAnswer);
    if (isKanaMatch) {
      hadKanaMatch = true;
    }

    return isKanjiMatch && isKanaMatch;
  });

  return { vocabMatch, hadKanjiMatch, hadKanaMatch };
};

const withAnswerSubmission = (GameStore) =>
  GameStore.props({
    answerStats: types.optional(AnswerStats, {}),
  }).actions((self) => ({
    submitAnswer(kanaAnswer) {
      const { selectionsAsString: kanjiAnswer } = self.selectionsStore;

      const { vocabMatch, hadKanjiMatch, hadKanaMatch } = findVocabMatch(
        self.remainingVocab,
        kanjiAnswer,
        kanaAnswer,
      );

      if (vocabMatch) {
        self.solveVocab(vocabMatch);
        self.selectionsStore.deselectAll();
      } else {
        const { vocabMatch: completedVocabMatch } = findVocabMatch(
          self.completedVocab,
          kanjiAnswer,
          kanaAnswer,
        );

        const hadCompletedVocabMatch = !!completedVocabMatch;

        self.answerStats.updateIncorrectAnswerStats(
          kanjiAnswer,
          kanaAnswer,
          hadKanjiMatch,
          hadKanaMatch,
          hadCompletedVocabMatch,
        );
      }
    },
  }));

export default withAnswerSubmission;
