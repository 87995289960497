import { Progress } from 'antd';
import Label from 'components/shared/Label';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ProgressSummary.scss';

class ProgressSummary extends Component {
  formatPercent = (percent) => Math.round(percent * 100);

  render() {
    const { gameStore } = this.props;
    const {
      completedKanjiCount,
      totalKanjiCount,
      completedKanjiPercent,
      completedVocabCount,
      totalVocabCount,
      completedVocabPercent,
    } = gameStore;

    return (
      <div className="progress-summary">
        <div className="progress-summary-indicator">
          <Progress type="dashboard" percent={this.formatPercent(completedKanjiPercent)} />
          <Label large>{`${completedKanjiCount} / ${totalKanjiCount} Kanji`}</Label>
        </div>
        <div className="progress-summary-indicator">
          <Progress type="dashboard" percent={this.formatPercent(completedVocabPercent)} />
          <Label large>{`${completedVocabCount} / ${totalVocabCount} Vocab`}</Label>
        </div>
      </div>
    );
  }
}

export default inject('gameStore')(observer(ProgressSummary));
