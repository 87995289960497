import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/App.scss';
import AppContent from './content/AppContent';
import AppFooter from './footer/AppFooter';
import AppHeader from './header/AppHeader';

class App extends Component {
  render() {
    return (
      <div className="app">
        <AppHeader />
        <AppContent />
        <AppFooter />
      </div>
    );
  }
}

export default observer(App);
