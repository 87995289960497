import { Provider } from 'mobx-react';
import React from 'react';

export const flattenStores = (rootStore) => {
  const { appSettingsStore, dataStore, gameStore } = rootStore;
  const { gameSettingsStore, selectionsStore } = gameStore;
  const { waniKaniSettingsStore } = dataStore;
  return {
    rootStore,
    appSettingsStore,
    dataStore,
    gameStore,
    gameSettingsStore,
    selectionsStore,
    waniKaniSettingsStore,
  };
};

const StoreProvider = ({ rootStore, children }) => (
  <Provider {...flattenStores(rootStore)}>{children}</Provider>
);

export default StoreProvider;
