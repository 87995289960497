import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import IconButton from 'components/shared/IconButton';
import MaterialIcon from 'components/shared/MaterialIcon';
import SettingsDrawer from './settings/SettingsDrawer';

class SettingsButton extends Component {
  settingsVisible = false;

  handleShowSettings = () => {
    this.settingsVisible = true;
  };

  handleHideSettings = () => {
    this.settingsVisible = false;
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      settingsVisible: observable,
      handleShowSettings: action,
      handleHideSettings: action,
    });
  }

  render() {
    return (
      <>
        <IconButton theme="light" size="medium" onClick={this.handleShowSettings}>
          <MaterialIcon>more_vert</MaterialIcon>
        </IconButton>
        <SettingsDrawer
          settingsVisible={this.settingsVisible}
          onHideSettings={this.handleHideSettings}
        />
      </>
    );
  }
}

export default observer(SettingsButton);
