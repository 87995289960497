import { types } from 'mobx-state-tree';

const AppSettingsStore = types
  .model('AppSettingsStore', {
    appTitleToggled: false,
  })
  .actions((self) => ({
    toggleAppTitle() {
      self.appTitleToggled = !self.appTitleToggled;
    },
  }));

export default AppSettingsStore;
