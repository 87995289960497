import { types } from 'mobx-state-tree';

const AnswerStats = types
  .model('AnswerStats', {
    incorrectAnswerCount: 0,
    duplicateAnswerCount: 0,
    answerWithKanjiKanaMismatchCount: 0,
    answerWithIncorrectKanjiCount: 0,
    answerWithIncorrectKanaCount: 0,
    answerWithBlankKanjiCount: 0,
    answerWithBlankKanaCount: 0,
  })
  .actions((self) => ({
    updateIncorrectAnswerStats(
      kanjiAnswer,
      kanaAnswer,
      hadKanjiMatch,
      hadKanaMatch,
      hadCompletedVocabMatch,
    ) {
      self.incorrectAnswerCount += 1;

      if (!kanjiAnswer) {
        self.answerWithBlankKanjiCount += 1;
      } else if (!kanaAnswer) {
        self.answerWithBlankKanaCount += 1;
      } else if (hadCompletedVocabMatch) {
        self.duplicateAnswerCount += 1;
      } else if (hadKanjiMatch && hadKanaMatch) {
        self.answerWithKanjiKanaMismatchCount += 1;
      } else if (hadKanjiMatch) {
        self.answerWithIncorrectKanaCount += 1;
      } else if (hadKanaMatch) {
        self.answerWithIncorrectKanjiCount += 1;
      }
    },
  }));

export default AnswerStats;
