import countBy from 'lodash/countBy';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { getEnv, types } from 'mobx-state-tree';
import GameSettingsStore from './GameSettingsStore';
import {
  calculateGridDimensions,
  calculateVisualCenterIndex,
  getNeighbors,
} from './gameStoreExtensions/gridHelpers';
import withAnswerSubmission from './gameStoreExtensions/withAnswerSubmission';
import withGameStats from './gameStoreExtensions/withGameStats';
import withLevelLoading from './gameStoreExtensions/withLevelLoading';
import KanjiData from './models/KanjiData';
import VocabData from './models/VocabData';
import SelectionsStore from './SelectionsStore';

const ORIGIN_KANJI_ID = -1;

const GameStore = types
  .model('GameStore', {
    gameSettingsStore: types.optional(GameSettingsStore, {}),
    selectionsStore: types.optional(SelectionsStore, {}),
    kanjiList: types.array(types.reference(KanjiData)),
    vocabList: types.array(types.reference(VocabData)),
    // completedVocab is not computed in order to preserve insertion order
    completedVocab: types.array(types.reference(VocabData)),
    originKanji: types.optional(KanjiData, {
      id: ORIGIN_KANJI_ID,
      url: '',
      kanji: '',
    }),
    solvable: false,
    columnCount: 0,
    rowCount: 0,
  })
  .views((self) => ({
    get remainingKanji() {
      return self.kanjiList.filter((kanjiData) => !kanjiData.solved);
    },
    get completedKanji() {
      return self.kanjiList.filter(
        (kanjiData) => kanjiData.id !== ORIGIN_KANJI_ID && kanjiData.solved,
      );
    },
    get remainingVocab() {
      return self.vocabList.filter((vocabData) => !vocabData.solved);
    },
    get solvableVocab() {
      return self.vocabList.filter((vocabData) => vocabData.solvable);
    },
  }))
  .actions((self) => {
    const { shuffle } = getEnv(self);

    return {
      onDataLoad() {
        const { kanjiList } = self.dataStore;

        // + 1 to account for the origin kanji
        const length = kanjiList.length + 1;
        const { columnCount, rowCount } = calculateGridDimensions(length);

        self.columnCount = columnCount;
        self.rowCount = rowCount;

        self.generateLevel();
      },
      generateLevel() {
        const { columnCount, rowCount } = self;
        const centerIndex = calculateVisualCenterIndex(columnCount, rowCount);
        const { dataStore } = self;
        const shuffledKanjiIds = shuffle(dataStore.kanjiIds);
        shuffledKanjiIds.splice(centerIndex, 0, self.originKanji.id);
        const shuffledVocabIds = shuffle(dataStore.vocabIds);
        self.kanjiList.replace(shuffledKanjiIds);
        self.vocabList.replace(shuffledVocabIds);

        // Make sure to set solvable to true before calling resetState
        // because resetState will enable all kanji if the game is not solvable.
        self.solvable = true;

        // reset state before running the solvability algorithm
        self.resetState();

        // Used to keep track of kanji that have already been used to solve words.
        // Kanji in the collection will not be considered for rearrangement.
        const positionLockedKanji = new Set();

        // check for solvability and swap kanji as needed
        while (self.remainingVocab.length > 0 && self.solvable) {
          let madeProgress = false;

          self.solvableVocab.forEach((vocabData) => {
            self.solveVocab(vocabData);
            madeProgress = true;
            // Keep track of which kanji have been used so that the algorithm
            // can ignore them when rearranging kanji to make the game solvable.
            vocabData.kanjiList.forEach((kanjiData) => {
              positionLockedKanji.add(kanjiData);
            });
          });

          if (!madeProgress) {
            // count duplicate kanji to determine how many vocab words use them
            const kanjiFrequencyInVocabMap = countBy(
              flatten(self.remainingVocab.map((vocabData) => vocabData.kanjiList)),
            );

            // select the word with kanji that have the fewest dependencies on other words
            const vocabToSwapIn = sortBy(self.remainingVocab, (vocabData) =>
              sumBy(vocabData.kanjiList, (kanjiData) => kanjiFrequencyInVocabMap[kanjiData]),
            )[0];

            const listOfDisabledKanjiToSwapIn = vocabToSwapIn.kanjiList.filter(
              (kanjiData) => !kanjiData.enabled,
            );

            // shuffle to keep things unpredictable
            const listOfEnabledKanjiToSwapOut = shuffle(
              self.remainingKanji.filter(
                (kanjiData) =>
                  kanjiData.enabled &&
                  !positionLockedKanji.has(kanjiData) &&
                  !vocabToSwapIn.kanjiList.includes(kanjiData),
              ),
            );

            if (listOfEnabledKanjiToSwapOut.length >= listOfDisabledKanjiToSwapIn.length) {
              listOfDisabledKanjiToSwapIn.forEach((kanjiToSwapIn) => {
                const kanjiToSwapOut = listOfEnabledKanjiToSwapOut.pop();

                const indexOfKanjiToSwapIn = self.kanjiList.indexOf(kanjiToSwapIn);

                const indexOfKanjiToSwapOut = self.kanjiList.indexOf(kanjiToSwapOut);

                self.kanjiList[indexOfKanjiToSwapOut] = kanjiToSwapIn.id;
                self.kanjiList[indexOfKanjiToSwapIn] = kanjiToSwapOut.id;
                kanjiToSwapIn.enable();
                kanjiToSwapOut.resetState();
              });
            } else {
              self.solvable = false;
            }
          }
        }

        // reset state again after the solvability algorithm
        self.resetState();
      },
      resetState() {
        self.selectionsStore.deselectAll();
        self.answerStats = {};

        self.kanjiList.forEach((kanjiData) => {
          kanjiData.resetState();
        });

        self.vocabList.forEach((vocabData) => {
          vocabData.resetState();
        });

        self.completedVocab.clear();

        if (self.solvable) {
          self.originKanji.enable();
          self.enableNeighboringKanji(self.originKanji);
        } else {
          // if not solvable, then simply enable all kanji
          self.enableAllKanji();
        }
      },
      solveVocab(vocabData) {
        vocabData.solve();
        self.completedVocab.unshift(vocabData.id);

        vocabData.kanjiList.forEach((kanjiData) => {
          if (kanjiData.solved) {
            self.enableNeighboringKanji(kanjiData);
          }
        });
      },
      enableNeighboringKanji(kanjiData) {
        const neighboringKanji = getNeighbors(
          kanjiData,
          self.kanjiList,
          self.columnCount,
          self.rowCount,
        );

        neighboringKanji.forEach((neighbor) => {
          neighbor.enable();
        });
      },
      enableAllKanji() {
        self.kanjiList.forEach((kanjiData) => {
          kanjiData.enable();
        });
      },
      autoSolve() {
        self.selectionsStore.deselectAll();
        if (self.solvableVocab.length > 0) {
          self.solveVocab(self.solvableVocab[0]);
        }
      },
      instantWin() {
        self.selectionsStore.deselectAll();

        while (self.remainingVocab.length > 0) {
          let madeProgress = false;
          self.solvableVocab.forEach((vocabData) => {
            self.solveVocab(vocabData);
            madeProgress = true;
          });

          if (!madeProgress) {
            break;
          }
        }
      },
    };
  });

export default withLevelLoading(withAnswerSubmission(withGameStats(GameStore)));
