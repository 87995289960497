import throttle from 'lodash/throttle';
import { configure as configureMobx } from 'mobx';
import { onSnapshot, setLivelynessChecking } from 'mobx-state-tree';
import localStorageHelper from './data/localStorageHelper';
import mobxEnvFactory from './stores/env/mobxEnvFactory';
import RootStore from './stores/RootStore';

const LOCAL_STORAGE_STATE = 'state';

configureMobx({
  // Prevent setting state outside of mobx actions
  enforceActions: 'observed',
});

// configure mobx state tree
setLivelynessChecking('error');

export const createAndConfigureRootStore = () => {
  const mobxEnv = mobxEnvFactory();

  let rootStore;
  try {
    // Load persisted state from local storage
    const initialState = localStorageHelper.getItem(LOCAL_STORAGE_STATE) || {};
    rootStore = RootStore.create(initialState, mobxEnv);
  } catch (err) {
    // Start fresh if old state is no longer compatible with the app
    rootStore = RootStore.create({}, mobxEnv);
  }

  window.APP_STATE = rootStore;

  onSnapshot(
    rootStore,
    throttle((snapshot) => {
      localStorageHelper.setItem(LOCAL_STORAGE_STATE, snapshot);
    }, 1000),
  );

  return rootStore;
};
