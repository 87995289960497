import classNames from 'classnames';
import NewTabLink from 'components/shared/NewTabLink';
import RaisedButton from 'components/shared/RaisedButton';
import { action, observable, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/KanjiCell.scss';
import ScalableCharacter from './ScalableCharacter';
import ScalableSelectionIcon from './ScalableSelectionIcon';

// Also check the meta key to support MacBooks
const isCtrlKey = (event) => event.ctrlKey || event.metaKey;

class KanjiCell extends Component {
  disabled = false;

  focused = false;

  handleRaisedButtonClick = (event) => {
    if (!isCtrlKey(event)) {
      const { selectionsStore, kanjiData } = this.props;
      selectionsStore.selectKanji(kanjiData);
    }
  };

  handleLinkClick = (event) => {
    if (isCtrlKey(event)) {
      // Allow the browser to handle the click on the link.
      // Stop the event from propagating to prevent cell selection.
      event.stopPropagation();
    } else {
      // Otherwise, ignore the click on the link.
      event.preventDefault();
    }
  };

  handleLinkFocus = () => {
    this.focused = true;
  };

  handleLinkBlur = () => {
    this.focused = false;
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      disabled: observable,
      focused: observable,
      handleLinkFocus: action,
      handleLinkBlur: action,
    });
  }

  render() {
    const { kanjiData, selectionsStore, gameSettingsStore } = this.props;
    const { showKanjiHints } = gameSettingsStore;

    const { solved } = kanjiData;
    const disabled = !kanjiData.enabled;
    const highlighted = showKanjiHints && kanjiData.hasSolvableVocab;

    return (
      <RaisedButton
        className={classNames('kanji-cell', {
          'kanji-cell-highlighted': highlighted,
          'kanji-cell-disabled': disabled,
          'kanji-cell-solved': solved,
        })}
        focusOverride={this.focused}
        disabled={disabled}
        onClick={this.handleRaisedButtonClick}
        aria-label={solved ? 'Empty' : kanjiData.kanji}
        // set a negative tabIndex when the kanji is visible,
        // so that focus lands on the kanji link right away
        tabIndex={solved ? 0 : -1}
      >
        <NewTabLink
          className="kanji-cell-link"
          href={kanjiData.url}
          onClick={this.handleLinkClick}
          onFocus={this.handleLinkFocus}
          onBlur={this.handleLinkBlur}
        >
          <ScalableCharacter>{kanjiData.kanji}</ScalableCharacter>
          <ScalableSelectionIcon isVisible={() => selectionsStore.isKanjiSelected(kanjiData)} />
        </NewTabLink>
      </RaisedButton>
    );
  }
}

export default inject('selectionsStore', 'gameSettingsStore')(observer(KanjiCell));
