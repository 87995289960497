import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/AppFooter.scss';
import CopyrightNotice from './CopyrightNotice';

class AppFooter extends Component {
  render() {
    return (
      <footer className="app-footer">
        <CopyrightNotice />
      </footer>
    );
  }
}

export default observer(AppFooter);
