import { types } from 'mobx-state-tree';
import KanjiData from './KanjiData';

const VocabData = types
  .model('VocabData', {
    id: types.identifierNumber,
    url: types.string,
    characters: types.string,
    meanings: types.array(types.string),
    readings: types.array(types.string),
    partsOfSpeech: types.array(types.string),
    // late reference to avoid circular dependency issues with KanjiData
    kanjiList: types.array(types.reference(types.late(() => KanjiData))),
    solved: false,
  })
  .views((self) => {
    const arrayToString = (array) => array.join(', ');

    const charactersWithMask = (maskFn) => {
      const kanjiCharacters = self.kanjiList.map((kanjiData) => kanjiData.kanji);
      return self.characters
        .split('')
        .map((c) => maskFn(c, kanjiCharacters.includes(c)))
        .join('');
    };

    return {
      get meaningsString() {
        return arrayToString(self.meanings);
      },
      get readingsString() {
        return arrayToString(self.readings);
      },
      get partsOfSpeechString() {
        return arrayToString(self.partsOfSpeech);
      },
      get kanaHintString() {
        return charactersWithMask((character, isKanji) => (isKanji ? ' ＿ ' : character)).replace(
          new RegExp('\\s\\s', 'g'),
          ' ',
        );
      },
      get kanjiString() {
        return charactersWithMask((character, isKanji) => (isKanji ? character : ''));
      },
      get solvable() {
        return !self.solved && self.kanjiList.every((kanjiData) => kanjiData.enabled);
      },
    };
  })
  .actions((self) => ({
    solve() {
      self.solved = true;
    },
    resetState() {
      self.solved = false;
    },
  }));

export default VocabData;
