import { flow, types } from 'mobx-state-tree';
import localStorageHelper from '../data/localStorageHelper';
import AppSettingsStore from './AppSettingsStore';
import DataStore from './DataStore';
import GameStore from './GameStore';

const RootStore = types
  .model('RootStore', {
    appSettingsStore: types.optional(AppSettingsStore, {}),
    dataStore: types.optional(DataStore, {}),
    gameStore: types.optional(GameStore, {}),
  })
  .views((self) => ({
    get loading() {
      return self.gameStore.loading;
    },
  }))
  .actions((self) => ({
    resetState: flow(function* resetState() {
      // force the service worker to update its cache
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }

      // delete all data from local storage
      localStorageHelper.clear();

      self.appSettingsStore = {};
      self.dataStore = {};
      self.gameStore = {};

      yield self.gameStore.loadDefaultLevel();
    }),
  }));

export default RootStore;
