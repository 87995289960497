import Label from 'components/shared/Label';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/KanjiSelectionsPanel.scss';
import ClearSelectionsButton from './ClearSelectionsButton';
import KanjiSelection from './KanjiSelection';

class KanjiSelections extends Component {
  handleDeselect = (selection) => {
    const { selectionsStore } = this.props;
    selectionsStore.deselect(selection);
  };

  render() {
    const { selectionsStore } = this.props;
    const { hasSelections } = selectionsStore;
    return (
      <div className="kanji-selections">
        <div className="kanji-selections-container">
          {hasSelections &&
            selectionsStore.selections.map((selection) => (
              <KanjiSelection
                className="kanji-selections-selection"
                key={selection.id}
                selection={selection}
                onClick={this.handleDeselect}
              />
            ))}
          {!hasSelections && (
            <>
              <Label className="kanji-selections-prompt">Select kanji from above</Label>
              {/* A hidden selection used for container sizing */}
              <KanjiSelection
                className="kanji-selections-selection"
                selection={{
                  id: 'fakeSelection',
                  kanjiData: { id: 0, kanji: '天' },
                }}
                style={{ visibility: 'hidden' }}
              />
            </>
          )}
        </div>
        {hasSelections && <ClearSelectionsButton />}
      </div>
    );
  }
}

export default inject('selectionsStore')(observer(KanjiSelections));
