import React from 'react';
import { Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState, useRef } from 'react';
import { toKana } from 'wanakana';
import ShakeAnimation from 'components/animations/ShakeAnimation';
import { focusWithoutScroll } from 'helpers/focusWithoutScroll';

const KanaInput = ({ gameStore, selectionsStore, gameSettingsStore }) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const kana = toKana(event.target.value, { IMEMode: true });
    setValue(kana);
  };

  const clearInput = () => {
    setValue('');
  };

  useEffect(() => {
    return reaction(() => gameStore.completedVocab.length, clearInput);
  }, []);

  const autoFocusInput = () => {
    if (!gameSettingsStore.autoFocusInput) {
      return;
    }

    focusWithoutScroll(inputRef.current);
  };

  useEffect(() => {
    return reaction(() => selectionsStore.selections.length, autoFocusInput);
  }, []);

  const handleSubmit = (value) => {
    // The value should already be in kana, but may have a trailing
    // 'n' that should get auto-converted to 'ん'.
    const kana = toKana(value);
    setValue(kana);

    gameStore.submitAnswer(kana);
  };

  const placeholder = 'full word in kana';

  return (
    <ShakeAnimation trigger={() => gameStore.answerStats.incorrectAnswerCount}>
      <Input.Search
        ref={inputRef}
        placeholder={placeholder}
        aria-label={placeholder}
        enterButton={<RightOutlined aria-label="Submit" />}
        value={value}
        onChange={handleChange}
        onSearch={handleSubmit}
      />
    </ShakeAnimation>
  );
};

export default inject('gameStore', 'selectionsStore', 'gameSettingsStore')(observer(KanaInput));
