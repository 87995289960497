import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/Label.scss';

class Label extends Component {
  render() {
    const { className, inline, secondary, large, bold, ...otherProps } = this.props;

    return (
      <div
        className={classNames(className, 'label', {
          'label-inline': inline,
          'label-secondary': secondary,
          'label-large': large,
          'label-bold': bold,
        })}
        {...otherProps}
      />
    );
  }
}

export default observer(Label);
