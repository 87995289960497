import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Label from './Label';

class LabeledItem extends Component {
  render() {
    const { className, text, children } = this.props;

    return (
      <div className={classNames(className, 'labeled-item')}>
        <Label bold>{text}</Label>
        {children}
      </div>
    );
  }
}

export default observer(LabeledItem);
