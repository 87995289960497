import { action, observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ShakeAnimation.scss';

class ShakeAnimation extends Component {
  active = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      active: observable,
      handleTrigger: action,
      handleAnimationEnd: action,
    });
  }

  componentDidMount() {
    const { trigger } = this.props;
    reaction(trigger, this.handleTrigger);
  }

  handleTrigger = (value) => {
    if (value) {
      this.active = true;
    }
  };

  handleAnimationEnd = () => {
    this.active = false;
  };

  render() {
    const { children } = this.props;

    return (
      <div
        className={this.active ? 'shake-animation' : ''}
        onAnimationEnd={this.handleAnimationEnd}
      >
        {children}
      </div>
    );
  }
}

export default observer(ShakeAnimation);
