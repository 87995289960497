import queryStringHelper from 'helpers/queryStringHelper';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ControlPanel.scss';
import AutoSolveButton from './controls/AutoSolveButton';
import InstantWinButton from './controls/InstantWinButton';
import LevelPicker from './controls/LevelPicker';
import ResetLevelButton from './controls/ResetLevelButton';

class ControlPanel extends Component {
  render() {
    const isDevMode = queryStringHelper.isDevMode();

    return (
      <div className="control-panel">
        <LevelPicker className="level-picker" />
        <div className="control-panel-buttons">
          <ResetLevelButton />
          {isDevMode && <AutoSolveButton />}
          {isDevMode && <InstantWinButton />}
        </div>
      </div>
    );
  }
}

export default observer(ControlPanel);
