import { Switch } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/SwitchItem.scss';
import Label from './Label';

class SwitchItem extends Component {
  render() {
    const { checked, onChange, text } = this.props;

    return (
      <div className="switch-item">
        <Switch checked={checked} onChange={onChange} aria-label={text} />
        <Label inline>{text}</Label>
      </div>
    );
  }
}

export default observer(SwitchItem);
