import { flow, types } from 'mobx-state-tree';
import waniKaniAPI from '../data/waniKaniAPI';

const trimKey = (key) => {
  return (key || '').trim();
};

const WaniKaniSettingsStore = types
  .model('WaniKaniSettingsStore', {
    key: types.maybe(types.string),
    keyValid: types.maybe(types.boolean),
  })
  .views((self) => ({
    get keyHasValue() {
      return !!self.key;
    },
    get keyMaybeValid() {
      return self.keyHasValue && self.keyValid !== false;
    },
    get keyValidated() {
      return self.keyValid !== undefined;
    },
    isKeySameAsCurrent(key) {
      return trimKey(self.key) === trimKey(key);
    },
    isKeyValidated(key) {
      return self.isKeySameAsCurrent(key) && self.keyValidated;
    },
  }))
  .actions((self) => ({
    setKey: flow(function* setKey(key) {
      // Don't do anything if the given key has already been validated.
      // The key may not have been validated if the internet was down,
      // in which case the user should be allowed to resubmit
      if (self.isKeyValidated(key)) return;

      self.key = trimKey(key);
      self.setKeyValid(undefined);

      if (self.keyMaybeValid) {
        try {
          waniKaniAPI.setKey(self.key);
          yield waniKaniAPI.getUser();
          self.setKeyValid(true);
        } catch (err) {
          self.setKeyInvalidIfStatusError(err);
        }
      }
    }),
    setKeyValid(keyValid) {
      self.keyValid = keyValid;
    },
    setKeyInvalidIfStatusError(err) {
      const { response } = err;
      if (response) {
        const { status } = response;
        if (status && status === 401) {
          self.setKeyValid(false);
        }
      }
    },
  }));

export default WaniKaniSettingsStore;
