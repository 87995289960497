import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from './components/app/App';
import { createAndConfigureRootStore } from './rootStoreConfig';
import * as serviceWorkerHelper from './serviceWorkerHelper';
import StoreProvider from './stores/StoreProvider';

const rootStore = createAndConfigureRootStore();

ReactDOM.render(
  <StoreProvider rootStore={rootStore}>
    <App />
  </StoreProvider>,
  document.querySelector('#root'),
);

// Progressive Web App feature used to cache assets.
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerHelper.register();
