import { observer } from 'mobx-react';
import React, { Component } from 'react';

const isSelectionKey = ({ key, ctrlKey }) => (key === 'Enter' || key === ' ') && !ctrlKey;

const withMouseAndKeyboardSelection = (WrappedComponent) => {
  class WithMouseAndKeyboardSelection extends Component {
    handleClick = (event) => {
      const { onClick, disabled } = this.props;
      if (onClick && !disabled) {
        onClick(event);
      }
    };

    handleKeyDown = (event) => {
      if (isSelectionKey(event)) {
        event.preventDefault();

        if (!this.selectionKeyDown) {
          this.selectionKeyDown = event.key;
          this.handleClick(event);
        }
      }
    };

    handleKeyUp = ({ key }) => {
      if (key === this.selectionKeyDown) {
        this.selectionKeyDown = null;
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
        />
      );
    }
  }

  return WithMouseAndKeyboardSelection;
};

export default withMouseAndKeyboardSelection;
