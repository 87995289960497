import { types } from 'mobx-state-tree';
import KanjiData from './KanjiData';

const Selection = types
  .model('Selection', {
    id: types.identifier,
    kanjiData: types.reference(KanjiData),
  })
  .views((self) => ({
    get value() {
      return self.kanjiData.kanji;
    },
  }));

export default Selection;
