import React, { Component } from 'react';
import NewTabLink from 'components/shared/NewTabLink';

const url = 'https://paypal.me/mhennessy116';

class PayPalLink extends Component {
  render() {
    const { asButton } = this.props;

    if (asButton) {
      return (
        <NewTabLink href={url}>
          <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" alt="Donate" />
        </NewTabLink>
      );
    }

    return <NewTabLink href={url}>paypal.me/mhennessy116</NewTabLink>;
  }
}

export default PayPalLink;
