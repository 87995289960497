import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/Game.scss';
import ControlPanel from './header/ControlPanel';
import MainPanel from './main/MainPanel';
import notifications from './notifications';
import SidePanel from './side/SidePanel';

class Game extends Component {
  componentDidMount() {
    const { gameStore } = this.props;
    notifications.register(gameStore);
  }

  render() {
    const { gameSettingsStore } = this.props;
    const { increaseKanjiSize } = gameSettingsStore;

    return (
      <div
        className={classNames('game', {
          'increase-kanji-size': increaseKanjiSize,
        })}
      >
        <section className="game-header-content">
          <ControlPanel />
        </section>
        <section className="game-main-content">
          <MainPanel />
        </section>
        <section className="game-side-content">
          <SidePanel />
        </section>
      </div>
    );
  }
}

export default inject('gameStore', 'gameSettingsStore')(observer(Game));
