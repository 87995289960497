/* eslint-disable */

export default {
  object: 'collection',
  url: 'https://api.wanikani.com/v2/subjects?levels=1%2C2%2C3&types=kanji',
  pages: {
    per_page: 1000,
    next_url: null,
    previous_url: null,
  },
  total_count: 89,
  data_updated_at: '2018-09-11T19:32:30.907916Z',
  data: [
    {
      id: 440,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/440',
      data_updated_at: '2018-05-21T21:51:46.011695Z',
      data: {
        created_at: '2012-02-27T19:55:19.000000Z',
        level: 1,
        slug: '一',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%80',
        characters: '一',
        meanings: [
          {
            meaning: 'One',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'いち',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ひと',
            accepted_answer: false,
          },
          {
            type: 'nanori',
            primary: false,
            reading: 'かず',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [1],
        amalgamation_subject_ids: [
          2467,
          2468,
          2477,
          2510,
          2544,
          2588,
          2627,
          2660,
          2665,
          2672,
          2679,
          2721,
          2730,
          2751,
          2959,
          3048,
          3256,
          3335,
          3348,
          3349,
          3372,
          3481,
          3527,
          3528,
          3656,
          3663,
          4133,
          4173,
          4258,
          4282,
          4563,
          4615,
          4701,
          4823,
          4906,
          5050,
          5224,
          5237,
          5349,
          5362,
          5838,
          6010,
          6029,
          6150,
          6169,
          6209,
          6210,
          6346,
          6584,
          6614,
          6723,
          6811,
          6851,
          7037,
          7293,
          7305,
          7451,
          7561,
          7617,
          7734,
          7780,
          7927,
          8209,
          8214,
          8414,
          8456,
          8583,
          8709,
        ],
      },
    },
    {
      id: 441,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/441',
      data_updated_at: '2018-05-21T21:51:46.040970Z',
      data: {
        created_at: '2012-02-27T20:15:43.000000Z',
        level: 1,
        slug: '二',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%BA%8C',
        characters: '二',
        meanings: [
          {
            meaning: 'Two',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'に',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ふた',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [7],
        amalgamation_subject_ids: [
          2473,
          2474,
          2476,
          2545,
          2589,
          2628,
          2683,
          2712,
          2960,
          3049,
          3153,
          3237,
          3339,
          3347,
          3373,
          3374,
          4141,
          4826,
          5238,
          6048,
          6615,
          6992,
          7515,
          7565,
        ],
      },
    },
    {
      id: 442,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/442',
      data_updated_at: '2018-05-21T21:51:46.074323Z',
      data: {
        created_at: '2012-02-27T22:33:25.000000Z',
        level: 1,
        slug: '九',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B9%9D',
        characters: '九',
        meanings: [
          {
            meaning: 'Nine',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'く',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'きゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ここの',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [11],
        amalgamation_subject_ids: [2471, 2472, 2864, 7614],
      },
    },
    {
      id: 443,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/443',
      data_updated_at: '2018-05-21T21:51:46.100962Z',
      data: {
        created_at: '2012-02-27T22:38:57.000000Z',
        level: 1,
        slug: '七',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%83',
        characters: '七',
        meanings: [
          {
            meaning: 'Seven',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'しち',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'なな',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [4],
        amalgamation_subject_ids: [2469, 2470, 7562],
      },
    },
    {
      id: 444,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/444',
      data_updated_at: '2018-05-21T21:51:46.128605Z',
      data: {
        created_at: '2012-02-27T22:41:23.000000Z',
        level: 1,
        slug: '人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%BA%BA',
        characters: '人',
        meanings: [
          {
            meaning: 'Person',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'にん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'じん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ひと',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'と',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [9],
        amalgamation_subject_ids: [
          2475,
          2476,
          2477,
          2478,
          2479,
          2488,
          2502,
          2582,
          2604,
          2632,
          2655,
          2674,
          2691,
          2715,
          2719,
          2782,
          2887,
          2989,
          2995,
          3014,
          3061,
          3069,
          3141,
          3202,
          3232,
          3254,
          3264,
          3356,
          3358,
          3402,
          3407,
          3412,
          3420,
          3452,
          3540,
          3606,
          3621,
          3677,
          3699,
          3708,
          3724,
          3748,
          3793,
          3862,
          3898,
          3899,
          3907,
          3936,
          4130,
          4153,
          4158,
          4203,
          4257,
          4305,
          4344,
          4365,
          4385,
          4433,
          4909,
          4964,
          4982,
          4985,
          5014,
          5144,
          5224,
          5474,
          5510,
          5524,
          5733,
          6029,
          6154,
          6209,
          6260,
          6283,
          6450,
          6613,
          6870,
          7218,
          7462,
          7465,
          7517,
          7560,
          7757,
          7783,
          8005,
          8255,
          8384,
          8551,
          8604,
          8659,
          8735,
          8805,
        ],
      },
    },
    {
      id: 445,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/445',
      data_updated_at: '2018-05-21T21:51:46.155630Z',
      data: {
        created_at: '2012-02-27T22:43:03.000000Z',
        level: 1,
        slug: '入',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%A5',
        characters: '入',
        meanings: [
          {
            meaning: 'Enter',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'にゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'はい',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'い',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [12],
        amalgamation_subject_ids: [
          2480,
          2481,
          2498,
          2671,
          2790,
          2831,
          3036,
          3039,
          3592,
          3869,
          4105,
          4180,
          4312,
          4354,
          4590,
          4976,
          5212,
          5248,
          5370,
          5388,
          5507,
          5674,
          5880,
          5914,
          6553,
          6850,
          6966,
          6995,
          7116,
          7267,
          7628,
          8733,
        ],
      },
    },
    {
      id: 446,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/446',
      data_updated_at: '2018-05-21T21:51:46.180608Z',
      data: {
        created_at: '2012-02-27T22:47:16.000000Z',
        level: 1,
        slug: '八',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%AB',
        characters: '八',
        meanings: [
          {
            meaning: 'Eight',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'はち',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'や',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [2],
        amalgamation_subject_ids: [2482, 2483, 4172, 7615],
      },
    },
    {
      id: 447,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/447',
      data_updated_at: '2018-05-21T21:51:46.205201Z',
      data: {
        created_at: '2012-02-28T01:32:14.000000Z',
        level: 1,
        slug: '力',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8A%9B',
        characters: '力',
        meanings: [
          {
            meaning: 'Power',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Strength',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'りょく',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'りき',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ちから',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [13],
        amalgamation_subject_ids: [
          2484,
          2807,
          2855,
          2970,
          2991,
          2998,
          3081,
          3104,
          3269,
          3536,
          3661,
          3722,
          4018,
          4176,
          4211,
          4266,
          4397,
          4523,
          4808,
          5135,
          5296,
          6100,
          6597,
          7197,
          8689,
          8733,
        ],
      },
    },
    {
      id: 448,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/448',
      data_updated_at: '2018-05-21T21:51:46.231391Z',
      data: {
        created_at: '2012-02-28T01:36:02.000000Z',
        level: 1,
        slug: '十',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8D%81',
        characters: '十',
        meanings: [
          {
            meaning: 'Ten',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'じゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'とお',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [15],
        amalgamation_subject_ids: [
          2485,
          2524,
          2530,
          2543,
          2566,
          2590,
          2630,
          3237,
          3374,
          4253,
          6992,
          7565,
          7616,
        ],
      },
    },
    {
      id: 449,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/449',
      data_updated_at: '2018-05-21T21:51:46.259758Z',
      data: {
        created_at: '2012-02-28T01:43:58.000000Z',
        level: 1,
        slug: '三',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%89',
        characters: '三',
        meanings: [
          {
            meaning: 'Three',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'さん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'み',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [7, 1],
        amalgamation_subject_ids: [
          2486,
          2487,
          2488,
          2713,
          2836,
          3553,
          4171,
          4260,
          5239,
          5844,
          7456,
          7563,
          7678,
        ],
      },
    },
    {
      id: 450,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/450',
      data_updated_at: '2018-05-21T21:51:46.286222Z',
      data: {
        created_at: '2012-02-28T01:46:31.000000Z',
        level: 1,
        slug: '上',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%8A',
        characters: '上',
        meanings: [
          {
            meaning: 'Above',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Up',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Over',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'じょう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'うえ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'あ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'のぼ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'うわ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'かみ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [10, 1],
        amalgamation_subject_ids: [
          2489,
          2490,
          2491,
          2492,
          2539,
          2817,
          2939,
          3304,
          3441,
          3461,
          3745,
          4051,
          4743,
          4825,
          4910,
          5841,
          6038,
          6086,
          6544,
          7087,
          7459,
          7577,
          7598,
          7968,
        ],
      },
    },
    {
      id: 451,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/451',
      data_updated_at: '2018-05-21T21:51:46.311553Z',
      data: {
        created_at: '2012-02-28T01:48:12.000000Z',
        level: 1,
        slug: '下',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%8B',
        characters: '下',
        meanings: [
          {
            meaning: 'Below',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Down',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Under',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Beneath',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'か',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'げ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'した',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'さ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'くだ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'お',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [10, 1],
        amalgamation_subject_ids: [
          2493,
          2494,
          2495,
          2496,
          2538,
          2848,
          3201,
          3396,
          3410,
          3446,
          3818,
          4104,
          4824,
          5099,
          5428,
          5441,
          6087,
          6185,
          7004,
          7086,
          7329,
          7371,
          8640,
          8663,
        ],
      },
    },
    {
      id: 452,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/452',
      data_updated_at: '2018-05-21T21:51:46.337520Z',
      data: {
        created_at: '2012-02-28T01:52:02.000000Z',
        level: 1,
        slug: '口',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%A3',
        characters: '口',
        meanings: [
          {
            meaning: 'Mouth',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'こう',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'く',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'くち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [16],
        amalgamation_subject_ids: [
          2497,
          2498,
          2558,
          2611,
          3402,
          3432,
          3439,
          3458,
          3891,
          4246,
          4360,
          5622,
          5690,
          5942,
          6938,
          7021,
          7685,
          8812,
        ],
      },
    },
    {
      id: 453,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/453',
      data_updated_at: '2018-05-21T21:51:46.365133Z',
      data: {
        created_at: '2012-02-28T01:54:53.000000Z',
        level: 1,
        slug: '大',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A4%A7',
        characters: '大',
        meanings: [
          {
            meaning: 'Big',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Large',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'たい',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'だい',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'おお',
            accepted_answer: false,
          },
          {
            type: 'nanori',
            primary: false,
            reading: 'ひろ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [18],
        amalgamation_subject_ids: [
          2499,
          2500,
          2501,
          2502,
          2602,
          2661,
          2741,
          2792,
          2812,
          2815,
          3001,
          3016,
          3085,
          3332,
          3436,
          3581,
          3731,
          3751,
          3918,
          3952,
          4052,
          4129,
          4206,
          4249,
          4405,
          4525,
          4536,
          4727,
          4842,
          4868,
          4987,
          5013,
          5045,
          5193,
          5202,
          5259,
          5274,
          5424,
          5425,
          5481,
          5518,
          5642,
          5823,
          5961,
          6085,
          6822,
          7035,
          7077,
          7151,
          7224,
          7304,
          7416,
          7528,
          7609,
          7668,
          7676,
          7784,
          7915,
          7974,
          8270,
          8401,
          8559,
          8574,
          8576,
          8659,
          8661,
          8666,
          8668,
          8694,
        ],
      },
    },
    {
      id: 454,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/454',
      data_updated_at: '2018-05-21T21:51:46.392255Z',
      data: {
        created_at: '2012-02-28T01:56:16.000000Z',
        level: 1,
        slug: '女',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A5%B3',
        characters: '女',
        meanings: [
          {
            meaning: 'Woman',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'じょ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'おんな',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'め',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [19],
        amalgamation_subject_ids: [
          2503,
          2512,
          2514,
          2554,
          2555,
          3359,
          3678,
          4435,
          4872,
          6030,
          6978,
          7518,
          7583,
          8475,
          8515,
          8735,
        ],
      },
    },
    {
      id: 455,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/455',
      data_updated_at: '2018-05-21T21:51:46.418023Z',
      data: {
        created_at: '2012-02-28T01:57:19.000000Z',
        level: 1,
        slug: '山',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B1%B1',
        characters: '山',
        meanings: [
          {
            meaning: 'Mountain',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'さん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'やま',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [20],
        amalgamation_subject_ids: [
          2504,
          2505,
          2549,
          3392,
          3652,
          4440,
          5475,
          5490,
          5923,
          5925,
          6534,
          6708,
          7858,
          8140,
          8233,
          8410,
          8678,
        ],
      },
    },
    {
      id: 456,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/456',
      data_updated_at: '2018-06-18T22:01:03.661373Z',
      data: {
        created_at: '2012-02-28T01:59:43.000000Z',
        level: 1,
        slug: '川',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B7%9D',
        characters: '川',
        meanings: [
          {
            meaning: 'River',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'せん',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'かわ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [21],
        amalgamation_subject_ids: [
          2506,
          3993,
          4620,
          5495,
          5978,
          6117,
          6930,
          7073,
          7413,
        ],
      },
    },
    {
      id: 457,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/457',
      data_updated_at: '2018-05-21T21:51:46.471886Z',
      data: {
        created_at: '2012-02-28T20:45:58.000000Z',
        level: 1,
        slug: '工',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B7%A5',
        characters: '工',
        meanings: [
          {
            meaning: 'Construction',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Industry',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'こう',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'く',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [25],
        amalgamation_subject_ids: [2791, 2811, 3033, 3037, 3084, 3193, 7560],
      },
    },
    {
      id: 458,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/458',
      data_updated_at: '2018-06-06T16:00:14.897228Z',
      data: {
        created_at: '2012-02-28T21:32:28.000000Z',
        level: 2,
        slug: '刀',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%88%80',
        characters: '刀',
        meanings: [
          {
            meaning: 'Sword',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Katana',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'とう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'かたな',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [24],
        amalgamation_subject_ids: [2507, 3399],
      },
    },
    {
      id: 459,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/459',
      data_updated_at: '2018-05-21T21:51:46.525009Z',
      data: {
        created_at: '2012-02-28T23:31:05.000000Z',
        level: 2,
        slug: '土',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%9C%9F',
        characters: '土',
        meanings: [
          {
            meaning: 'Soil',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Earth',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Ground',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Dirt',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ど',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'と',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'つち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [17],
        amalgamation_subject_ids: [
          2508,
          2849,
          4054,
          4541,
          7048,
          7061,
          7089,
          7155,
          7673,
          7972,
          7999,
          8753,
        ],
      },
    },
    {
      id: 460,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/460',
      data_updated_at: '2018-05-21T21:51:46.552937Z',
      data: {
        created_at: '2012-02-28T23:34:28.000000Z',
        level: 2,
        slug: '千',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8D%83',
        characters: '千',
        meanings: [
          {
            meaning: 'Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'せん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [32],
        amalgamation_subject_ids: [2509, 2510, 2533, 2565, 2780, 2933, 7534],
      },
    },
    {
      id: 461,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/461',
      data_updated_at: '2018-05-21T21:51:46.579078Z',
      data: {
        created_at: '2012-02-28T23:36:17.000000Z',
        level: 2,
        slug: '夕',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A4%95',
        characters: '夕',
        meanings: [
          {
            meaning: 'Evening',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'せき',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ゆう',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [33],
        amalgamation_subject_ids: [2511, 4119, 6173],
      },
    },
    {
      id: 462,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/462',
      data_updated_at: '2018-05-21T21:51:46.607435Z',
      data: {
        created_at: '2012-02-28T23:38:28.000000Z',
        level: 2,
        slug: '子',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%AD%90',
        characters: '子',
        meanings: [
          {
            meaning: 'Child',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Kid',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'し',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'す',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'こ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [34],
        amalgamation_subject_ids: [
          2512,
          2513,
          2514,
          2551,
          2553,
          2684,
          3080,
          3220,
          3324,
          3403,
          3408,
          3640,
          3779,
          3965,
          4176,
          4281,
          4316,
          4371,
          4411,
          4753,
          4965,
          4995,
          5089,
          5192,
          5454,
          5662,
          5938,
          6140,
          6256,
          6572,
          6750,
          6792,
          6835,
          6957,
          6989,
          7100,
          7111,
          7154,
          7356,
          7702,
          8022,
          8466,
        ],
      },
    },
    {
      id: 463,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/463',
      data_updated_at: '2018-05-21T21:51:46.636375Z',
      data: {
        created_at: '2012-02-28T23:40:00.000000Z',
        level: 2,
        slug: '小',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B0%8F',
        characters: '小',
        meanings: [
          {
            meaning: 'Small',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Little',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'しょう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ちい',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'こ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'お',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [35],
        amalgamation_subject_ids: [
          2515,
          2737,
          3290,
          3411,
          3449,
          3469,
          4038,
          4250,
          5046,
          5700,
          5764,
          5827,
          6249,
          6862,
          6956,
          7665,
          7735,
        ],
      },
    },
    {
      id: 464,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/464',
      data_updated_at: '2018-06-06T16:00:56.564321Z',
      data: {
        created_at: '2012-02-28T23:42:48.000000Z',
        level: 2,
        slug: '丁',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%81',
        characters: '丁',
        meanings: [
          {
            meaning: 'Street',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Ward',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ちょう',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'てい',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [27],
        amalgamation_subject_ids: [3124, 6237, 6527, 6983, 7493],
      },
    },
    {
      id: 465,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/465',
      data_updated_at: '2018-05-21T21:51:46.689326Z',
      data: {
        created_at: '2012-02-28T23:47:00.000000Z',
        level: 2,
        slug: '了',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%BA%86',
        characters: '了',
        meanings: [
          {
            meaning: 'Finish',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Complete',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'End',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'りょう',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [6],
        amalgamation_subject_ids: [3169, 3672, 5715],
      },
    },
    {
      id: 466,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/466',
      data_updated_at: '2018-05-21T21:51:46.716650Z',
      data: {
        created_at: '2012-02-28T23:48:13.000000Z',
        level: 2,
        slug: '又',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%88',
        characters: '又',
        meanings: [
          {
            meaning: 'Again',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'kunyomi',
            primary: true,
            reading: 'また',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [30],
        amalgamation_subject_ids: [2516],
      },
    },
    {
      id: 467,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/467',
      data_updated_at: '2018-05-21T21:51:46.745927Z',
      data: {
        created_at: '2012-02-28T23:53:55.000000Z',
        level: 2,
        slug: '丸',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%B8',
        characters: '丸',
        meanings: [
          {
            meaning: 'Circle',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Circular',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Round',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'がん',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'まる',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [11, 3],
        amalgamation_subject_ids: [2517, 2518, 6099, 7729],
      },
    },
    {
      id: 468,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/468',
      data_updated_at: '2018-05-21T21:51:46.773590Z',
      data: {
        created_at: '2012-02-28T23:57:42.000000Z',
        level: 2,
        slug: '才',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%89%8D',
        characters: '才',
        meanings: [
          {
            meaning: 'Genius',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'さい',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [15, 5],
        amalgamation_subject_ids: [2519, 2536, 3721, 6377, 7381],
      },
    },
    {
      id: 469,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/469',
      data_updated_at: '2018-05-21T21:51:46.801270Z',
      data: {
        created_at: '2012-02-29T00:01:12.000000Z',
        level: 2,
        slug: '中',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%AD',
        characters: '中',
        meanings: [
          {
            meaning: 'Middle',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'In',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Inside',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Center',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ちゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'なか',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [49],
        amalgamation_subject_ids: [
          2520,
          2521,
          2615,
          2626,
          2664,
          2726,
          2882,
          2899,
          3190,
          3284,
          3303,
          3442,
          3451,
          3474,
          3769,
          3771,
          3942,
          4273,
          4681,
          4862,
          5062,
          5095,
          5157,
          5245,
          5427,
          5515,
          5923,
          6088,
          6253,
          6607,
          6610,
          7079,
          7466,
          7551,
          7569,
          7576,
          7728,
          7732,
          7804,
          8272,
        ],
      },
    },
    {
      id: 470,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/470',
      data_updated_at: '2018-06-06T16:01:19.397994Z',
      data: {
        created_at: '2012-02-29T00:04:18.000000Z',
        level: 2,
        slug: '五',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%BA%94',
        characters: '五',
        meanings: [
          {
            meaning: 'Five',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ご',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'いつ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [39],
        amalgamation_subject_ids: [
          2522,
          2523,
          2524,
          2525,
          2526,
          2629,
          2682,
          3546,
          4110,
          4253,
        ],
      },
    },
    {
      id: 471,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/471',
      data_updated_at: '2018-05-21T21:51:46.855800Z',
      data: {
        created_at: '2012-02-29T00:17:02.000000Z',
        level: 2,
        slug: '六',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%AD',
        characters: '六',
        meanings: [
          {
            meaning: 'Six',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ろく',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'む',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [8, 2],
        amalgamation_subject_ids: [2527, 2528, 2529, 2530, 2531],
      },
    },
    {
      id: 472,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/472',
      data_updated_at: '2018-05-21T21:51:46.881413Z',
      data: {
        created_at: '2012-02-29T00:18:18.000000Z',
        level: 2,
        slug: '円',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%86%86',
        characters: '円',
        meanings: [
          {
            meaning: 'Yen',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Round',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Circle',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'えん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'まる',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [29, 8],
        amalgamation_subject_ids: [
          2532,
          2533,
          2534,
          2933,
          3528,
          6205,
          6588,
          6619,
        ],
      },
    },
    {
      id: 473,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/473',
      data_updated_at: '2018-05-21T21:51:46.906290Z',
      data: {
        created_at: '2012-02-29T00:22:40.000000Z',
        level: 2,
        slug: '天',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A4%A9',
        characters: '天',
        meanings: [
          {
            meaning: 'Heaven',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'てん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'あま',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [40],
        amalgamation_subject_ids: [
          2535,
          2536,
          2676,
          2883,
          3986,
          4795,
          5661,
          6884,
          7406,
          7464,
          7762,
          8676,
        ],
      },
    },
    {
      id: 474,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/474',
      data_updated_at: '2018-05-21T21:51:46.942807Z',
      data: {
        created_at: '2012-02-29T00:26:40.000000Z',
        level: 2,
        slug: '手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%89%8B',
        characters: '手',
        meanings: [
          {
            meaning: 'Hand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'しゅ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'て',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [41],
        amalgamation_subject_ids: [
          2537,
          2538,
          2539,
          2560,
          2568,
          2819,
          2851,
          2964,
          2999,
          3138,
          3205,
          3404,
          3422,
          3513,
          3549,
          3567,
          3571,
          3629,
          4061,
          4264,
          4353,
          4402,
          4438,
          5030,
          6276,
          6458,
          6472,
          6596,
          6771,
          6948,
          7167,
          7193,
          7467,
          7726,
          7770,
          8286,
          8506,
          8737,
        ],
      },
    },
    {
      id: 475,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/475',
      data_updated_at: '2018-05-21T21:51:46.967521Z',
      data: {
        created_at: '2012-02-29T00:29:24.000000Z',
        level: 2,
        slug: '文',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%96%87',
        characters: '文',
        meanings: [
          {
            meaning: 'Writing',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Sentence',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ぶん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'もん',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [42],
        amalgamation_subject_ids: [
          2540,
          2659,
          2660,
          2661,
          2809,
          2846,
          3283,
          3346,
          3411,
          3580,
          3658,
          3774,
          3786,
          4075,
          4327,
          4612,
          5519,
          5994,
          7632,
          7750,
          8303,
          8696,
          8706,
          8740,
        ],
      },
    },
    {
      id: 476,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/476',
      data_updated_at: '2018-05-21T21:51:46.992066Z',
      data: {
        created_at: '2012-02-29T00:32:19.000000Z',
        level: 2,
        slug: '日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%97%A5',
        characters: '日',
        meanings: [
          {
            meaning: 'Sun',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'にち',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'じつ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ひ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'か',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'び',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [22],
        amalgamation_subject_ids: [
          2523,
          2529,
          2541,
          2563,
          2570,
          2594,
          2647,
          2652,
          2728,
          2746,
          2748,
          2759,
          2784,
          2843,
          2852,
          2856,
          2965,
          3046,
          3164,
          3210,
          3712,
          3762,
          3850,
          3948,
          3983,
          4054,
          4055,
          4056,
          4057,
          4058,
          4116,
          4361,
          4362,
          4659,
          4680,
          4688,
          4969,
          5185,
          5246,
          5295,
          5353,
          5357,
          5775,
          5934,
          6048,
          6175,
          6362,
          6693,
          7515,
          7561,
          7562,
          7563,
          7564,
          7565,
          7587,
          7593,
          7614,
          7615,
          7616,
          7899,
          8265,
          8709,
        ],
      },
    },
    {
      id: 477,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/477',
      data_updated_at: '2018-05-21T21:51:47.016692Z',
      data: {
        created_at: '2012-02-29T00:34:02.000000Z',
        level: 2,
        slug: '月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%9C%88',
        characters: '月',
        meanings: [
          {
            meaning: 'Moon',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Month',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'げつ',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'がつ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'つき',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [43],
        amalgamation_subject_ids: [
          2522,
          2528,
          2542,
          2543,
          2544,
          2545,
          2562,
          2595,
          2650,
          2651,
          2702,
          2745,
          2758,
          2783,
          2825,
          2949,
          4056,
          4674,
          4817,
          4888,
          5358,
          6172,
          6990,
          7325,
        ],
      },
    },
    {
      id: 478,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/478',
      data_updated_at: '2018-05-21T21:51:47.042046Z',
      data: {
        created_at: '2012-02-29T00:34:36.000000Z',
        level: 2,
        slug: '木',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%9C%A8',
        characters: '木',
        meanings: [
          {
            meaning: 'Tree',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Wood',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'もく',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'き',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'こ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [23],
        amalgamation_subject_ids: [
          2546,
          3692,
          3694,
          4057,
          4679,
          7993,
          8034,
          8218,
          8692,
        ],
      },
    },
    {
      id: 479,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/479',
      data_updated_at: '2018-05-21T21:51:47.072395Z',
      data: {
        created_at: '2012-02-29T00:36:53.000000Z',
        level: 2,
        slug: '水',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%B0%B4',
        characters: '水',
        meanings: [
          {
            meaning: 'Water',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'すい',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'みず',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [45],
        amalgamation_subject_ids: [
          2547,
          2774,
          3366,
          3397,
          3630,
          3972,
          4058,
          4289,
          4335,
          4347,
          5334,
          5445,
          5587,
          6116,
          6284,
          6401,
          6512,
          6516,
          6928,
          7016,
          7121,
          7201,
          7207,
          7307,
          7310,
          7425,
          7619,
          7624,
          7728,
          7871,
          8001,
          8297,
          8376,
          8633,
        ],
      },
    },
    {
      id: 480,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/480',
      data_updated_at: '2018-05-21T21:51:47.097233Z',
      data: {
        created_at: '2012-02-29T00:38:57.000000Z',
        level: 2,
        slug: '火',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%81%AB',
        characters: '火',
        meanings: [
          {
            meaning: 'Fire',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'か',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ひ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ほ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [46],
        amalgamation_subject_ids: [
          2548,
          2549,
          2695,
          4300,
          4336,
          4361,
          6138,
          7176,
          7211,
          7484,
          7740,
          7816,
          7868,
          8723,
        ],
      },
    },
    {
      id: 481,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/481',
      data_updated_at: '2018-05-21T21:51:47.123502Z',
      data: {
        created_at: '2012-02-29T00:42:35.000000Z',
        level: 2,
        slug: '犬',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%8A%AC',
        characters: '犬',
        meanings: [
          {
            meaning: 'Dog',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'けん',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'いぬ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [47],
        amalgamation_subject_ids: [2550, 2551, 3502, 5405, 7910, 8296],
      },
    },
    {
      id: 482,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/482',
      data_updated_at: '2018-05-21T21:51:47.147971Z',
      data: {
        created_at: '2012-02-29T00:44:29.000000Z',
        level: 2,
        slug: '王',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%8E%8B',
        characters: '王',
        meanings: [
          {
            meaning: 'King',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'おう',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [48],
        amalgamation_subject_ids: [
          2552,
          2553,
          2554,
          2555,
          7355,
          7618,
          7751,
          8068,
          8662,
        ],
      },
    },
    {
      id: 483,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/483',
      data_updated_at: '2018-05-21T21:51:47.174494Z',
      data: {
        created_at: '2012-02-29T00:45:28.000000Z',
        level: 2,
        slug: '出',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%87%BA',
        characters: '出',
        meanings: [
          {
            meaning: 'Exit',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'しゅつ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'で',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'だ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [20],
        amalgamation_subject_ids: [
          2556,
          2557,
          2558,
          2880,
          3040,
          3418,
          3433,
          3435,
          3461,
          3506,
          3980,
          4071,
          4380,
          4566,
          4684,
          4977,
          5027,
          5335,
          5340,
          5508,
          5509,
          5915,
          5940,
          6402,
          6561,
          6675,
          6936,
          6959,
          7274,
          7423,
          7872,
          7903,
          8086,
          8660,
        ],
      },
    },
    {
      id: 484,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/484',
      data_updated_at: '2018-05-21T21:51:47.205259Z',
      data: {
        created_at: '2012-02-29T00:50:30.000000Z',
        level: 2,
        slug: '右',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%B3',
        characters: '右',
        meanings: [
          {
            meaning: 'Right',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'う',
            accepted_answer: false,
          },
          {
            type: 'onyomi',
            primary: false,
            reading: 'ゆう',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'みぎ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [26, 16],
        amalgamation_subject_ids: [2559, 2560, 3574, 3953, 6779, 8693, 8717],
      },
    },
    {
      id: 485,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/485',
      data_updated_at: '2018-05-21T21:51:47.230433Z',
      data: {
        created_at: '2012-02-29T00:53:29.000000Z',
        level: 2,
        slug: '四',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%9B%9B',
        characters: '四',
        meanings: [
          {
            meaning: 'Four',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'し',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'よん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'よ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [28, 16],
        amalgamation_subject_ids: [
          2561,
          2562,
          2563,
          2564,
          2565,
          2566,
          2681,
          2837,
          2885,
          3237,
          3374,
          3671,
          6810,
          7854,
        ],
      },
    },
    {
      id: 486,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/486',
      data_updated_at: '2018-05-21T21:51:47.256235Z',
      data: {
        created_at: '2012-02-29T00:57:18.000000Z',
        level: 2,
        slug: '左',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B7%A6',
        characters: '左',
        meanings: [
          {
            meaning: 'Left',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'さ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ひだり',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [26, 25],
        amalgamation_subject_ids: [
          2567,
          2568,
          3572,
          3954,
          6778,
          7652,
          8585,
          8693,
        ],
      },
    },
    {
      id: 487,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/487',
      data_updated_at: '2018-05-21T21:51:47.281492Z',
      data: {
        created_at: '2012-02-29T00:58:32.000000Z',
        level: 2,
        slug: '本',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%9C%AC',
        characters: '本',
        meanings: [
          {
            meaning: 'Book',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Origin',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Real',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Main',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ほん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'もと',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [50],
        amalgamation_subject_ids: [
          2569,
          2570,
          2677,
          2679,
          2833,
          2856,
          2865,
          3108,
          3164,
          3210,
          3329,
          3336,
          3642,
          3670,
          3712,
          3911,
          4429,
          4680,
          4876,
          4877,
          5911,
          6244,
          6832,
          6881,
          7149,
          7766,
          8031,
          8736,
          8749,
        ],
      },
    },
    {
      id: 488,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/488',
      data_updated_at: '2018-05-21T21:51:47.305694Z',
      data: {
        created_at: '2012-02-29T01:04:20.000000Z',
        level: 2,
        slug: '正',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%AD%A3',
        characters: '正',
        meanings: [
          {
            meaning: 'Correct',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'せい',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'しょう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ただ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'まさ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [44, 1],
        amalgamation_subject_ids: [
          2571,
          2572,
          2739,
          2904,
          4095,
          4205,
          4311,
          4467,
          4476,
          7382,
          7654,
          8642,
        ],
      },
    },
    {
      id: 489,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/489',
      data_updated_at: '2018-05-21T21:51:47.331772Z',
      data: {
        created_at: '2012-02-29T01:07:13.000000Z',
        level: 2,
        slug: '玉',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%8E%89',
        characters: '玉',
        meanings: [
          {
            meaning: 'Ball',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'ぎょく',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'たま',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [48, 3],
        amalgamation_subject_ids: [2573, 2574, 2575, 2584, 2798, 6302],
      },
    },
    {
      id: 490,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/490',
      data_updated_at: '2018-05-21T21:51:47.356619Z',
      data: {
        created_at: '2012-02-29T01:12:55.000000Z',
        level: 2,
        slug: '田',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%94%B0',
        characters: '田',
        meanings: [
          {
            meaning: 'Rice Paddy',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Rice Field',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Field',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'でん',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'た',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [51],
        amalgamation_subject_ids: [2580, 5871, 5930, 6316, 6524, 6735, 7537],
      },
    },
    {
      id: 491,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/491',
      data_updated_at: '2018-05-21T21:51:47.381412Z',
      data: {
        created_at: '2012-02-29T01:13:32.000000Z',
        level: 2,
        slug: '白',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%99%BD',
        characters: '白',
        meanings: [
          {
            meaning: 'White',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'はく',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'しろ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'しら',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [52],
        amalgamation_subject_ids: [
          2581,
          2582,
          2986,
          3514,
          3878,
          4081,
          5322,
          7034,
          7521,
          8688,
        ],
      },
    },
    {
      id: 492,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/492',
      data_updated_at: '2018-05-21T21:51:47.407034Z',
      data: {
        created_at: '2012-02-29T01:14:43.000000Z',
        level: 2,
        slug: '目',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%9B%AE',
        characters: '目',
        meanings: [
          {
            meaning: 'Eye',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'もく',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'め',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [53],
        amalgamation_subject_ids: [
          2583,
          2584,
          2872,
          3480,
          3481,
          3553,
          3603,
          3713,
          3912,
          6237,
          6332,
          7372,
          7737,
          8386,
          8489,
          8539,
          8600,
          8699,
          8712,
        ],
      },
    },
    {
      id: 493,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/493',
      data_updated_at: '2018-05-21T21:51:47.432314Z',
      data: {
        created_at: '2012-02-29T01:17:03.000000Z',
        level: 2,
        slug: '石',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%9F%B3',
        characters: '石',
        meanings: [
          {
            meaning: 'Stone',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'せき',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'いし',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [54],
        amalgamation_subject_ids: [
          2585,
          5632,
          5778,
          6522,
          6535,
          6697,
          7058,
          8302,
          8573,
          8726,
        ],
      },
    },
    {
      id: 494,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/494',
      data_updated_at: '2018-05-31T17:34:02.037705Z',
      data: {
        created_at: '2012-02-29T01:18:04.000000Z',
        level: 2,
        slug: '立',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%AB%8B',
        characters: '立',
        meanings: [
          {
            meaning: 'Stand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'りつ',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'りゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'た',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [55],
        amalgamation_subject_ids: [
          2586,
          2587,
          2636,
          2771,
          3009,
          3015,
          3716,
          3918,
          4312,
          4439,
          4899,
          4953,
          5076,
          5116,
          5457,
          7536,
          7876,
          7891,
        ],
      },
    },
    {
      id: 495,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/495',
      data_updated_at: '2018-05-21T21:51:47.499749Z',
      data: {
        created_at: '2012-03-01T02:10:55.000000Z',
        level: 3,
        slug: '万',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B8%87',
        characters: '万',
        meanings: [
          {
            meaning: 'Ten Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'まん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'ばん',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [24, 8763],
        amalgamation_subject_ids: [
          2588,
          2589,
          2590,
          2680,
          6993,
          7462,
          7519,
          7768,
        ],
      },
    },
    {
      id: 496,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/496',
      data_updated_at: '2018-05-21T21:51:47.532742Z',
      data: {
        created_at: '2012-03-01T02:18:50.000000Z',
        level: 3,
        slug: '久',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%B9%85',
        characters: '久',
        meanings: [
          {
            meaning: 'Long Time',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'きゅう',
            accepted_answer: false,
          },
          {
            type: 'onyomi',
            primary: false,
            reading: 'く',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ひさ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [59],
        amalgamation_subject_ids: [2591, 2592, 5535, 7212, 7881, 8096],
      },
    },
    {
      id: 497,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/497',
      data_updated_at: '2018-05-21T21:51:47.562246Z',
      data: {
        created_at: '2012-03-01T02:24:53.000000Z',
        level: 3,
        slug: '今',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E4%BB%8A',
        characters: '今',
        meanings: [
          {
            meaning: 'Now',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'こん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'いま',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [8764, 58],
        amalgamation_subject_ids: [
          2593,
          2594,
          2595,
          2752,
          2890,
          2979,
          3123,
          3414,
          3417,
          3444,
          3761,
          3765,
          5607,
          7564,
          8703,
        ],
      },
    },
    {
      id: 498,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/498',
      data_updated_at: '2018-05-21T21:51:47.589553Z',
      data: {
        created_at: '2012-03-01T02:25:56.000000Z',
        level: 3,
        slug: '元',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%83',
        characters: '元',
        meanings: [
          {
            meaning: 'Origin',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'げん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'がん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'もと',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [64],
        amalgamation_subject_ids: [
          2596,
          2675,
          3783,
          3934,
          6227,
          6867,
          7960,
          7977,
          8215,
        ],
      },
    },
    {
      id: 499,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/499',
      data_updated_at: '2018-05-21T21:51:47.613843Z',
      data: {
        created_at: '2012-03-01T02:26:43.000000Z',
        level: 3,
        slug: '公',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%AC',
        characters: '公',
        meanings: [
          {
            meaning: 'Public',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'こう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'None',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [140, 2],
        amalgamation_subject_ids: [
          2642,
          2832,
          3186,
          3248,
          3824,
          4085,
          4288,
          4427,
          4528,
          4535,
          5176,
          7842,
          8193,
        ],
      },
    },
    {
      id: 500,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/500',
      data_updated_at: '2018-05-21T21:51:47.638986Z',
      data: {
        created_at: '2012-03-01T02:30:14.000000Z',
        level: 3,
        slug: '内',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%86%85',
        characters: '内',
        meanings: [
          {
            meaning: 'Inside',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Within',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ない',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'うち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [29, 9],
        amalgamation_subject_ids: [
          2663,
          2710,
          2808,
          2830,
          3955,
          3967,
          4185,
          4512,
          5389,
          5500,
          5720,
          5913,
          6211,
          6351,
          6388,
          7844,
        ],
      },
    },
    {
      id: 501,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/501',
      data_updated_at: '2018-05-21T21:51:47.664257Z',
      data: {
        created_at: '2012-03-01T02:31:21.000000Z',
        level: 3,
        slug: '分',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%88%86',
        characters: '分',
        meanings: [
          {
            meaning: 'Part',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Minute',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Separate',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Understand',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ぶん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'ふん',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'ぶ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'わ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [24, 2],
        amalgamation_subject_ids: [
          2597,
          2598,
          2599,
          2600,
          2673,
          2700,
          2755,
          2770,
          3405,
          3741,
          4072,
          4079,
          4159,
          4357,
          4468,
          5128,
          5412,
          6605,
          6660,
          6845,
          7533,
          7749,
          8052,
          8628,
        ],
      },
    },
    {
      id: 502,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/502',
      data_updated_at: '2018-05-21T21:51:47.689678Z',
      data: {
        created_at: '2012-03-01T02:33:02.000000Z',
        level: 3,
        slug: '切',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%88%87',
        characters: '切',
        meanings: [
          {
            meaning: 'Cut',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'せつ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'き',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [24, 4],
        amalgamation_subject_ids: [
          2601,
          2602,
          2603,
          2818,
          3225,
          3404,
          4070,
          5077,
          5078,
          5083,
          5087,
          5272,
          5348,
          5691,
          5692,
          5833,
          5842,
        ],
      },
    },
    {
      id: 503,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/503',
      data_updated_at: '2018-05-21T21:51:47.714106Z',
      data: {
        created_at: '2012-03-01T02:34:26.000000Z',
        level: 3,
        slug: '午',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8D%88',
        characters: '午',
        meanings: [
          {
            meaning: 'Noon',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ご',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [61, 5],
        amalgamation_subject_ids: [2909, 2913],
      },
    },
    {
      id: 504,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/504',
      data_updated_at: '2018-05-21T21:51:47.738107Z',
      data: {
        created_at: '2012-03-01T02:35:48.000000Z',
        level: 3,
        slug: '友',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%8B',
        characters: '友',
        meanings: [
          {
            meaning: 'Friend',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ゆう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'とも',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [30, 26],
        amalgamation_subject_ids: [2604, 3226, 3251, 4151],
      },
    },
    {
      id: 505,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/505',
      data_updated_at: '2018-05-21T21:51:47.762873Z',
      data: {
        created_at: '2012-03-01T02:38:55.000000Z',
        level: 3,
        slug: '太',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A4%AA',
        characters: '太',
        meanings: [
          {
            meaning: 'Fat',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'たい',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'た',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ふと',
            accepted_answer: false,
          },
          {
            type: 'nanori',
            primary: false,
            reading: 'おお',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [18, 3],
        amalgamation_subject_ids: [
          2605,
          2606,
          2658,
          3294,
          3371,
          5354,
          5662,
          7356,
          8433,
        ],
      },
    },
    {
      id: 506,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/506',
      data_updated_at: '2018-05-21T21:51:47.788872Z',
      data: {
        created_at: '2012-03-01T02:42:36.000000Z',
        level: 3,
        slug: '少',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B0%91',
        characters: '少',
        meanings: [
          {
            meaning: 'Few',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'A Little',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'しょう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'すこ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [35, 5],
        amalgamation_subject_ids: [2607, 2608, 2666, 7078, 7518, 8271],
      },
    },
    {
      id: 507,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/507',
      data_updated_at: '2018-05-21T21:51:47.815728Z',
      data: {
        created_at: '2012-03-01T02:46:20.000000Z',
        level: 3,
        slug: '引',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%BC%95',
        characters: '引',
        meanings: [
          {
            meaning: 'Pull',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'いん',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ひ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [36, 8761],
        amalgamation_subject_ids: [
          2609,
          3405,
          4765,
          5270,
          5721,
          6632,
          6844,
          7716,
          8744,
        ],
      },
    },
    {
      id: 508,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/508',
      data_updated_at: '2018-05-21T21:51:47.840648Z',
      data: {
        created_at: '2012-03-01T02:48:23.000000Z',
        level: 3,
        slug: '心',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%BF%83',
        characters: '心',
        meanings: [
          {
            meaning: 'Heart',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'しん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'こころ',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [65],
        amalgamation_subject_ids: [
          2610,
          2860,
          2973,
          3019,
          3026,
          3520,
          3563,
          3564,
          3588,
          3614,
          3638,
          3648,
          3709,
          3735,
          3992,
          4000,
          4033,
          4374,
          5305,
          5563,
          5718,
          6749,
          7709,
          7745,
          7765,
          7774,
        ],
      },
    },
    {
      id: 509,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/509',
      data_updated_at: '2018-05-21T21:51:47.866142Z',
      data: {
        created_at: '2012-03-01T02:49:28.000000Z',
        level: 3,
        slug: '戸',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%88%B8',
        characters: '戸',
        meanings: [
          {
            meaning: 'Door',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'こ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'と',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [73],
        amalgamation_subject_ids: [
          2611,
          5247,
          6479,
          6670,
          6769,
          6833,
          6883,
          8734,
        ],
      },
    },
    {
      id: 510,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/510',
      data_updated_at: '2018-05-21T21:51:47.892327Z',
      data: {
        created_at: '2012-03-01T02:50:26.000000Z',
        level: 3,
        slug: '方',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%96%B9',
        characters: '方',
        meanings: [
          {
            meaning: 'Direction',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Way',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'ほう',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'かた',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [66],
        amalgamation_subject_ids: [
          2612,
          2701,
          2716,
          2717,
          2766,
          2853,
          2858,
          2897,
          3216,
          3419,
          3437,
          3450,
          3499,
          3555,
          3773,
          4030,
          4106,
          7463,
          8300,
        ],
      },
    },
    {
      id: 511,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/511',
      data_updated_at: '2018-05-21T21:51:47.916468Z',
      data: {
        created_at: '2012-03-01T02:52:31.000000Z',
        level: 3,
        slug: '牛',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%89%9B',
        characters: '牛',
        meanings: [
          {
            meaning: 'Cow',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ぎゅう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'うし',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [126],
        amalgamation_subject_ids: [2619, 2768, 3403, 4646, 5754],
      },
    },
    {
      id: 512,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/512',
      data_updated_at: '2018-05-21T21:51:47.947040Z',
      data: {
        created_at: '2012-03-01T03:00:33.000000Z',
        level: 3,
        slug: '父',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%88%B6',
        characters: '父',
        meanings: [
          {
            meaning: 'Father',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Dad',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'ふ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ちち',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [68],
        amalgamation_subject_ids: [
          2617,
          2618,
          3551,
          5542,
          5723,
          6000,
          7613,
          8524,
        ],
      },
    },
    {
      id: 513,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/513',
      data_updated_at: '2018-05-21T21:51:47.972525Z',
      data: {
        created_at: '2012-03-01T03:01:07.000000Z',
        level: 3,
        slug: '毛',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%AF%9B',
        characters: '毛',
        meanings: [
          {
            meaning: 'Fur',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Hair',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'もう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'け',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [67],
        amalgamation_subject_ids: [2616, 2878, 3409, 3416, 4194, 5767, 6818],
      },
    },
    {
      id: 514,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/514',
      data_updated_at: '2018-05-21T21:51:48.033911Z',
      data: {
        created_at: '2012-03-01T03:02:11.000000Z',
        level: 3,
        slug: '止',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%AD%A2',
        characters: '止',
        meanings: [
          {
            meaning: 'Stop',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'し',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'と',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'や',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [44],
        amalgamation_subject_ids: [
          2613,
          2614,
          2615,
          2646,
          4126,
          4156,
          4312,
          4333,
          4585,
          5261,
          5513,
          5980,
          6142,
          6199,
          7042,
        ],
      },
    },
    {
      id: 515,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/515',
      data_updated_at: '2018-05-21T21:51:48.109674Z',
      data: {
        created_at: '2012-03-01T03:45:02.000000Z',
        level: 3,
        slug: '兄',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%85%84',
        characters: '兄',
        meanings: [
          {
            meaning: 'Older Brother',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Big Brother',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Elder Brother',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'きょう',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'あに',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [28, 16],
        amalgamation_subject_ids: [2620, 2621, 2821, 5748, 7717],
      },
    },
    {
      id: 516,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/516',
      data_updated_at: '2018-05-21T21:51:48.178863Z',
      data: {
        created_at: '2012-03-01T03:46:35.000000Z',
        level: 3,
        slug: '冬',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%86%AC',
        characters: '冬',
        meanings: [
          {
            meaning: 'Winter',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'とう',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ふゆ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [60, 7],
        amalgamation_subject_ids: [2622, 3415, 6542],
      },
    },
    {
      id: 517,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/517',
      data_updated_at: '2018-05-21T21:51:48.228879Z',
      data: {
        created_at: '2012-03-01T03:49:49.000000Z',
        level: 3,
        slug: '北',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8C%97',
        characters: '北',
        meanings: [
          {
            meaning: 'North',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'ほく',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'きた',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [63, 57],
        amalgamation_subject_ids: [2623, 2779, 2898, 7474, 8014],
      },
    },
    {
      id: 518,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/518',
      data_updated_at: '2018-05-21T21:51:48.255124Z',
      data: {
        created_at: '2012-03-01T03:50:42.000000Z',
        level: 3,
        slug: '半',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8D%8A',
        characters: '半',
        meanings: [
          {
            meaning: 'Half',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'はん',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'なか',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [61, 8765],
        amalgamation_subject_ids: [2597, 2624, 2960, 4671, 5062, 5463, 6895],
      },
    },
    {
      id: 519,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/519',
      data_updated_at: '2018-05-21T21:51:48.281182Z',
      data: {
        created_at: '2012-03-01T03:52:05.000000Z',
        level: 3,
        slug: '古',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%A4',
        characters: '古',
        meanings: [
          {
            meaning: 'Old',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'こ',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'ふる',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [69],
        amalgamation_subject_ids: [
          2625,
          2626,
          2827,
          3122,
          3809,
          7414,
          7568,
          8037,
        ],
      },
    },
    {
      id: 520,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/520',
      data_updated_at: '2018-09-11T19:32:30.907916Z',
      data: {
        created_at: '2012-03-01T03:54:05.000000Z',
        level: 3,
        slug: '台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%8F%B0',
        characters: '台',
        meanings: [
          {
            meaning: 'Machine',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'だい',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'たい',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [140, 16],
        amalgamation_subject_ids: [
          2627,
          2628,
          2629,
          2630,
          6005,
          6091,
          6285,
          6764,
          7455,
          7567,
          7651,
          7742,
        ],
      },
    },
    {
      id: 521,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/521',
      data_updated_at: '2018-05-21T21:51:48.330888Z',
      data: {
        created_at: '2012-03-01T03:56:49.000000Z',
        level: 3,
        slug: '外',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%A4%96',
        characters: '外',
        meanings: [
          {
            meaning: 'Outside',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'がい',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'そと',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'はず',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [33, 10],
        amalgamation_subject_ids: [
          2631,
          2632,
          2633,
          2634,
          2828,
          2886,
          2887,
          2918,
          2938,
          3008,
          3421,
          3655,
          3875,
          3956,
          3975,
          4272,
          4373,
          4513,
          4555,
          5424,
          5435,
          6298,
          6389,
          7270,
          7730,
          7845,
          8110,
          8118,
        ],
      },
    },
    {
      id: 522,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/522',
      data_updated_at: '2018-05-21T21:51:48.356889Z',
      data: {
        created_at: '2012-03-01T03:57:35.000000Z',
        level: 3,
        slug: '市',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%B8%82',
        characters: '市',
        meanings: [
          {
            meaning: 'City',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'し',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'いち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [102, 8],
        amalgamation_subject_ids: [2635, 2636, 2637, 4530, 4938, 7457],
      },
    },
    {
      id: 523,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/523',
      data_updated_at: '2018-06-18T22:01:09.787946Z',
      data: {
        created_at: '2012-03-01T03:58:32.000000Z',
        level: 3,
        slug: '広',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E5%BA%83',
        characters: '広',
        meanings: [
          {
            meaning: 'Wide',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'こう',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'ひろ',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [140, 62],
        amalgamation_subject_ids: [2638, 3344, 4083, 8760],
      },
    },
    {
      id: 524,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/524',
      data_updated_at: '2018-05-21T21:51:48.405959Z',
      data: {
        created_at: '2012-03-01T04:00:00.000000Z',
        level: 3,
        slug: '母',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E6%AF%8D',
        characters: '母',
        meanings: [
          {
            meaning: 'Mother',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Mom',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Mum',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'ぼ',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'はは',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [70],
        amalgamation_subject_ids: [
          2639,
          2640,
          3552,
          5543,
          5723,
          6513,
          7409,
          8420,
          8525,
        ],
      },
    },
    {
      id: 525,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/525',
      data_updated_at: '2018-05-21T21:51:48.430656Z',
      data: {
        created_at: '2012-03-01T04:00:59.000000Z',
        level: 3,
        slug: '用',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%94%A8',
        characters: '用',
        meanings: [
          {
            meaning: 'Task',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Use',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'よう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'もち',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [71],
        amalgamation_subject_ids: [
          2641,
          2642,
          2722,
          2810,
          3083,
          3533,
          3736,
          3893,
          4032,
          4064,
          4516,
          5527,
          5745,
          6269,
          6379,
          6460,
          7579,
          7669,
          7679,
          7733,
          7774,
          8802,
        ],
      },
    },
    {
      id: 526,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/526',
      data_updated_at: '2018-08-08T22:18:04.281786Z',
      data: {
        created_at: '2012-03-01T04:02:50.000000Z',
        level: 3,
        slug: '矢',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%9F%A2',
        characters: '矢',
        meanings: [
          {
            meaning: 'Arrow',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'し',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'や',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [72],
        amalgamation_subject_ids: [2643, 4293, 5105],
      },
    },
    {
      id: 850,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/850',
      data_updated_at: '2018-06-06T16:48:09.006163Z',
      data: {
        created_at: '2012-06-05T23:58:47.796331Z',
        level: 3,
        slug: '生',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E7%94%9F',
        characters: '生',
        meanings: [
          {
            meaning: 'Life',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: true,
            reading: 'せい',
            accepted_answer: true,
          },
          {
            type: 'onyomi',
            primary: true,
            reading: 'しょう',
            accepted_answer: true,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'い',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'なま',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'う',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'は',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: false,
            reading: 'き',
            accepted_answer: false,
          },
        ],
        component_subject_ids: [210],
        amalgamation_subject_ids: [
          2576,
          2577,
          2578,
          2579,
          2653,
          2665,
          2788,
          2916,
          3407,
          3436,
          3449,
          3451,
          3473,
          3715,
          3806,
          3827,
          4059,
          4195,
          4498,
          4659,
          4969,
          5154,
          5326,
          5548,
          5571,
          6225,
          6346,
          6432,
          6471,
          6640,
          6903,
          6980,
          7189,
          7429,
          7500,
          7617,
          7671,
          7731,
          7738,
          8196,
          8668,
          8701,
          8745,
        ],
      },
    },
    {
      id: 856,
      object: 'kanji',
      url: 'https://api.wanikani.com/v2/subjects/856',
      data_updated_at: '2018-05-21T21:51:58.609085Z',
      data: {
        created_at: '2012-07-24T23:58:04.630590Z',
        level: 2,
        slug: '々',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/kanji/%E3%80%85',
        characters: '々',
        meanings: [
          {
            meaning: 'Repeater',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Repetition',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Reduplication',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Iteration Mark',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            type: 'onyomi',
            primary: false,
            reading: 'のま',
            accepted_answer: false,
          },
          {
            type: 'kunyomi',
            primary: true,
            reading: 'のま',
            accepted_answer: true,
          },
        ],
        component_subject_ids: [14, 3],
        amalgamation_subject_ids: [
          2521,
          2650,
          2669,
          2773,
          3265,
          3427,
          3447,
          3478,
          3510,
          3601,
          4227,
          4232,
          4366,
          4508,
          5048,
          5390,
          5614,
          5843,
          6796,
          6950,
          7388,
          7405,
          7448,
          7517,
          7855,
          7981,
          8095,
          8253,
          8481,
          8493,
          8731,
        ],
      },
    },
  ],
}
