import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/AnswerPanel.scss';
import KanaInput from './KanaInput';
import KanjiSelectionsPanel from './selections/KanjiSelectionsPanel';
import VocabProgressBar from './VocabProgressBar';

class AnswerPanel extends Component {
  render() {
    const { gameStore } = this.props;

    return (
      <div className="answer-panel">
        <div
          className={classNames('answer-panel-controls', {
            'answer-panel-controls-hidden': gameStore.isWon,
          })}
        >
          <KanjiSelectionsPanel />
          <KanaInput />
        </div>
        <VocabProgressBar className="answer-panel-progress-bar" />
      </div>
    );
  }
}

export default inject('gameStore')(observer(AnswerPanel));
