import { Card } from 'antd';
import classNames from 'classnames';
import Label from 'components/shared/Label';
import NewTabLink from 'components/shared/NewTabLink';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/VocabCard.scss';

class VocabCard extends Component {
  render() {
    const { className, vocabData, showKanaHints, showAnswers, gameSettingsStore } = this.props;

    const { showVocabHints } = gameSettingsStore;
    const highlighted = showVocabHints && vocabData.solvable;

    return (
      <Card
        className={classNames(className, 'vocab-card', {
          'vocab-card-highlighted': highlighted,
        })}
      >
        <Label large>
          <NewTabLink href={vocabData.url} title="Meanings">
            {vocabData.meaningsString}
          </NewTabLink>
        </Label>
        <Label title="Parts of Speech" secondary>
          {vocabData.partsOfSpeechString}
        </Label>
        {showKanaHints && (
          <Label large title="Kana Hint">
            {vocabData.kanaHintString}
          </Label>
        )}
        {showAnswers && (
          <>
            <Label large title="Characters">
              {vocabData.characters}
            </Label>
            <Label title="Readings" secondary>
              {vocabData.readingsString}
            </Label>
          </>
        )}
      </Card>
    );
  }
}

export default inject('gameSettingsStore')(observer(VocabCard));
