import lodashShuffle from 'lodash/shuffle';
import shortid from 'shortid';

export default () => ({
  generateId() {
    return shortid.generate();
  },
  shuffle(array) {
    return lodashShuffle(array);
  },
  delay(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  },
});
