import { Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import 'styles/ResetAppButton.scss';

const ResetAppButton = ({ rootStore, form }) => {
  const handleClick = async () => {
    await rootStore.resetState();

    // A timeout is needed to avoid a rendering race condition between mobx and antd.
    // Otherwise, the user would have to click "Reset App" twice for the API Key to
    // be cleared from the input.
    setTimeout(() => {
      form.resetFields();
      message.warning('App Reset');
    }, 500);
  };

  return (
    <Button className="reset-app-button" type="danger" onClick={handleClick}>
      Reset App
    </Button>
  );
};

export default inject('rootStore')(observer(ResetAppButton));
