import { types } from 'mobx-state-tree';
import VocabData from './VocabData';

const KanjiData = types
  .model('KanjiData', {
    id: types.identifierNumber,
    url: types.string,
    kanji: types.string,
    // late reference to avoid circular dependency issues with VocabData
    vocabList: types.array(types.reference(types.late(() => VocabData))),
    enabled: false,
  })
  .views((self) => ({
    get solved() {
      return self.vocabList.every((vocabData) => vocabData.solved);
    },
    get hasSolvableVocab() {
      return self.vocabList.some((vocabData) => vocabData.solvable);
    },
  }))
  .actions((self) => ({
    enable() {
      self.enabled = true;
    },
    resetState() {
      self.enabled = false;
    },
  }));

export default KanjiData;
