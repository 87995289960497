import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/CopyrightNotice.scss';

const CURRENT_YEAR = new Date().getFullYear();
const COPYRIGHT_MESSAGE = `Copyright \u00A9 ${CURRENT_YEAR} Mark Hennessy`;

class CopyrightNotice extends Component {
  render() {
    return <div className="copyright-notice">{COPYRIGHT_MESSAGE}</div>;
  }
}

export default observer(CopyrightNotice);
