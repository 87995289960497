import { Tabs } from 'antd';
import withWindowDimensions from 'components/hoc/withWindowDimensions';
import MaterialIcon from 'components/shared/MaterialIcon';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import responsiveSizes from 'responsiveSizes';
import 'styles/SidePanel.scss';
import ProgressSummary from './progress/ProgressSummary';
import VocabCards from './vocab/VocabCards';

const { TabPane } = Tabs;

class SidePanel extends Component {
  render() {
    const { gameStore, windowWidth } = this.props;
    const { solvableVocab, remainingVocab, completedVocab } = gameStore;

    return (
      <Tabs
        className="side-panel"
        defaultActiveKey="1"
        tabPosition={windowWidth <= responsiveSizes.tablet ? 'top' : 'right'}
      >
        <TabPane key="1" tab={<MaterialIcon title="Solvable Words">explore</MaterialIcon>}>
          <VocabCards vocabList={solvableVocab} celebrateWhenEmpty={gameStore.solvable} />
        </TabPane>
        <TabPane key="2" tab={<MaterialIcon title="Remaining Words">school</MaterialIcon>}>
          <VocabCards vocabList={remainingVocab} celebrateWhenEmpty />
        </TabPane>
        <TabPane
          key="3"
          tab={<MaterialIcon title="Remaining Words with Hints">beach_access</MaterialIcon>}
        >
          <VocabCards vocabList={remainingVocab} showKanaHints celebrateWhenEmpty />
        </TabPane>
        <TabPane
          key="4"
          tab={<MaterialIcon title="Remaining Words with Answers">spellcheck</MaterialIcon>}
        >
          <VocabCards vocabList={remainingVocab} showAnswers celebrateWhenEmpty />
        </TabPane>
        <TabPane key="5" tab={<MaterialIcon title="Completed Words">done_all</MaterialIcon>}>
          <VocabCards vocabList={completedVocab} showAnswers />
        </TabPane>
        <TabPane key="6" tab={<MaterialIcon title="Progress Summary">bar_chart</MaterialIcon>}>
          <ProgressSummary />
        </TabPane>
      </Tabs>
    );
  }
}

export default withWindowDimensions(inject('gameStore')(observer(SidePanel)));
