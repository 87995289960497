import { flow, getEnv, types } from 'mobx-state-tree';
import dataLoader from '../data/dataLoader';
import KanjiData from './models/KanjiData';
import VocabData from './models/VocabData';
import WaniKaniSettingsStore from './WaniKaniSettingsStore';

const DataStore = types
  .model('DataStore', {
    waniKaniSettingsStore: types.optional(WaniKaniSettingsStore, {}),
    level: types.maybe(types.number),
    vocabList: types.array(VocabData),
    kanjiList: types.array(KanjiData),
    networkErrorCount: 0,
    apiKeyErrorCount: 0,
  })
  .views((self) => {
    const getIds = (list) => list.map((item) => item.id);

    return {
      get vocabIds() {
        return getIds(self.vocabList);
      },
      get kanjiIds() {
        return getIds(self.kanjiList);
      },
    };
  })
  .actions((self) => ({
    loadLevel: flow(function* loadLevel(level, onSuccessCallback) {
      let success = yield self.loadLevelFromLocalStorage(level, onSuccessCallback);

      if (!success && self.waniKaniSettingsStore.keyMaybeValid) {
        success = yield self.loadLevelFromAPI(level, onSuccessCallback);
      }

      if (!success) {
        success = self.loadLevelFromFallbackData(level, onSuccessCallback);
      }

      return success;
    }),
    loadLevelFromLocalStorage: flow(function* loadLevelFromLocalStorage(level, onSuccessCallback) {
      // give the UI a chance to update the animated loading spinner
      yield getEnv(self).delay(250);

      const levelData = dataLoader.loadLevelFromLocalStorage(level);

      if (levelData) {
        Object.assign(self, levelData);
        if (onSuccessCallback) {
          onSuccessCallback();
        }

        return true;
      }

      return false;
    }),
    loadLevelFromAPI: flow(function* loadLevelFromAPI(level, onSuccessCallback) {
      const { key } = self.waniKaniSettingsStore;

      try {
        const levelData = yield dataLoader.loadLevelFromAPI(level, key);
        // Each region between yield statements is 1 transaction for state updates.
        // Changing state will break id references to the state and
        // cause the program to crash unless the references are also updated
        // within the same transaction. The onSuccessCallback can be used to
        // reset state references in the same transaction as state updates.
        Object.assign(self, levelData);
        self.waniKaniSettingsStore.setKeyValid(true);
        if (onSuccessCallback) {
          onSuccessCallback();
        }

        return true;
      } catch (err) {
        self.waniKaniSettingsStore.setKeyInvalidIfStatusError(err);
        if (err.message === 'Network Error') {
          self.networkErrorCount += 1;
        }

        return false;
      }
    }),
    loadLevelFromFallbackData(level, onSuccessCallback) {
      const levelData = dataLoader.loadLevelFromFallbackData(level);
      const { vocabList, kanjiList } = levelData;
      if (vocabList.length > 0 && kanjiList.length > 0) {
        Object.assign(self, levelData);
        if (onSuccessCallback) {
          onSuccessCallback();
        }

        return true;
      }

      const { keyValid } = self.waniKaniSettingsStore;
      if (!keyValid) {
        self.apiKeyErrorCount += 1;
      }

      return false;
    },
  }));

export default DataStore;
