import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

const withWindowDimensions = (WrappedComponent) => {
  class WithWindowDimensions extends Component {
    windowWidth = window.innerWidth;

    windowHeight = window.innerHeight;

    constructor(props) {
      super(props);

      makeObservable(this, {
        windowWidth: observable,
        windowHeight: observable,
        updateWindowDimensions: action,
      });
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={this.windowWidth}
          windowHeight={this.windowHeight}
        />
      );
    }
  }

  return WithWindowDimensions;
};

export default withWindowDimensions;
