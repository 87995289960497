import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ScalableCharacter.scss';

class ScalableCharacter extends Component {
  render() {
    const { children } = this.props;

    return (
      <svg
        className="scalable-character"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMinYMin"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text className="scalable-character-text" textAnchor="middle" x="50" y="78" fontSize="75">
          {children}
        </text>
      </svg>
    );
  }
}

export default observer(ScalableCharacter);
