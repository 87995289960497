import IconButton from 'components/shared/IconButton';
import MaterialIcon from 'components/shared/MaterialIcon';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ClearSelectionsButton.scss';

class ClearSelectionsButton extends Component {
  render() {
    const { selectionsStore } = this.props;

    return (
      <div className="clear-selections-button">
        <IconButton title="Clear Selections" onClick={selectionsStore.deselectAll}>
          <MaterialIcon>delete_sweep</MaterialIcon>
        </IconButton>
      </div>
    );
  }
}

export default inject('selectionsStore')(observer(ClearSelectionsButton));
