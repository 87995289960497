import classNames from 'classnames';
import withMouseAndKeyboardSelection from 'components/hoc/withMouseAndKeyboardSelection';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/IconButton.scss';
import { ContentButtonBase } from './ContentButton';

class IconButton extends Component {
  render() {
    const { className, size, ...otherProps } = this.props;

    return (
      <ContentButtonBase
        className={classNames(className, 'icon-button', {
          'icon-button-medium': size === 'medium',
        })}
        {...otherProps}
      />
    );
  }
}

export default withMouseAndKeyboardSelection(observer(IconButton));
