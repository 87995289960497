import { Drawer } from 'antd';
import withWindowDimensions from 'components/hoc/withWindowDimensions';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/SettingsDrawer.scss';
import SettingsPanel from './SettingsPanel';

const KEY_EVENT = 'keydown';
const SETTINGS_DRAWER_WIDTH = 450;

class SettingsDrawer extends Component {
  componentDidMount() {
    document.addEventListener(KEY_EVENT, this.onKeyEvent, false);
  }

  componentWillUnmount() {
    document.removeEventListener(KEY_EVENT, this.onKeyEvent, false);
  }

  onKeyEvent = (event) => {
    if (event.key === 'Escape') {
      const { onHideSettings } = this.props;
      onHideSettings();
    }
  };

  render() {
    const { windowWidth, settingsVisible, onHideSettings } = this.props;

    return (
      <Drawer
        className="settings-drawer"
        title="Settings"
        width={Math.min(windowWidth, SETTINGS_DRAWER_WIDTH)}
        placement="right"
        onClose={onHideSettings}
        visible={settingsVisible}
        destroyOnClose
      >
        <SettingsPanel />
      </Drawer>
    );
  }
}

export default withWindowDimensions(observer(SettingsDrawer));
