import { Button, Form, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import 'styles/WaniKaniSettings.scss';
import WaniKaniLink from './links/WaniKaniLink';

const FormItem = Form.Item;

const API_KEY_FIELD = 'apiKey';

const API_KEY_DESCRIPTION = (
  <div>
    Used to request kanji and vocabulary from {<WaniKaniLink />}
    . Only a few levels can be played without a key. The key can be found at wanikani.com under
    <br />
    Settings &gt; API Tokens &gt; <WaniKaniLink goToAPIKey>Public API Key</WaniKaniLink>
  </div>
);

const WaniKaniSettings = ({ form, waniKaniSettingsStore }) => {
  const { key, keyValid, keyHasValue, isKeySameAsCurrent, isKeyValidated } = waniKaniSettingsStore;

  const handleFinish = (values) => {
    waniKaniSettingsStore.setKey(values[API_KEY_FIELD]);
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <FormItem shouldUpdate>
        {() => {
          const keyFieldValue = form.getFieldValue(API_KEY_FIELD);
          const keyValidated = isKeyValidated(keyFieldValue);

          let keyValidationProps = {};
          if (keyValidated) {
            if (keyValid) {
              keyValidationProps = {
                hasFeedback: true,
                validateStatus: 'success',
              };
            } else {
              keyValidationProps = {
                hasFeedback: true,
                validateStatus: 'error',
                help: 'Invalid API Key',
              };
            }
          }

          return (
            <FormItem
              {...keyValidationProps}
              className="wanikani-settings-form-item"
              label="WaniKani API Key (API Version 2)"
              extra={API_KEY_DESCRIPTION}
              name={API_KEY_FIELD}
              initialValue={key}
            >
              <Input placeholder="API Key" />
            </FormItem>
          );
        }}
      </FormItem>
      <FormItem className="wanikani-settings-form-item" dependencies={[API_KEY_FIELD]}>
        {() => {
          const keyFieldValue = form.getFieldValue(API_KEY_FIELD);
          const sameAsCurrent = isKeySameAsCurrent(keyFieldValue);
          const keyValidated = isKeyValidated(keyFieldValue);
          const submitDisabled = keyValidated || (!keyHasValue && sameAsCurrent);
          return (
            <Button type="primary" htmlType="submit" disabled={submitDisabled}>
              Submit
            </Button>
          );
        }}
      </FormItem>
    </Form>
  );
};

export default inject('waniKaniSettingsStore')(observer(WaniKaniSettings));
