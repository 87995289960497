import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import IconButton from 'components/shared/IconButton';
import MaterialIcon from 'components/shared/MaterialIcon';
import ResetConfirmation from './ResetConfirmation';

class ResetLevelButton extends Component {
  resetLevel = () => {
    const { gameStore } = this.props;
    gameStore.generateLevel();
    message.success('Level Reset');
  };

  render() {
    const { className, gameStore } = this.props;

    return (
      <ResetConfirmation onReset={this.resetLevel} placement="bottomRight">
        <IconButton className={className} title="Reset Level" disabled={gameStore.loading}>
          <MaterialIcon>replay</MaterialIcon>
        </IconButton>
      </ResetConfirmation>
    );
  }
}

export default inject('gameStore')(observer(ResetLevelButton));
