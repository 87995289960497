import classNames from 'classnames';
import withMouseAndKeyboardSelection from 'components/hoc/withMouseAndKeyboardSelection';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/ContentButton.scss';

class ContentButton extends Component {
  render() {
    const { as, className, theme, disabled, ...otherProps } = this.props;
    const CustomTag = as || 'div';

    return (
      <CustomTag
        className={classNames(className, 'content-button', {
          'content-button-light': theme === 'light',
          'content-button-disabled': disabled,
        })}
        role="button"
        tabIndex={0}
        {...otherProps}
      />
    );
  }
}

export const ContentButtonBase = ContentButton;
export default withMouseAndKeyboardSelection(observer(ContentButton));
