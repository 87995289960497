import { Button } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/KanjiSelection.scss';

class KanjiSelection extends Component {
  handleClick = () => {
    const { onClick, selection } = this.props;
    if (onClick) {
      onClick(selection);
    }
  };

  render() {
    const { className, style, selection } = this.props;

    return (
      <Button
        className={classNames(className, 'kanji-selection')}
        style={style}
        danger
        shape="circle"
        onClick={this.handleClick}
      >
        {selection.value}
      </Button>
    );
  }
}

export default observer(KanjiSelection);
