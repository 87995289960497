/* eslint-disable */

export default {
  object: 'collection',
  url: 'https://api.wanikani.com/v2/subjects?levels=1%2C2%2C3&types=vocabulary',
  pages: {
    per_page: 1000,
    next_url: null,
    previous_url: null,
  },
  total_count: 203,
  data_updated_at: '2018-09-20T17:45:45.639065Z',
  data: [
    {
      id: 2467,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2467',
      data_updated_at: '2018-09-20T17:45:45.639065Z',
      data: {
        created_at: '2012-02-28T08:04:47.000000Z',
        level: 1,
        slug: '一',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80',
        characters: '一',
        meanings: [
          {
            meaning: 'One',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [440],
      },
    },
    {
      id: 2468,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2468',
      data_updated_at: '2018-09-11T19:42:33.806412Z',
      data: {
        created_at: '2012-02-28T08:10:16.000000Z',
        level: 1,
        slug: '一つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E3%81%A4',
        characters: '一つ',
        meanings: [
          {
            meaning: 'One Thing',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひとつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [440],
      },
    },
    {
      id: 2469,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2469',
      data_updated_at: '2018-09-11T19:42:20.131189Z',
      data: {
        created_at: '2012-02-28T08:12:25.000000Z',
        level: 1,
        slug: '七',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%83',
        characters: '七',
        meanings: [
          {
            meaning: 'Seven',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'なな',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'しち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [443],
      },
    },
    {
      id: 2470,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2470',
      data_updated_at: '2018-09-11T19:42:27.653523Z',
      data: {
        created_at: '2012-02-28T08:12:39.000000Z',
        level: 1,
        slug: '七つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%83%E3%81%A4',
        characters: '七つ',
        meanings: [
          {
            meaning: 'Seven Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ななつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [443],
      },
    },
    {
      id: 2471,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2471',
      data_updated_at: '2018-09-11T19:42:21.263872Z',
      data: {
        created_at: '2012-02-28T08:13:23.000000Z',
        level: 1,
        slug: '九',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B9%9D',
        characters: '九',
        meanings: [
          {
            meaning: 'Nine',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'きゅう',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'く',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [442],
      },
    },
    {
      id: 2472,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2472',
      data_updated_at: '2018-09-11T19:42:37.423924Z',
      data: {
        created_at: '2012-02-28T08:13:33.000000Z',
        level: 1,
        slug: '九つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B9%9D%E3%81%A4',
        characters: '九つ',
        meanings: [
          {
            meaning: 'Nine Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ここのつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [442],
      },
    },
    {
      id: 2473,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2473',
      data_updated_at: '2018-09-11T19:42:20.852461Z',
      data: {
        created_at: '2012-02-28T08:13:57.000000Z',
        level: 1,
        slug: '二',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C',
        characters: '二',
        meanings: [
          {
            meaning: 'Two',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'に',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [441],
      },
    },
    {
      id: 2474,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2474',
      data_updated_at: '2018-09-11T19:42:37.904653Z',
      data: {
        created_at: '2012-02-28T08:14:07.000000Z',
        level: 1,
        slug: '二つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E3%81%A4',
        characters: '二つ',
        meanings: [
          {
            meaning: 'Two Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふたつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [441],
      },
    },
    {
      id: 2475,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2475',
      data_updated_at: '2018-09-11T19:42:06.540324Z',
      data: {
        created_at: '2012-02-28T08:14:35.000000Z',
        level: 1,
        slug: '人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%BA',
        characters: '人',
        meanings: [
          {
            meaning: 'Person',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひと',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [444],
      },
    },
    {
      id: 2476,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2476',
      data_updated_at: '2018-09-11T19:42:36.385030Z',
      data: {
        created_at: '2012-02-28T08:14:49.000000Z',
        level: 1,
        slug: '二人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E4%BA%BA',
        characters: '二人',
        meanings: [
          {
            meaning: 'Two People',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Pair',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Couple',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Two Persons',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふたり',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [444, 441],
      },
    },
    {
      id: 2477,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2477',
      data_updated_at: '2018-09-11T19:42:28.958526Z',
      data: {
        created_at: '2012-02-28T08:15:02.000000Z',
        level: 1,
        slug: '一人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E4%BA%BA',
        characters: '一人',
        meanings: [
          {
            meaning: 'Alone',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'One Person',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひとり',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [444, 440],
      },
    },
    {
      id: 2478,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2478',
      data_updated_at: '2018-09-11T19:42:43.965907Z',
      data: {
        created_at: '2012-02-28T08:15:23.000000Z',
        level: 3,
        slug: 'アメリカ人',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%82%A2%E3%83%A1%E3%83%AA%E3%82%AB%E4%BA%BA',
        characters: 'アメリカ人',
        meanings: [
          {
            meaning: 'American',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'American Person',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'あめりかじん',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'アメリカじん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [444],
      },
    },
    {
      id: 2479,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2479',
      data_updated_at: '2018-09-11T19:42:45.726933Z',
      data: {
        created_at: '2012-02-28T08:15:33.000000Z',
        level: 3,
        slug: 'フランス人',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%83%95%E3%83%A9%E3%83%B3%E3%82%B9%E4%BA%BA',
        characters: 'フランス人',
        meanings: [
          {
            meaning: 'Frenchman',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'French Person',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふらんすじん',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'フランスじん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [444],
      },
    },
    {
      id: 2480,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2480',
      data_updated_at: '2018-09-11T19:42:23.416621Z',
      data: {
        created_at: '2012-02-28T08:15:42.000000Z',
        level: 1,
        slug: '入る',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%A5%E3%82%8B',
        characters: '入る',
        meanings: [
          {
            meaning: 'To Enter',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はいる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [445],
      },
    },
    {
      id: 2481,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2481',
      data_updated_at: '2018-09-11T19:42:14.854757Z',
      data: {
        created_at: '2012-02-28T08:15:53.000000Z',
        level: 1,
        slug: '入れる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%85%A5%E3%82%8C%E3%82%8B',
        characters: '入れる',
        meanings: [
          {
            meaning: 'To Insert',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Put In',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いれる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [445],
      },
    },
    {
      id: 2482,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2482',
      data_updated_at: '2018-09-11T19:42:25.544712Z',
      data: {
        created_at: '2012-02-28T08:16:01.000000Z',
        level: 1,
        slug: '八',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AB',
        characters: '八',
        meanings: [
          {
            meaning: 'Eight',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [446],
      },
    },
    {
      id: 2483,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2483',
      data_updated_at: '2018-09-11T19:42:38.503562Z',
      data: {
        created_at: '2012-02-28T08:16:10.000000Z',
        level: 1,
        slug: '八つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AB%E3%81%A4',
        characters: '八つ',
        meanings: [
          {
            meaning: 'Eight Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'やっつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [446],
      },
    },
    {
      id: 2484,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2484',
      data_updated_at: '2018-09-11T19:42:39.129814Z',
      data: {
        created_at: '2012-02-28T08:16:19.000000Z',
        level: 1,
        slug: '力',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8A%9B',
        characters: '力',
        meanings: [
          {
            meaning: 'Power',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Strength',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ちから',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [447],
      },
    },
    {
      id: 2485,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2485',
      data_updated_at: '2018-09-11T19:42:18.916031Z',
      data: {
        created_at: '2012-02-28T08:16:46.000000Z',
        level: 1,
        slug: '十',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81',
        characters: '十',
        meanings: [
          {
            meaning: 'Ten',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じゅう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [448],
      },
    },
    {
      id: 2486,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2486',
      data_updated_at: '2018-09-11T19:42:03.833134Z',
      data: {
        created_at: '2012-02-28T08:16:57.000000Z',
        level: 1,
        slug: '三',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%89',
        characters: '三',
        meanings: [
          {
            meaning: 'Three',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [449],
      },
    },
    {
      id: 2487,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2487',
      data_updated_at: '2018-09-11T19:42:39.035740Z',
      data: {
        created_at: '2012-02-28T08:17:06.000000Z',
        level: 1,
        slug: '三つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%89%E3%81%A4',
        characters: '三つ',
        meanings: [
          {
            meaning: 'Three Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'みっつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [449],
      },
    },
    {
      id: 2488,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2488',
      data_updated_at: '2018-09-11T19:42:45.207277Z',
      data: {
        created_at: '2012-02-28T08:17:14.000000Z',
        level: 1,
        slug: '三人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%89%E4%BA%BA',
        characters: '三人',
        meanings: [
          {
            meaning: 'Three People',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Three Persons',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さんにん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [449, 444],
      },
    },
    {
      id: 2489,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2489',
      data_updated_at: '2018-09-11T19:42:09.381201Z',
      data: {
        created_at: '2012-02-28T08:17:22.000000Z',
        level: 1,
        slug: '上',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%8A',
        characters: '上',
        meanings: [
          {
            meaning: 'Up',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Above',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Over',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'うえ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adverb', 'noun', 'suffix', 'no_adjective'],
        component_subject_ids: [450],
      },
    },
    {
      id: 2490,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2490',
      data_updated_at: '2018-09-11T19:42:13.079451Z',
      data: {
        created_at: '2012-02-28T08:17:31.000000Z',
        level: 1,
        slug: '上げる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B8%8A%E3%81%92%E3%82%8B',
        characters: '上げる',
        meanings: [
          {
            meaning: 'To Raise',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Raise Something',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'あげる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [450],
      },
    },
    {
      id: 2491,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2491',
      data_updated_at: '2018-09-11T19:42:13.649028Z',
      data: {
        created_at: '2012-02-28T08:17:38.000000Z',
        level: 1,
        slug: '上がる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B8%8A%E3%81%8C%E3%82%8B',
        characters: '上がる',
        meanings: [
          {
            meaning: 'To Rise',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Go Up',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Be Raised',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'あがる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [450],
      },
    },
    {
      id: 2492,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2492',
      data_updated_at: '2018-09-11T19:42:14.774868Z',
      data: {
        created_at: '2012-02-28T08:17:49.000000Z',
        level: 1,
        slug: '上る',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%8A%E3%82%8B',
        characters: '上る',
        meanings: [
          {
            meaning: 'To Climb',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Go Up',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'のぼる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [450],
      },
    },
    {
      id: 2493,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2493',
      data_updated_at: '2018-09-11T19:42:06.167125Z',
      data: {
        created_at: '2012-02-28T08:17:58.000000Z',
        level: 1,
        slug: '下',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%8B',
        characters: '下',
        meanings: [
          {
            meaning: 'Down',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Below',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Under',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Beneath',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'した',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [451],
      },
    },
    {
      id: 2494,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2494',
      data_updated_at: '2018-09-11T19:42:28.513875Z',
      data: {
        created_at: '2012-02-28T08:18:06.000000Z',
        level: 1,
        slug: '下がる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B8%8B%E3%81%8C%E3%82%8B',
        characters: '下がる',
        meanings: [
          {
            meaning: 'To Get Lower',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Fall',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Drop',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Come Down',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Hang Down',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さがる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [451],
      },
    },
    {
      id: 2495,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2495',
      data_updated_at: '2018-09-11T19:42:33.343959Z',
      data: {
        created_at: '2012-02-28T08:18:34.000000Z',
        level: 1,
        slug: '下げる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B8%8B%E3%81%92%E3%82%8B',
        characters: '下げる',
        meanings: [
          {
            meaning: 'To Lower',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Hang',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Lower Something',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Hang Something',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さげる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [451],
      },
    },
    {
      id: 2496,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2496',
      data_updated_at: '2018-09-11T19:42:38.056979Z',
      data: {
        created_at: '2012-02-28T08:18:43.000000Z',
        level: 1,
        slug: '下さい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B8%8B%E3%81%95%E3%81%84',
        characters: '下さい',
        meanings: [
          {
            meaning: 'Please Give Me',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Please',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ください',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['expression'],
        component_subject_ids: [451],
      },
    },
    {
      id: 2497,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2497',
      data_updated_at: '2018-09-11T19:42:07.058711Z',
      data: {
        created_at: '2012-02-28T08:19:11.000000Z',
        level: 1,
        slug: '口',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%A3',
        characters: '口',
        meanings: [
          {
            meaning: 'Mouth',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'くち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [452],
      },
    },
    {
      id: 2498,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2498',
      data_updated_at: '2018-09-11T20:02:30.832591Z',
      data: {
        created_at: '2012-02-28T08:19:19.000000Z',
        level: 1,
        slug: '入り口',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%85%A5%E3%82%8A%E5%8F%A3',
        characters: '入り口',
        meanings: [
          {
            meaning: 'Entrance',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いりぐち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [452, 445],
      },
    },
    {
      id: 2499,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2499',
      data_updated_at: '2018-09-11T19:42:44.798453Z',
      data: {
        created_at: '2012-02-28T08:19:28.000000Z',
        level: 1,
        slug: '大きい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%A7%E3%81%8D%E3%81%84',
        characters: '大きい',
        meanings: [
          {
            meaning: 'Big',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Large',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おおきい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [453],
      },
    },
    {
      id: 2500,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2500',
      data_updated_at: '2018-09-11T20:01:34.967189Z',
      data: {
        created_at: '2012-02-28T08:19:38.000000Z',
        level: 1,
        slug: '大きさ',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%A7%E3%81%8D%E3%81%95',
        characters: '大きさ',
        meanings: [
          {
            meaning: 'Size',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おおきさ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [453],
      },
    },
    {
      id: 2501,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2501',
      data_updated_at: '2018-09-11T19:42:25.061847Z',
      data: {
        created_at: '2012-02-28T08:19:47.000000Z',
        level: 1,
        slug: '大した',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%A7%E3%81%97%E3%81%9F',
        characters: '大した',
        meanings: [
          {
            meaning: 'Considerable',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Great',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Important',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Big Deal',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たいした',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adjective'],
        component_subject_ids: [453],
      },
    },
    {
      id: 2502,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2502',
      data_updated_at: '2018-09-11T19:42:14.427056Z',
      data: {
        created_at: '2012-02-28T08:19:56.000000Z',
        level: 1,
        slug: '大人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%A7%E4%BA%BA',
        characters: '大人',
        meanings: [
          {
            meaning: 'Adult',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おとな',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [453, 444],
      },
    },
    {
      id: 2503,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2503',
      data_updated_at: '2018-09-11T19:42:36.285705Z',
      data: {
        created_at: '2012-02-28T08:20:08.000000Z',
        level: 1,
        slug: '女',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A5%B3',
        characters: '女',
        meanings: [
          {
            meaning: 'Woman',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Female',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おんな',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [454],
      },
    },
    {
      id: 2504,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2504',
      data_updated_at: '2018-09-11T19:42:28.358853Z',
      data: {
        created_at: '2012-02-28T08:20:18.000000Z',
        level: 1,
        slug: '山',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B1%B1',
        characters: '山',
        meanings: [
          {
            meaning: 'Mountain',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'やま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [455],
      },
    },
    {
      id: 2505,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2505',
      data_updated_at: '2018-09-11T19:32:18.133670Z',
      data: {
        created_at: '2012-02-28T08:20:27.000000Z',
        level: 1,
        slug: 'ふじ山',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%81%B5%E3%81%98%E5%B1%B1',
        characters: 'ふじ山',
        meanings: [
          {
            meaning: 'Mt Fuji',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Mount Fuji',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Mt. Fuji',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふじさん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['proper_noun'],
        component_subject_ids: [455],
      },
    },
    {
      id: 2506,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2506',
      data_updated_at: '2018-09-11T19:42:26.143886Z',
      data: {
        created_at: '2012-02-28T08:20:36.000000Z',
        level: 1,
        slug: '川',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B7%9D',
        characters: '川',
        meanings: [
          {
            meaning: 'River',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'かわ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [456],
      },
    },
    {
      id: 2507,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2507',
      data_updated_at: '2018-09-11T19:44:10.574259Z',
      data: {
        created_at: '2012-02-29T01:38:56.000000Z',
        level: 2,
        slug: '刀',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%88%80',
        characters: '刀',
        meanings: [
          {
            meaning: 'Sword',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Katana',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'かたな',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [458],
      },
    },
    {
      id: 2508,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2508',
      data_updated_at: '2018-09-11T19:43:52.897416Z',
      data: {
        created_at: '2012-02-29T07:34:08.000000Z',
        level: 2,
        slug: '土',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9C%9F',
        characters: '土',
        meanings: [
          {
            meaning: 'Soil',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Earth',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Ground',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Dirt',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'つち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [459],
      },
    },
    {
      id: 2509,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2509',
      data_updated_at: '2018-09-11T19:43:16.828457Z',
      data: {
        created_at: '2012-02-29T07:34:35.000000Z',
        level: 2,
        slug: '千',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%83',
        characters: '千',
        meanings: [
          {
            meaning: 'Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'せん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [460],
      },
    },
    {
      id: 2510,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2510',
      data_updated_at: '2018-09-11T19:44:18.229999Z',
      data: {
        created_at: '2012-02-29T07:34:46.000000Z',
        level: 2,
        slug: '一千',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E5%8D%83',
        characters: '一千',
        meanings: [
          {
            meaning: 'One Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いっせん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [460, 440],
      },
    },
    {
      id: 2511,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2511',
      data_updated_at: '2018-09-11T19:42:53.202511Z',
      data: {
        created_at: '2012-02-29T07:34:57.000000Z',
        level: 2,
        slug: '夕べ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%95%E3%81%B9',
        characters: '夕べ',
        meanings: [
          {
            meaning: 'Evening',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Last Night',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ゆうべ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [461],
      },
    },
    {
      id: 2512,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2512',
      data_updated_at: '2018-09-11T19:44:22.419630Z',
      data: {
        created_at: '2012-02-29T07:35:07.000000Z',
        level: 2,
        slug: '女の子',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A5%B3%E3%81%AE%E5%AD%90',
        characters: '女の子',
        meanings: [
          {
            meaning: 'Girl',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Young Girl',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Young Lady',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Young Woman',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おんなのこ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [462, 454],
      },
    },
    {
      id: 2513,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2513',
      data_updated_at: '2018-09-11T19:42:48.758462Z',
      data: {
        created_at: '2012-02-29T07:35:15.000000Z',
        level: 2,
        slug: '子',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%AD%90',
        characters: '子',
        meanings: [
          {
            meaning: 'Kid',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Child',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [462],
      },
    },
    {
      id: 2514,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2514',
      data_updated_at: '2018-09-11T19:43:26.425840Z',
      data: {
        created_at: '2012-02-29T07:35:23.000000Z',
        level: 2,
        slug: '女子',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A5%B3%E5%AD%90',
        characters: '女子',
        meanings: [
          {
            meaning: 'Girl',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Woman',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じょし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [462, 454],
      },
    },
    {
      id: 2515,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2515',
      data_updated_at: '2018-09-11T19:44:19.890241Z',
      data: {
        created_at: '2012-02-29T07:35:34.000000Z',
        level: 2,
        slug: '小さい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%B0%8F%E3%81%95%E3%81%84',
        characters: '小さい',
        meanings: [
          {
            meaning: 'Small',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Little',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ちいさい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [463],
      },
    },
    {
      id: 2516,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2516',
      data_updated_at: '2018-09-11T19:42:49.198018Z',
      data: {
        created_at: '2012-02-29T07:35:42.000000Z',
        level: 2,
        slug: '又',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%88',
        characters: '又',
        meanings: [
          {
            meaning: 'Again',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'And',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'また',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adverb', 'prefix'],
        component_subject_ids: [466],
      },
    },
    {
      id: 2517,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2517',
      data_updated_at: '2018-09-11T19:42:48.700660Z',
      data: {
        created_at: '2012-02-29T07:37:06.000000Z',
        level: 2,
        slug: '丸',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%B8',
        characters: '丸',
        meanings: [
          {
            meaning: 'Circle',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Round',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Circular',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'まる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [467],
      },
    },
    {
      id: 2518,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2518',
      data_updated_at: '2018-09-11T19:44:02.415062Z',
      data: {
        created_at: '2012-02-29T07:37:19.000000Z',
        level: 2,
        slug: '丸い',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%B8%E3%81%84',
        characters: '丸い',
        meanings: [
          {
            meaning: 'Circular',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Round',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Spherical',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'まるい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [467],
      },
    },
    {
      id: 2519,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2519',
      data_updated_at: '2018-09-11T19:43:26.518544Z',
      data: {
        created_at: '2012-02-29T07:37:27.000000Z',
        level: 2,
        slug: '〜才',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E3%80%9C%E6%89%8D',
        characters: '〜才',
        meanings: [
          {
            meaning: 'Years Old',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Age',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['suffix'],
        component_subject_ids: [468],
      },
    },
    {
      id: 2520,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2520',
      data_updated_at: '2018-09-11T19:43:19.895197Z',
      data: {
        created_at: '2012-02-29T07:37:35.000000Z',
        level: 2,
        slug: '中',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%AD',
        characters: '中',
        meanings: [
          {
            meaning: 'Middle',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'In',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Inside',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Center',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'なか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [469],
      },
    },
    {
      id: 2521,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2521',
      data_updated_at: '2018-09-11T19:43:05.906488Z',
      data: {
        created_at: '2012-02-29T07:37:41.000000Z',
        level: 2,
        slug: '中々',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%AD%E3%80%85',
        characters: '中々',
        meanings: [
          {
            meaning: 'Very',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Considerably',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Quite',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'なかなか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adverb'],
        component_subject_ids: [856, 469],
      },
    },
    {
      id: 2522,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2522',
      data_updated_at: '2018-09-11T19:43:32.706367Z',
      data: {
        created_at: '2012-02-29T07:37:58.000000Z',
        level: 2,
        slug: '五月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94%E6%9C%88',
        characters: '五月',
        meanings: [
          {
            meaning: 'May',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ごがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477, 470],
      },
    },
    {
      id: 2523,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2523',
      data_updated_at: '2018-09-11T19:42:54.236829Z',
      data: {
        created_at: '2012-02-29T07:38:25.000000Z',
        level: 2,
        slug: '五日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94%E6%97%A5',
        characters: '五日',
        meanings: [
          {
            meaning: 'Fifth Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Five',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Five Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いつか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 470],
      },
    },
    {
      id: 2524,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2524',
      data_updated_at: '2018-09-11T19:43:56.811676Z',
      data: {
        created_at: '2012-02-29T07:38:51.000000Z',
        level: 2,
        slug: '五十',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94%E5%8D%81',
        characters: '五十',
        meanings: [
          {
            meaning: 'Fifty',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ごじゅう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [470, 448],
      },
    },
    {
      id: 2525,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2525',
      data_updated_at: '2018-09-11T19:43:56.600205Z',
      data: {
        created_at: '2012-02-29T07:39:00.000000Z',
        level: 2,
        slug: '五つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94%E3%81%A4',
        characters: '五つ',
        meanings: [
          {
            meaning: 'Five Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いつつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [470],
      },
    },
    {
      id: 2526,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2526',
      data_updated_at: '2018-09-11T19:43:10.319570Z',
      data: {
        created_at: '2012-02-29T07:39:13.000000Z',
        level: 2,
        slug: '五',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94',
        characters: '五',
        meanings: [
          {
            meaning: 'Five',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ご',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [470],
      },
    },
    {
      id: 2527,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2527',
      data_updated_at: '2018-09-11T19:44:08.572294Z',
      data: {
        created_at: '2012-02-29T07:39:21.000000Z',
        level: 2,
        slug: '六つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AD%E3%81%A4',
        characters: '六つ',
        meanings: [
          {
            meaning: 'Six Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'むっつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [471],
      },
    },
    {
      id: 2528,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2528',
      data_updated_at: '2018-09-11T19:44:25.873819Z',
      data: {
        created_at: '2012-02-29T07:39:31.000000Z',
        level: 2,
        slug: '六月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AD%E6%9C%88',
        characters: '六月',
        meanings: [
          {
            meaning: 'June',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ろくがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477, 471],
      },
    },
    {
      id: 2529,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2529',
      data_updated_at: '2018-09-11T19:42:57.469993Z',
      data: {
        created_at: '2012-02-29T07:39:41.000000Z',
        level: 2,
        slug: '六日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AD%E6%97%A5',
        characters: '六日',
        meanings: [
          {
            meaning: 'Sixth Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Six',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Six Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'むいか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 471],
      },
    },
    {
      id: 2530,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2530',
      data_updated_at: '2018-09-11T19:44:25.170706Z',
      data: {
        created_at: '2012-02-29T07:39:55.000000Z',
        level: 2,
        slug: '十六',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81%E5%85%AD',
        characters: '十六',
        meanings: [
          {
            meaning: 'Sixteen',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じゅうろく',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [471, 448],
      },
    },
    {
      id: 2531,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2531',
      data_updated_at: '2018-09-11T19:43:47.455905Z',
      data: {
        created_at: '2012-02-29T07:40:03.000000Z',
        level: 2,
        slug: '六',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AD',
        characters: '六',
        meanings: [
          {
            meaning: 'Six',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ろく',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [471],
      },
    },
    {
      id: 2532,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2532',
      data_updated_at: '2018-09-11T19:43:27.339429Z',
      data: {
        created_at: '2012-02-29T07:40:19.000000Z',
        level: 2,
        slug: '〜円',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E3%80%9C%E5%86%86',
        characters: '〜円',
        meanings: [
          {
            meaning: 'Money',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Yen',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'えん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [472],
      },
    },
    {
      id: 2533,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2533',
      data_updated_at: '2018-09-11T19:43:36.968538Z',
      data: {
        created_at: '2012-02-29T07:40:31.000000Z',
        level: 2,
        slug: '千円',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%83%E5%86%86',
        characters: '千円',
        meanings: [
          {
            meaning: '1000 Yen',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'One Thousand Yen',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'A Thousand Yen',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Thousand Yen',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'せんえん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [472, 460],
      },
    },
    {
      id: 2534,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2534',
      data_updated_at: '2018-09-11T19:44:01.879613Z',
      data: {
        created_at: '2012-02-29T07:40:56.000000Z',
        level: 2,
        slug: '円い',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%86%86%E3%81%84',
        characters: '円い',
        meanings: [
          {
            meaning: 'Round',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Circular',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'まるい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [472],
      },
    },
    {
      id: 2535,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2535',
      data_updated_at: '2018-09-11T19:43:20.666080Z',
      data: {
        created_at: '2012-02-29T07:41:05.000000Z',
        level: 2,
        slug: '天',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%A9',
        characters: '天',
        meanings: [
          {
            meaning: 'Heaven',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'てん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [473],
      },
    },
    {
      id: 2536,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2536',
      data_updated_at: '2018-09-11T19:43:00.330428Z',
      data: {
        created_at: '2012-02-29T07:41:25.000000Z',
        level: 2,
        slug: '天才',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%A9%E6%89%8D',
        characters: '天才',
        meanings: [
          {
            meaning: 'Genius',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'てんさい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [473, 468],
      },
    },
    {
      id: 2537,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2537',
      data_updated_at: '2018-09-11T19:43:16.485220Z',
      data: {
        created_at: '2012-02-29T07:42:30.000000Z',
        level: 2,
        slug: '手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%89%8B',
        characters: '手',
        meanings: [
          {
            meaning: 'Hand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'て',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [474],
      },
    },
    {
      id: 2538,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2538',
      data_updated_at: '2018-09-11T19:43:52.538704Z',
      data: {
        created_at: '2012-02-29T07:42:38.000000Z',
        level: 2,
        slug: '下手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%8B%E6%89%8B',
        characters: '下手',
        meanings: [
          {
            meaning: 'Unskillful',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Unskilled',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Bad At',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Not Good At',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Not Skilled',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'へた',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'na_adjective'],
        component_subject_ids: [474, 451],
      },
    },
    {
      id: 2539,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2539',
      data_updated_at: '2018-09-11T19:44:11.723465Z',
      data: {
        created_at: '2012-02-29T07:42:46.000000Z',
        level: 2,
        slug: '上手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%8A%E6%89%8B',
        characters: '上手',
        meanings: [
          {
            meaning: 'Good At',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Skillful',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Skilled At',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Skilled',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じょうず',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'na_adjective'],
        component_subject_ids: [474, 450],
      },
    },
    {
      id: 2540,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2540',
      data_updated_at: '2018-09-11T19:43:25.565990Z',
      data: {
        created_at: '2012-02-29T07:42:54.000000Z',
        level: 2,
        slug: '文',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%96%87',
        characters: '文',
        meanings: [
          {
            meaning: 'Writing',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Sentence',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ぶん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [475],
      },
    },
    {
      id: 2541,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2541',
      data_updated_at: '2018-09-11T19:42:47.734407Z',
      data: {
        created_at: '2012-02-29T07:43:02.000000Z',
        level: 2,
        slug: '日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%97%A5',
        characters: '日',
        meanings: [
          {
            meaning: 'Sun',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476],
      },
    },
    {
      id: 2542,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2542',
      data_updated_at: '2018-09-11T19:43:53.916696Z',
      data: {
        created_at: '2012-02-29T07:43:10.000000Z',
        level: 2,
        slug: '月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%9C%88',
        characters: '月',
        meanings: [
          {
            meaning: 'Moon',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Month',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'つき',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477],
      },
    },
    {
      id: 2543,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2543',
      data_updated_at: '2018-09-11T19:44:34.679696Z',
      data: {
        created_at: '2012-02-29T07:43:23.000000Z',
        level: 2,
        slug: '十月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81%E6%9C%88',
        characters: '十月',
        meanings: [
          {
            meaning: 'October',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じゅうがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477, 448],
      },
    },
    {
      id: 2544,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2544',
      data_updated_at: '2018-09-11T19:44:26.714658Z',
      data: {
        created_at: '2012-02-29T07:44:13.000000Z',
        level: 2,
        slug: '一月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E6%9C%88',
        characters: '一月',
        meanings: [
          {
            meaning: 'January',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いちがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477, 440],
      },
    },
    {
      id: 2545,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2545',
      data_updated_at: '2018-09-11T19:44:13.275354Z',
      data: {
        created_at: '2012-02-29T07:44:20.000000Z',
        level: 2,
        slug: '二月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E6%9C%88',
        characters: '二月',
        meanings: [
          {
            meaning: 'February',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [477, 441],
      },
    },
    {
      id: 2546,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2546',
      data_updated_at: '2018-09-11T19:43:09.159160Z',
      data: {
        created_at: '2012-02-29T07:44:29.000000Z',
        level: 2,
        slug: '木',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%9C%A8',
        characters: '木',
        meanings: [
          {
            meaning: 'Tree',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Wood',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'き',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [478],
      },
    },
    {
      id: 2547,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2547',
      data_updated_at: '2018-09-11T19:42:47.889470Z',
      data: {
        created_at: '2012-02-29T07:44:38.000000Z',
        level: 2,
        slug: '水',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%B0%B4',
        characters: '水',
        meanings: [
          {
            meaning: 'Water',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'みず',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [479],
      },
    },
    {
      id: 2548,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2548',
      data_updated_at: '2018-09-11T19:43:09.794460Z',
      data: {
        created_at: '2012-02-29T07:44:54.000000Z',
        level: 2,
        slug: '火',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%81%AB',
        characters: '火',
        meanings: [
          {
            meaning: 'Fire',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [480],
      },
    },
    {
      id: 2549,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2549',
      data_updated_at: '2018-09-11T19:43:34.659767Z',
      data: {
        created_at: '2012-02-29T07:45:03.000000Z',
        level: 2,
        slug: '火山',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%81%AB%E5%B1%B1',
        characters: '火山',
        meanings: [
          {
            meaning: 'Volcano',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'かざん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [480, 455],
      },
    },
    {
      id: 2550,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2550',
      data_updated_at: '2018-09-11T19:43:50.592068Z',
      data: {
        created_at: '2012-02-29T07:45:14.000000Z',
        level: 2,
        slug: '犬',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%8A%AC',
        characters: '犬',
        meanings: [
          {
            meaning: 'Dog',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いぬ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [481],
      },
    },
    {
      id: 2551,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2551',
      data_updated_at: '2018-09-11T19:42:52.839502Z',
      data: {
        created_at: '2012-02-29T07:45:23.000000Z',
        level: 2,
        slug: '子犬',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%AD%90%E7%8A%AC',
        characters: '子犬',
        meanings: [
          {
            meaning: 'Puppy',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こいぬ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [481, 462],
      },
    },
    {
      id: 2552,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2552',
      data_updated_at: '2018-09-11T19:43:17.641183Z',
      data: {
        created_at: '2012-02-29T07:45:31.000000Z',
        level: 2,
        slug: '王',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%8E%8B',
        characters: '王',
        meanings: [
          {
            meaning: 'King',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [482],
      },
    },
    {
      id: 2553,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2553',
      data_updated_at: '2018-09-11T19:43:38.482757Z',
      data: {
        created_at: '2012-02-29T07:45:39.000000Z',
        level: 2,
        slug: '王子',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%8E%8B%E5%AD%90',
        characters: '王子',
        meanings: [
          {
            meaning: 'Prince',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おうじ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [482, 462],
      },
    },
    {
      id: 2554,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2554',
      data_updated_at: '2018-09-11T19:43:59.869977Z',
      data: {
        created_at: '2012-02-29T07:45:46.000000Z',
        level: 2,
        slug: '女王',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A5%B3%E7%8E%8B',
        characters: '女王',
        meanings: [
          {
            meaning: 'Queen',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じょおう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [482, 454],
      },
    },
    {
      id: 2555,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2555',
      data_updated_at: '2018-09-11T19:43:38.244417Z',
      data: {
        created_at: '2012-02-29T07:45:56.000000Z',
        level: 2,
        slug: '王女',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%8E%8B%E5%A5%B3',
        characters: '王女',
        meanings: [
          {
            meaning: 'Princess',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おうじょ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [482, 454],
      },
    },
    {
      id: 2556,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2556',
      data_updated_at: '2018-09-11T19:43:23.401180Z',
      data: {
        created_at: '2012-02-29T07:46:19.000000Z',
        level: 2,
        slug: '出す',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%87%BA%E3%81%99',
        characters: '出す',
        meanings: [
          {
            meaning: 'To Take Out',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Hand Out',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Remove',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Turn In',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Put Out',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'だす',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'godan_verb'],
        component_subject_ids: [483],
      },
    },
    {
      id: 2557,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2557',
      data_updated_at: '2018-09-11T19:43:28.162980Z',
      data: {
        created_at: '2012-02-29T07:46:27.000000Z',
        level: 2,
        slug: '出る',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%87%BA%E3%82%8B',
        characters: '出る',
        meanings: [
          {
            meaning: 'To Exit',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Leave',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Attend',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Come Out',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Go Out',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'でる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'ichidan_verb'],
        component_subject_ids: [483],
      },
    },
    {
      id: 2558,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2558',
      data_updated_at: '2018-09-11T19:43:38.109632Z',
      data: {
        created_at: '2012-02-29T07:46:43.000000Z',
        level: 2,
        slug: '出口',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%87%BA%E5%8F%A3',
        characters: '出口',
        meanings: [
          {
            meaning: 'Exit',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'でぐち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [483, 452],
      },
    },
    {
      id: 2559,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2559',
      data_updated_at: '2018-09-11T19:42:48.963761Z',
      data: {
        created_at: '2012-02-29T07:46:51.000000Z',
        level: 2,
        slug: '右',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%B3',
        characters: '右',
        meanings: [
          {
            meaning: 'Right Direction',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Right',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'みぎ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [484],
      },
    },
    {
      id: 2560,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2560',
      data_updated_at: '2018-09-11T19:42:57.474869Z',
      data: {
        created_at: '2012-02-29T07:47:19.000000Z',
        level: 2,
        slug: '右手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%B3%E6%89%8B',
        characters: '右手',
        meanings: [
          {
            meaning: 'Right Hand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'みぎて',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [484, 474],
      },
    },
    {
      id: 2561,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2561',
      data_updated_at: '2018-09-11T19:43:19.537099Z',
      data: {
        created_at: '2012-02-29T07:47:27.000000Z',
        level: 2,
        slug: '四',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B',
        characters: '四',
        meanings: [
          {
            meaning: 'Four',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よん',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'し',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [485],
      },
    },
    {
      id: 2562,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2562',
      data_updated_at: '2018-09-11T19:44:16.817274Z',
      data: {
        created_at: '2012-02-29T07:47:34.000000Z',
        level: 2,
        slug: '四月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B%E6%9C%88',
        characters: '四月',
        meanings: [
          {
            meaning: 'April',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'しがつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [485, 477],
      },
    },
    {
      id: 2563,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2563',
      data_updated_at: '2018-09-11T19:42:56.761725Z',
      data: {
        created_at: '2012-02-29T07:47:46.000000Z',
        level: 2,
        slug: '四日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B%E6%97%A5',
        characters: '四日',
        meanings: [
          {
            meaning: 'Fourth Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Four',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Four Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よっか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [485, 476],
      },
    },
    {
      id: 2564,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2564',
      data_updated_at: '2018-09-11T19:44:19.659174Z',
      data: {
        created_at: '2012-02-29T07:47:56.000000Z',
        level: 2,
        slug: '四つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B%E3%81%A4',
        characters: '四つ',
        meanings: [
          {
            meaning: 'Four Things',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よっつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [485],
      },
    },
    {
      id: 2565,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2565',
      data_updated_at: '2018-09-11T19:44:19.140427Z',
      data: {
        created_at: '2012-02-29T07:48:03.000000Z',
        level: 2,
        slug: '四千',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B%E5%8D%83',
        characters: '四千',
        meanings: [
          {
            meaning: 'Four Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よんせん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [485, 460],
      },
    },
    {
      id: 2566,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2566',
      data_updated_at: '2018-09-11T19:43:41.363492Z',
      data: {
        created_at: '2012-02-29T07:48:18.000000Z',
        level: 2,
        slug: '四十',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%9B%9B%E5%8D%81',
        characters: '四十',
        meanings: [
          {
            meaning: 'Forty',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よんじゅう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [485, 448],
      },
    },
    {
      id: 2567,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2567',
      data_updated_at: '2018-09-11T19:42:52.249583Z',
      data: {
        created_at: '2012-02-29T07:48:32.000000Z',
        level: 2,
        slug: '左',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B7%A6',
        characters: '左',
        meanings: [
          {
            meaning: 'Left Direction',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Left',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひだり',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [486],
      },
    },
    {
      id: 2568,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2568',
      data_updated_at: '2018-09-11T19:43:01.196311Z',
      data: {
        created_at: '2012-02-29T07:48:40.000000Z',
        level: 2,
        slug: '左手',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B7%A6%E6%89%8B',
        characters: '左手',
        meanings: [
          {
            meaning: 'Left Hand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひだりて',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [486, 474],
      },
    },
    {
      id: 2569,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2569',
      data_updated_at: '2018-09-11T19:43:25.909717Z',
      data: {
        created_at: '2012-02-29T07:49:03.000000Z',
        level: 2,
        slug: '本',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%9C%AC',
        characters: '本',
        meanings: [
          {
            meaning: 'Book',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ほん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [487],
      },
    },
    {
      id: 2570,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2570',
      data_updated_at: '2018-09-11T19:44:04.957434Z',
      data: {
        created_at: '2012-02-29T07:49:10.000000Z',
        level: 2,
        slug: '日本',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%97%A5%E6%9C%AC',
        characters: '日本',
        meanings: [
          {
            meaning: 'Japan',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にほん',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'にっぽん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['proper_noun'],
        component_subject_ids: [487, 476],
      },
    },
    {
      id: 2571,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2571',
      data_updated_at: '2018-09-11T19:44:22.242220Z',
      data: {
        created_at: '2012-02-29T07:49:20.000000Z',
        level: 2,
        slug: '正しい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E6%AD%A3%E3%81%97%E3%81%84',
        characters: '正しい',
        meanings: [
          {
            meaning: 'Correct',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'True',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ただしい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [488],
      },
    },
    {
      id: 2572,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2572',
      data_updated_at: '2018-09-11T19:43:58.278668Z',
      data: {
        created_at: '2012-02-29T07:49:28.000000Z',
        level: 2,
        slug: '正す',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%AD%A3%E3%81%99',
        characters: '正す',
        meanings: [
          {
            meaning: 'To Correct',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ただす',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'godan_verb'],
        component_subject_ids: [488],
      },
    },
    {
      id: 2573,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2573',
      data_updated_at: '2018-09-11T19:43:53.748011Z',
      data: {
        created_at: '2012-02-29T07:49:39.000000Z',
        level: 2,
        slug: '玉',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%8E%89',
        characters: '玉',
        meanings: [
          {
            meaning: 'Ball',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [489],
      },
    },
    {
      id: 2574,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2574',
      data_updated_at: '2018-09-11T19:43:07.625695Z',
      data: {
        created_at: '2012-02-29T07:49:48.000000Z',
        level: 3,
        slug: 'ビー玉',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%83%93%E3%83%BC%E7%8E%89',
        characters: 'ビー玉',
        meanings: [
          {
            meaning: 'Marble',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'びーだま',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'ビーだま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [489],
      },
    },
    {
      id: 2575,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2575',
      data_updated_at: '2018-09-11T19:44:23.289623Z',
      data: {
        created_at: '2012-02-29T07:49:56.000000Z',
        level: 2,
        slug: '玉ねぎ',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E7%8E%89%E3%81%AD%E3%81%8E',
        characters: '玉ねぎ',
        meanings: [
          {
            meaning: 'Onion',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Round Onion',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たまねぎ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [489],
      },
    },
    {
      id: 2576,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2576',
      data_updated_at: '2018-09-11T19:44:53.951152Z',
      data: {
        created_at: '2012-02-29T07:50:04.000000Z',
        level: 3,
        slug: '生まれる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E7%94%9F%E3%81%BE%E3%82%8C%E3%82%8B',
        characters: '生まれる',
        meanings: [
          {
            meaning: 'To Be Born',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'うまれる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'ichidan_verb'],
        component_subject_ids: [850],
      },
    },
    {
      id: 2577,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2577',
      data_updated_at: '2018-09-11T19:44:32.677058Z',
      data: {
        created_at: '2012-02-29T07:50:12.000000Z',
        level: 3,
        slug: '生む',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%94%9F%E3%82%80',
        characters: '生む',
        meanings: [
          {
            meaning: 'To Give Birth',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Produce',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'うむ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'godan_verb'],
        component_subject_ids: [850],
      },
    },
    {
      id: 2578,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2578',
      data_updated_at: '2018-09-11T19:46:01.420148Z',
      data: {
        created_at: '2012-02-29T07:50:20.000000Z',
        level: 3,
        slug: '生きる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E7%94%9F%E3%81%8D%E3%82%8B',
        characters: '生きる',
        meanings: [
          {
            meaning: 'To Live',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Be Alive',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Exist',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いきる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'ichidan_verb'],
        component_subject_ids: [850],
      },
    },
    {
      id: 2579,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2579',
      data_updated_at: '2018-09-11T19:37:52.088639Z',
      data: {
        created_at: '2012-02-29T07:50:29.000000Z',
        level: 3,
        slug: '生',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%94%9F',
        characters: '生',
        meanings: [
          {
            meaning: 'Fresh',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Raw',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'なま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'prefix', 'na_adjective', 'no_adjective'],
        component_subject_ids: [850],
      },
    },
    {
      id: 2580,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2580',
      data_updated_at: '2018-09-11T19:43:09.918914Z',
      data: {
        created_at: '2012-02-29T07:50:47.000000Z',
        level: 2,
        slug: '田',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%94%B0',
        characters: '田',
        meanings: [
          {
            meaning: 'Rice Field',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Rice Paddy',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'た',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [490],
      },
    },
    {
      id: 2581,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2581',
      data_updated_at: '2018-09-11T19:43:17.615512Z',
      data: {
        created_at: '2012-02-29T07:51:26.000000Z',
        level: 2,
        slug: '白',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%99%BD',
        characters: '白',
        meanings: [
          {
            meaning: 'White',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'しろ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [491],
      },
    },
    {
      id: 2582,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2582',
      data_updated_at: '2018-09-13T20:44:05.074072Z',
      data: {
        created_at: '2012-02-29T07:51:35.000000Z',
        level: 2,
        slug: '白人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%99%BD%E4%BA%BA',
        characters: '白人',
        meanings: [
          {
            meaning: 'Caucasian',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'White Person',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'White People',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はくじん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [491, 444],
      },
    },
    {
      id: 2583,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2583',
      data_updated_at: '2018-09-11T19:43:10.164110Z',
      data: {
        created_at: '2012-02-29T07:51:42.000000Z',
        level: 2,
        slug: '目',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%9B%AE',
        characters: '目',
        meanings: [
          {
            meaning: 'Eye',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'め',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [492],
      },
    },
    {
      id: 2584,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2584',
      data_updated_at: '2018-09-11T19:44:13.483841Z',
      data: {
        created_at: '2012-02-29T07:51:51.000000Z',
        level: 2,
        slug: '目玉',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%9B%AE%E7%8E%89',
        characters: '目玉',
        meanings: [
          {
            meaning: 'Eyeball',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'めだま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [492, 489],
      },
    },
    {
      id: 2585,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2585',
      data_updated_at: '2018-09-11T19:43:49.132848Z',
      data: {
        created_at: '2012-02-29T07:51:59.000000Z',
        level: 2,
        slug: '石',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%9F%B3',
        characters: '石',
        meanings: [
          {
            meaning: 'Stone',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [493],
      },
    },
    {
      id: 2586,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2586',
      data_updated_at: '2018-09-11T19:37:22.014461Z',
      data: {
        created_at: '2012-02-29T07:52:06.000000Z',
        level: 2,
        slug: '立つ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%AB%8B%E3%81%A4',
        characters: '立つ',
        meanings: [
          {
            meaning: 'To Stand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [494],
      },
    },
    {
      id: 2587,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2587',
      data_updated_at: '2018-09-11T19:44:05.465905Z',
      data: {
        created_at: '2012-02-29T07:52:13.000000Z',
        level: 2,
        slug: '立てる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E7%AB%8B%E3%81%A6%E3%82%8B',
        characters: '立てる',
        meanings: [
          {
            meaning: 'To Stand Up',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Stand Something Up',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Erect',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Erect Something',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たてる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [494],
      },
    },
    {
      id: 2588,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2588',
      data_updated_at: '2018-09-11T19:46:19.917124Z',
      data: {
        created_at: '2012-03-01T18:19:56.000000Z',
        level: 3,
        slug: '一万',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E4%B8%87',
        characters: '一万',
        meanings: [
          {
            meaning: 'Ten Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いちまん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [495, 440],
      },
    },
    {
      id: 2589,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2589',
      data_updated_at: '2018-09-11T19:45:55.810734Z',
      data: {
        created_at: '2012-03-01T18:20:03.000000Z',
        level: 3,
        slug: '二万',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E4%B8%87',
        characters: '二万',
        meanings: [
          {
            meaning: 'Twenty Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にまん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [495, 441],
      },
    },
    {
      id: 2590,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2590',
      data_updated_at: '2018-09-11T19:46:22.096976Z',
      data: {
        created_at: '2012-03-01T18:20:11.000000Z',
        level: 3,
        slug: '十万',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81%E4%B8%87',
        characters: '十万',
        meanings: [
          {
            meaning: 'One Hundred Thousand',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Hundred Thousand',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'A Hundred Thousand',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じゅうまん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [495, 448],
      },
    },
    {
      id: 2591,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2591',
      data_updated_at: '2018-09-11T19:46:17.661915Z',
      data: {
        created_at: '2012-03-01T18:20:20.000000Z',
        level: 3,
        slug: '久しい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B9%85%E3%81%97%E3%81%84',
        characters: '久しい',
        meanings: [
          {
            meaning: 'Long Time',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひさしい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [496],
      },
    },
    {
      id: 2592,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2592',
      data_updated_at: '2018-09-11T19:44:58.022157Z',
      data: {
        created_at: '2012-03-01T18:20:27.000000Z',
        level: 3,
        slug: '久しぶり',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%B9%85%E3%81%97%E3%81%B6%E3%82%8A',
        characters: '久しぶり',
        meanings: [
          {
            meaning: 'Long Time No See',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: "It's Been A While",
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひさしぶり',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['na_adjective', 'no_adjective'],
        component_subject_ids: [496],
      },
    },
    {
      id: 2593,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2593',
      data_updated_at: '2018-09-11T19:45:06.041614Z',
      data: {
        created_at: '2012-03-01T18:20:49.000000Z',
        level: 3,
        slug: '今',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BB%8A',
        characters: '今',
        meanings: [
          {
            meaning: 'Now',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いま',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [497],
      },
    },
    {
      id: 2594,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2594',
      data_updated_at: '2018-09-11T19:45:26.147567Z',
      data: {
        created_at: '2012-03-01T18:21:14.000000Z',
        level: 3,
        slug: '今日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BB%8A%E6%97%A5',
        characters: '今日',
        meanings: [
          {
            meaning: 'Today',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'きょう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [497, 476],
      },
    },
    {
      id: 2595,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2595',
      data_updated_at: '2018-09-11T19:44:51.537149Z',
      data: {
        created_at: '2012-03-01T18:21:20.000000Z',
        level: 3,
        slug: '今月',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BB%8A%E6%9C%88',
        characters: '今月',
        meanings: [
          {
            meaning: 'This Month',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Current Month',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こんげつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [497, 477],
      },
    },
    {
      id: 2596,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2596',
      data_updated_at: '2018-09-11T19:44:43.810477Z',
      data: {
        created_at: '2012-03-01T18:21:29.000000Z',
        level: 3,
        slug: '元',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%83',
        characters: '元',
        meanings: [
          {
            meaning: 'Origin',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'もと',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [498],
      },
    },
    {
      id: 2597,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2597',
      data_updated_at: '2018-09-11T19:46:16.846657Z',
      data: {
        created_at: '2012-03-01T18:21:52.000000Z',
        level: 3,
        slug: '半分',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%8A%E5%88%86',
        characters: '半分',
        meanings: [
          {
            meaning: 'Half',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はんぶん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [518, 501],
      },
    },
    {
      id: 2598,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2598',
      data_updated_at: '2018-09-11T19:45:50.946393Z',
      data: {
        created_at: '2012-03-01T18:22:05.000000Z',
        level: 3,
        slug: '分かる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%88%86%E3%81%8B%E3%82%8B',
        characters: '分かる',
        meanings: [
          {
            meaning: 'To Understand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'わかる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [501],
      },
    },
    {
      id: 2599,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2599',
      data_updated_at: '2018-09-11T19:46:01.249662Z',
      data: {
        created_at: '2012-03-01T18:22:13.000000Z',
        level: 3,
        slug: '分ける',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%88%86%E3%81%91%E3%82%8B',
        characters: '分ける',
        meanings: [
          {
            meaning: 'To Separate',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Divide',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Part',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Split',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'わける',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [501],
      },
    },
    {
      id: 2600,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2600',
      data_updated_at: '2018-09-11T20:01:49.388676Z',
      data: {
        created_at: '2012-03-01T18:22:19.000000Z',
        level: 3,
        slug: '分',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%88%86',
        characters: '分',
        meanings: [
          {
            meaning: 'Minute',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Part',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ぶん',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'ふん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'prefix', 'suffix'],
        component_subject_ids: [501],
      },
    },
    {
      id: 2601,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2601',
      data_updated_at: '2018-09-11T19:46:09.358739Z',
      data: {
        created_at: '2012-03-01T18:22:25.000000Z',
        level: 3,
        slug: '切れる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%88%87%E3%82%8C%E3%82%8B',
        characters: '切れる',
        meanings: [
          {
            meaning: 'To Be Cut',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Snap',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Break',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'きれる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'ichidan_verb'],
        component_subject_ids: [502],
      },
    },
    {
      id: 2602,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2602',
      data_updated_at: '2018-09-11T19:44:51.456058Z',
      data: {
        created_at: '2012-03-01T18:22:33.000000Z',
        level: 3,
        slug: '大切',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%A7%E5%88%87',
        characters: '大切',
        meanings: [
          {
            meaning: 'Important',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Precious',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Valuable',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'たいせつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'na_adjective'],
        component_subject_ids: [502, 453],
      },
    },
    {
      id: 2603,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2603',
      data_updated_at: '2018-09-11T19:45:28.258943Z',
      data: {
        created_at: '2012-03-01T18:22:46.000000Z',
        level: 3,
        slug: '切る',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%88%87%E3%82%8B',
        characters: '切る',
        meanings: [
          {
            meaning: 'To Cut',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'きる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['suffix', 'transitive_verb', 'godan_verb'],
        component_subject_ids: [502],
      },
    },
    {
      id: 2604,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2604',
      data_updated_at: '2018-09-11T19:44:52.072001Z',
      data: {
        created_at: '2012-03-01T18:22:53.000000Z',
        level: 3,
        slug: '友人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%8B%E4%BA%BA',
        characters: '友人',
        meanings: [
          {
            meaning: 'Friend',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ゆうじん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [504, 444],
      },
    },
    {
      id: 2605,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2605',
      data_updated_at: '2018-09-11T19:45:56.099590Z',
      data: {
        created_at: '2012-03-01T18:22:59.000000Z',
        level: 3,
        slug: '太い',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%AA%E3%81%84',
        characters: '太い',
        meanings: [
          {
            meaning: 'Fat',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Thick',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふとい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [505],
      },
    },
    {
      id: 2606,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2606',
      data_updated_at: '2018-09-11T19:45:57.244312Z',
      data: {
        created_at: '2012-03-01T18:23:05.000000Z',
        level: 3,
        slug: '太る',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%AA%E3%82%8B',
        characters: '太る',
        meanings: [
          {
            meaning: 'To Get Fat',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Gain Weight',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Grow Fat',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Become Fat',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふとる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [505],
      },
    },
    {
      id: 2607,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2607',
      data_updated_at: '2018-09-11T19:45:51.885696Z',
      data: {
        created_at: '2012-03-01T18:23:13.000000Z',
        level: 3,
        slug: '少し',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B0%91%E3%81%97',
        characters: '少し',
        meanings: [
          {
            meaning: 'A Little',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'A Few',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Few',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'すこし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adverb', 'noun'],
        component_subject_ids: [506],
      },
    },
    {
      id: 2608,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2608',
      data_updated_at: '2018-09-11T19:46:18.770062Z',
      data: {
        created_at: '2012-03-01T18:23:22.000000Z',
        level: 3,
        slug: '少ない',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%B0%91%E3%81%AA%E3%81%84',
        characters: '少ない',
        meanings: [
          {
            meaning: 'A Few',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Few',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Scarce',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Not Much',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Not Many',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'すくない',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [506],
      },
    },
    {
      id: 2609,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2609',
      data_updated_at: '2018-09-11T19:44:43.765221Z',
      data: {
        created_at: '2012-03-01T18:23:31.000000Z',
        level: 3,
        slug: '引く',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%BC%95%E3%81%8F',
        characters: '引く',
        meanings: [
          {
            meaning: 'To Pull',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Subtract',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひく',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'transitive_verb', 'godan_verb'],
        component_subject_ids: [507],
      },
    },
    {
      id: 2610,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2610',
      data_updated_at: '2018-09-11T19:46:13.988578Z',
      data: {
        created_at: '2012-03-01T18:23:41.000000Z',
        level: 3,
        slug: '心',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%BF%83',
        characters: '心',
        meanings: [
          {
            meaning: 'Heart',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こころ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [508],
      },
    },
    {
      id: 2611,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2611',
      data_updated_at: '2018-09-11T19:45:38.457477Z',
      data: {
        created_at: '2012-03-01T18:23:48.000000Z',
        level: 3,
        slug: '戸口',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%88%B8%E5%8F%A3',
        characters: '戸口',
        meanings: [
          {
            meaning: 'Doorway',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'とぐち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [509, 452],
      },
    },
    {
      id: 2612,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2612',
      data_updated_at: '2018-09-11T19:45:45.347919Z',
      data: {
        created_at: '2012-03-01T18:23:59.000000Z',
        level: 3,
        slug: '方',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%96%B9',
        characters: '方',
        meanings: [
          {
            meaning: 'Way',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Direction',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'かた',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'ほう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [510],
      },
    },
    {
      id: 2613,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2613',
      data_updated_at: '2018-09-11T19:44:44.574597Z',
      data: {
        created_at: '2012-03-01T18:24:07.000000Z',
        level: 3,
        slug: '止まる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E6%AD%A2%E3%81%BE%E3%82%8B',
        characters: '止まる',
        meanings: [
          {
            meaning: 'To Stop',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'とまる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'godan_verb'],
        component_subject_ids: [514],
      },
    },
    {
      id: 2614,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2614',
      data_updated_at: '2018-09-11T19:44:42.546434Z',
      data: {
        created_at: '2012-03-01T18:24:17.000000Z',
        level: 3,
        slug: '止める',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E6%AD%A2%E3%82%81%E3%82%8B',
        characters: '止める',
        meanings: [
          {
            meaning: 'To Stop Something',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Stop',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'とめる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [514],
      },
    },
    {
      id: 2615,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2615',
      data_updated_at: '2018-09-11T19:44:42.915934Z',
      data: {
        created_at: '2012-03-01T18:24:23.000000Z',
        level: 3,
        slug: '中止',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%AD%E6%AD%A2',
        characters: '中止',
        meanings: [
          {
            meaning: 'Suspension',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Cancellation',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Stoppage',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Discontinuance',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Interruption',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ちゅうし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suru_verb'],
        component_subject_ids: [514, 469],
      },
    },
    {
      id: 2616,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2616',
      data_updated_at: '2018-09-11T19:44:33.190313Z',
      data: {
        created_at: '2012-03-01T18:24:38.000000Z',
        level: 3,
        slug: '毛',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%AF%9B',
        characters: '毛',
        meanings: [
          {
            meaning: 'Fur',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Hair',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'け',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [513],
      },
    },
    {
      id: 2617,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2617',
      data_updated_at: '2018-09-11T20:02:18.432379Z',
      data: {
        created_at: '2012-03-01T18:24:44.000000Z',
        level: 3,
        slug: '父',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%88%B6',
        characters: '父',
        meanings: [
          {
            meaning: 'Father',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ちち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [512],
      },
    },
    {
      id: 2618,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2618',
      data_updated_at: '2018-09-11T19:46:08.921605Z',
      data: {
        created_at: '2012-03-01T18:24:52.000000Z',
        level: 3,
        slug: 'お父さん',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%81%8A%E7%88%B6%E3%81%95%E3%82%93',
        characters: 'お父さん',
        meanings: [
          {
            meaning: 'Father',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Dad',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Papa',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おとうさん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [512],
      },
    },
    {
      id: 2619,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2619',
      data_updated_at: '2018-09-11T19:44:34.237993Z',
      data: {
        created_at: '2012-03-01T18:24:58.000000Z',
        level: 3,
        slug: '牛',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%89%9B',
        characters: '牛',
        meanings: [
          {
            meaning: 'Cow',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'うし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [511],
      },
    },
    {
      id: 2620,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2620',
      data_updated_at: '2018-09-11T19:45:10.393455Z',
      data: {
        created_at: '2012-03-01T18:25:12.000000Z',
        level: 3,
        slug: '兄',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%84',
        characters: '兄',
        meanings: [
          {
            meaning: 'Older Brother',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Big Brother',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Elder Brother',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'あに',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [515],
      },
    },
    {
      id: 2621,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2621',
      data_updated_at: '2018-09-11T19:46:13.535053Z',
      data: {
        created_at: '2012-03-01T18:25:37.000000Z',
        level: 3,
        slug: 'お兄さん',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%81%8A%E5%85%84%E3%81%95%E3%82%93',
        characters: 'お兄さん',
        meanings: [
          {
            meaning: 'Older Brother',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Big Brother',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Elder Brother',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おにいさん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [515],
      },
    },
    {
      id: 2622,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2622',
      data_updated_at: '2018-09-11T19:45:46.594724Z',
      data: {
        created_at: '2012-03-01T18:25:49.000000Z',
        level: 3,
        slug: '冬',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%86%AC',
        characters: '冬',
        meanings: [
          {
            meaning: 'Winter',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふゆ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [516],
      },
    },
    {
      id: 2623,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2623',
      data_updated_at: '2018-09-11T19:44:35.239845Z',
      data: {
        created_at: '2012-03-01T18:25:56.000000Z',
        level: 3,
        slug: '北',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8C%97',
        characters: '北',
        meanings: [
          {
            meaning: 'North',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'きた',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [517],
      },
    },
    {
      id: 2624,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2624',
      data_updated_at: '2018-09-11T19:45:14.043259Z',
      data: {
        created_at: '2012-03-01T18:26:40.000000Z',
        level: 3,
        slug: '半',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%8A',
        characters: '半',
        meanings: [
          {
            meaning: 'Half',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'prefix'],
        component_subject_ids: [518],
      },
    },
    {
      id: 2625,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2625',
      data_updated_at: '2018-09-11T19:46:04.225658Z',
      data: {
        created_at: '2012-03-01T18:26:48.000000Z',
        level: 3,
        slug: '古い',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8F%A4%E3%81%84',
        characters: '古い',
        meanings: [
          {
            meaning: 'Old',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふるい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adjective'],
        component_subject_ids: [519],
      },
    },
    {
      id: 2626,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2626',
      data_updated_at: '2018-09-11T19:44:46.973667Z',
      data: {
        created_at: '2012-03-01T18:26:56.000000Z',
        level: 3,
        slug: '中古',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%AD%E5%8F%A4',
        characters: '中古',
        meanings: [
          {
            meaning: 'Secondhand',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Used',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Second Hand',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ちゅうこ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [519, 469],
      },
    },
    {
      id: 2627,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2627',
      data_updated_at: '2018-09-11T19:46:09.283549Z',
      data: {
        created_at: '2012-03-01T18:27:04.000000Z',
        level: 3,
        slug: '一台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E5%8F%B0',
        characters: '一台',
        meanings: [
          {
            meaning: 'One Machine',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いちだい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [520, 440],
      },
    },
    {
      id: 2628,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2628',
      data_updated_at: '2018-09-11T19:45:27.954583Z',
      data: {
        created_at: '2012-03-01T18:27:10.000000Z',
        level: 3,
        slug: '二台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E5%8F%B0',
        characters: '二台',
        meanings: [
          {
            meaning: 'Two Machines',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にだい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [520, 441],
      },
    },
    {
      id: 2629,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2629',
      data_updated_at: '2018-09-11T19:44:42.096055Z',
      data: {
        created_at: '2012-03-01T18:27:17.000000Z',
        level: 3,
        slug: '五台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%94%E5%8F%B0',
        characters: '五台',
        meanings: [
          {
            meaning: 'Five Machines',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ごだい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [520, 470],
      },
    },
    {
      id: 2630,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2630',
      data_updated_at: '2018-09-11T19:45:38.500071Z',
      data: {
        created_at: '2012-03-01T18:27:24.000000Z',
        level: 3,
        slug: '十台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81%E5%8F%B0',
        characters: '十台',
        meanings: [
          {
            meaning: 'Ten Machines',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じゅうだい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [520, 448],
      },
    },
    {
      id: 2631,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2631',
      data_updated_at: '2018-09-11T20:02:41.468305Z',
      data: {
        created_at: '2012-03-01T18:27:36.000000Z',
        level: 3,
        slug: '外',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%96',
        characters: '外',
        meanings: [
          {
            meaning: 'Outside',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'そと',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [521],
      },
    },
    {
      id: 2632,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2632',
      data_updated_at: '2018-09-11T19:44:52.742117Z',
      data: {
        created_at: '2012-03-01T18:27:45.000000Z',
        level: 3,
        slug: '外人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%96%E4%BA%BA',
        characters: '外人',
        meanings: [
          {
            meaning: 'Foreigner',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Outsider',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'がいじん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [521, 444],
      },
    },
    {
      id: 2633,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2633',
      data_updated_at: '2018-09-11T19:44:43.377641Z',
      data: {
        created_at: '2012-03-01T18:27:52.000000Z',
        level: 3,
        slug: '外れ',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%A4%96%E3%82%8C',
        characters: '外れ',
        meanings: [
          {
            meaning: 'Extremity',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Furthest Point',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'End',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Outskirts',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Outer Limits',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Edge',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Miss',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Failure',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はずれ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [521],
      },
    },
    {
      id: 2634,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2634',
      data_updated_at: '2018-09-11T19:44:56.253784Z',
      data: {
        created_at: '2012-03-01T18:28:00.000000Z',
        level: 3,
        slug: '外れる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%96%E3%82%8C%E3%82%8B',
        characters: '外れる',
        meanings: [
          {
            meaning: 'To Be Disconnected',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Come Off',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Be Out',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Be Off',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はずれる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['intransitive_verb', 'ichidan_verb'],
        component_subject_ids: [521],
      },
    },
    {
      id: 2635,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2635',
      data_updated_at: '2018-09-11T19:45:00.155735Z',
      data: {
        created_at: '2012-03-01T18:28:09.000000Z',
        level: 3,
        slug: '市',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B8%82',
        characters: '市',
        meanings: [
          {
            meaning: 'City',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'し',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [522],
      },
    },
    {
      id: 2636,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2636',
      data_updated_at: '2018-09-11T20:02:22.041950Z',
      data: {
        created_at: '2012-03-01T18:28:19.000000Z',
        level: 3,
        slug: '市立',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B8%82%E7%AB%8B',
        characters: '市立',
        meanings: [
          {
            meaning: 'Municipal',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'City',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'しりつ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [522, 494],
      },
    },
    {
      id: 2637,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2637',
      data_updated_at: '2018-09-11T19:46:28.749911Z',
      data: {
        created_at: '2012-03-01T18:28:26.000000Z',
        level: 3,
        slug: 'シアトル市',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%82%B7%E3%82%A2%E3%83%88%E3%83%AB%E5%B8%82',
        characters: 'シアトル市',
        meanings: [
          {
            meaning: 'Seattle',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'City Of Seattle',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'しあとるし',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'シアトルし',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['proper_noun'],
        component_subject_ids: [522],
      },
    },
    {
      id: 2638,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2638',
      data_updated_at: '2018-09-11T19:45:50.923863Z',
      data: {
        created_at: '2012-03-01T18:28:33.000000Z',
        level: 3,
        slug: '広い',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%BA%83%E3%81%84',
        characters: '広い',
        meanings: [
          {
            meaning: 'Wide',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Spacious',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひろい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [523],
      },
    },
    {
      id: 2639,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2639',
      data_updated_at: '2018-09-11T19:45:12.108326Z',
      data: {
        created_at: '2012-03-01T18:28:40.000000Z',
        level: 3,
        slug: '母',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%AF%8D',
        characters: '母',
        meanings: [
          {
            meaning: 'Mother',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'はは',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [524],
      },
    },
    {
      id: 2640,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2640',
      data_updated_at: '2018-09-11T19:46:11.623796Z',
      data: {
        created_at: '2012-03-01T18:28:47.000000Z',
        level: 3,
        slug: 'お母さん',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E3%81%8A%E6%AF%8D%E3%81%95%E3%82%93',
        characters: 'お母さん',
        meanings: [
          {
            meaning: 'Mother',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Mom',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Mum',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おかあさん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [524],
      },
    },
    {
      id: 2641,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2641',
      data_updated_at: '2018-09-11T19:46:22.597632Z',
      data: {
        created_at: '2012-03-01T18:28:55.000000Z',
        level: 3,
        slug: '用いる',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E7%94%A8%E3%81%84%E3%82%8B',
        characters: '用いる',
        meanings: [
          {
            meaning: 'To Utilize',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'To Use',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'To Utilise',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'もちいる',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['transitive_verb', 'ichidan_verb'],
        component_subject_ids: [525],
      },
    },
    {
      id: 2642,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2642',
      data_updated_at: '2018-09-11T19:44:54.083990Z',
      data: {
        created_at: '2012-03-01T18:29:01.000000Z',
        level: 3,
        slug: '公用',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AC%E7%94%A8',
        characters: '公用',
        meanings: [
          {
            meaning: 'Government Business',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Official Business',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こうよう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [525, 499],
      },
    },
    {
      id: 2643,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/2643',
      data_updated_at: '2018-09-11T19:44:59.956728Z',
      data: {
        created_at: '2012-03-01T18:29:08.000000Z',
        level: 3,
        slug: '矢',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%9F%A2',
        characters: '矢',
        meanings: [
          {
            meaning: 'Arrow',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'や',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [526],
      },
    },
    {
      id: 3237,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/3237',
      data_updated_at: '2018-09-11T19:43:43.183416Z',
      data: {
        created_at: '2012-04-25T17:33:34.000000Z',
        level: 2,
        slug: '四十二',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%9B%9B%E5%8D%81%E4%BA%8C',
        characters: '四十二',
        meanings: [
          {
            meaning: 'Forty Two',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'The Answer',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よんじゅうに',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [485, 448, 441],
      },
    },
    {
      id: 3402,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/3402',
      data_updated_at: '2018-09-11T19:42:20.640660Z',
      data: {
        created_at: '2012-05-18T17:08:14.642177Z',
        level: 1,
        slug: '人口',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%BA%E5%8F%A3',
        characters: '人口',
        meanings: [
          {
            meaning: 'Population',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じんこう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [452, 444],
      },
    },
    {
      id: 3407,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/3407',
      data_updated_at: '2018-09-11T19:45:38.601548Z',
      data: {
        created_at: '2012-06-12T00:16:35.883506Z',
        level: 3,
        slug: '人生',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%BA%E7%94%9F',
        characters: '人生',
        meanings: [
          {
            meaning: "One's Life",
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Human Life',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じんせい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [850, 444],
      },
    },
    {
      id: 5389,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/5389',
      data_updated_at: '2018-09-11T19:44:33.353467Z',
      data: {
        created_at: '2013-02-06T15:02:15.493910Z',
        level: 3,
        slug: '内',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%86%85',
        characters: '内',
        meanings: [
          {
            meaning: 'Inside',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Within',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'うち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [500],
      },
    },
    {
      id: 7455,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7455',
      data_updated_at: '2018-09-11T19:45:12.526954Z',
      data: {
        created_at: '2013-10-10T19:55:55.160745Z',
        level: 3,
        slug: '〜台',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E3%80%9C%E5%8F%B0',
        characters: '〜台',
        meanings: [
          {
            meaning: 'Big Machine',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Big Device',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Machine',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Device',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Machine Counter',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Number Of Machines',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'だい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['counter', 'suffix'],
        component_subject_ids: [520],
      },
    },
    {
      id: 7465,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7465',
      data_updated_at: '2018-09-11T19:45:26.588221Z',
      data: {
        created_at: '2013-10-11T18:46:26.587327Z',
        level: 3,
        slug: '〜人',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E3%80%9C%E4%BA%BA',
        characters: '〜人',
        meanings: [
          {
            meaning: 'Number Of People',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'People',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'People Counter',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Counter For People',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['counter'],
        component_subject_ids: [444],
      },
    },
    {
      id: 7515,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7515',
      data_updated_at: '2018-09-11T19:42:55.510320Z',
      data: {
        created_at: '2014-10-28T00:39:33.118256Z',
        level: 2,
        slug: '二日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%8C%E6%97%A5',
        characters: '二日',
        meanings: [
          {
            meaning: 'Second Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Two',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Two Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ふつか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 441],
      },
    },
    {
      id: 7517,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7517',
      data_updated_at: '2018-09-11T19:44:07.009338Z',
      data: {
        created_at: '2014-11-14T18:19:46.833856Z',
        level: 2,
        slug: '人々',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%BA%E3%80%85',
        characters: '人々',
        meanings: [
          {
            meaning: 'People',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Everybody',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ひとびと',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [856, 444],
      },
    },
    {
      id: 7518,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7518',
      data_updated_at: '2018-09-11T19:45:34.798426Z',
      data: {
        created_at: '2014-11-14T18:25:46.114667Z',
        level: 3,
        slug: '少女',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B0%91%E5%A5%B3',
        characters: '少女',
        meanings: [
          {
            meaning: 'Girl',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Young Lady',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Little Girl',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Maiden',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'しょうじょ',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'おとめ',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [506, 454],
      },
    },
    {
      id: 7519,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7519',
      data_updated_at: '2018-09-11T19:45:26.339253Z',
      data: {
        created_at: '2014-11-14T18:29:21.504737Z',
        level: 3,
        slug: '万',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%87',
        characters: '万',
        meanings: [
          {
            meaning: 'Ten Thousand',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'まん',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['numeral'],
        component_subject_ids: [495],
      },
    },
    {
      id: 7560,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7560',
      data_updated_at: '2018-09-11T19:42:17.656309Z',
      data: {
        created_at: '2015-02-25T22:23:17.972924Z',
        level: 1,
        slug: '人工',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%BA%BA%E5%B7%A5',
        characters: '人工',
        meanings: [
          {
            meaning: 'Artificial',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Man Made',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Human Made',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'じんこう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [457, 444],
      },
    },
    {
      id: 7561,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7561',
      data_updated_at: '2018-09-11T19:44:32.270388Z',
      data: {
        created_at: '2015-02-25T22:24:10.545552Z',
        level: 2,
        slug: '一日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%80%E6%97%A5',
        characters: '一日',
        meanings: [
          {
            meaning: 'One Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day One',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'First Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'いちにち',
            accepted_answer: true,
          },
          {
            primary: false,
            reading: 'ついたち',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 440],
      },
    },
    {
      id: 7562,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7562',
      data_updated_at: '2018-09-11T19:42:55.996994Z',
      data: {
        created_at: '2015-02-25T22:25:21.799617Z',
        level: 2,
        slug: '七日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%83%E6%97%A5',
        characters: '七日',
        meanings: [
          {
            meaning: 'Seventh Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Seven',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Seven Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'なのか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 443],
      },
    },
    {
      id: 7563,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7563',
      data_updated_at: '2018-09-11T19:43:01.609559Z',
      data: {
        created_at: '2015-02-25T22:27:58.843119Z',
        level: 2,
        slug: '三日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B8%89%E6%97%A5',
        characters: '三日',
        meanings: [
          {
            meaning: 'Third Day',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Three',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Three Days',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'みっか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 449],
      },
    },
    {
      id: 7564,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7564',
      data_updated_at: '2018-05-21T21:55:36.607049Z',
      data: {
        created_at: '2015-02-25T22:29:25.843789Z',
        level: 3,
        slug: '今日は',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E4%BB%8A%E6%97%A5%E3%81%AF',
        characters: '今日は',
        meanings: [
          {
            meaning: 'Hello',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Good Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'こんにちは',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['interjection'],
        component_subject_ids: [497, 476],
      },
    },
    {
      id: 7614,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7614',
      data_updated_at: '2018-09-11T19:44:27.159103Z',
      data: {
        created_at: '2015-03-23T22:08:05.931291Z',
        level: 2,
        slug: '九日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E4%B9%9D%E6%97%A5',
        characters: '九日',
        meanings: [
          {
            meaning: 'Nine Days',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Nine',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Ninth Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ここのか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 442],
      },
    },
    {
      id: 7615,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7615',
      data_updated_at: '2018-09-11T19:42:55.849083Z',
      data: {
        created_at: '2015-03-23T22:12:16.264985Z',
        level: 2,
        slug: '八日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%AB%E6%97%A5',
        characters: '八日',
        meanings: [
          {
            meaning: 'Eight Days',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Eight',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Eighth Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'ようか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 446],
      },
    },
    {
      id: 7616,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7616',
      data_updated_at: '2018-09-11T19:42:59.551193Z',
      data: {
        created_at: '2015-03-23T22:15:09.052837Z',
        level: 2,
        slug: '十日',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%8D%81%E6%97%A5',
        characters: '十日',
        meanings: [
          {
            meaning: 'Ten Days',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Day Ten',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Tenth Day',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'とおか',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [476, 448],
      },
    },
    {
      id: 7668,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7668',
      data_updated_at: '2018-09-11T19:43:40.706782Z',
      data: {
        created_at: '2015-05-11T16:56:31.629975Z',
        level: 2,
        slug: '大いに',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%A7%E3%81%84%E3%81%AB',
        characters: '大いに',
        meanings: [
          {
            meaning: 'Very',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Much',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Greatly',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'A Lot Of',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Very Much',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おおいに',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['adverb'],
        component_subject_ids: [453],
      },
    },
    {
      id: 7669,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7669',
      data_updated_at: '2018-09-11T19:45:02.582512Z',
      data: {
        created_at: '2015-05-11T17:00:59.299439Z',
        level: 3,
        slug: '用',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E7%94%A8',
        characters: '用',
        meanings: [
          {
            meaning: 'Task',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Use',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Duty',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Service',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Business',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Purpose',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Function',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'よう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suffix'],
        component_subject_ids: [525],
      },
    },
    {
      id: 7728,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/7728',
      data_updated_at: '2018-09-11T19:43:04.417326Z',
      data: {
        created_at: '2015-06-25T17:06:33.774997Z',
        level: 2,
        slug: '水中',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%B0%B4%E4%B8%AD',
        characters: '水中',
        meanings: [
          {
            meaning: 'Underwater',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'すいちゅう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'no_adjective'],
        component_subject_ids: [479, 469],
      },
    },
    {
      id: 8659,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/8659',
      data_updated_at: '2018-09-11T19:46:24.140751Z',
      data: {
        created_at: '2015-08-24T17:41:32.867791Z',
        level: 3,
        slug: '大人しい',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A4%A7%E4%BA%BA%E3%81%97%E3%81%84',
        characters: '大人しい',
        meanings: [
          {
            meaning: 'Obedient',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Docile',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Quiet',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おとなしい',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['i_adjective'],
        component_subject_ids: [453, 444],
      },
    },
    {
      id: 8693,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/8693',
      data_updated_at: '2018-09-11T19:45:39.961140Z',
      data: {
        created_at: '2015-10-26T19:19:20.145014Z',
        level: 3,
        slug: '左右',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%B7%A6%E5%8F%B3',
        characters: '左右',
        meanings: [
          {
            meaning: 'Left And Right',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Both Ways',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Influence',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Control',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'さゆう',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suru_verb'],
        component_subject_ids: [486, 484],
      },
    },
    {
      id: 8733,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/8733',
      data_updated_at: '2018-09-11T19:43:06.590486Z',
      data: {
        created_at: '2016-04-05T17:49:51.283644Z',
        level: 2,
        slug: '入力',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E5%85%A5%E5%8A%9B',
        characters: '入力',
        meanings: [
          {
            meaning: 'Input',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Enter',
            primary: false,
            accepted_answer: true,
          },
          {
            meaning: 'Entry',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'にゅうりょく',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun', 'suru_verb'],
        component_subject_ids: [447, 445],
      },
    },
    {
      id: 8734,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/8734',
      data_updated_at: '2018-09-11T19:44:31.653661Z',
      data: {
        created_at: '2016-04-05T17:53:21.308855Z',
        level: 3,
        slug: '戸',
        hidden_at: null,
        document_url: 'https://www.wanikani.com/vocabulary/%E6%88%B8',
        characters: '戸',
        meanings: [
          {
            meaning: 'Japanese Style Door',
            primary: true,
            accepted_answer: true,
          },
          {
            meaning: 'Door',
            primary: false,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'と',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [509],
      },
    },
    {
      id: 8735,
      object: 'vocabulary',
      url: 'https://api.wanikani.com/v2/subjects/8735',
      data_updated_at: '2018-09-11T19:45:00.187420Z',
      data: {
        created_at: '2016-04-05T17:59:10.479594Z',
        level: 3,
        slug: '女の人',
        hidden_at: null,
        document_url:
          'https://www.wanikani.com/vocabulary/%E5%A5%B3%E3%81%AE%E4%BA%BA',
        characters: '女の人',
        meanings: [
          {
            meaning: 'Woman',
            primary: true,
            accepted_answer: true,
          },
        ],
        readings: [
          {
            primary: true,
            reading: 'おんなのひと',
            accepted_answer: true,
          },
        ],
        parts_of_speech: ['noun'],
        component_subject_ids: [454, 444],
      },
    },
  ],
}
