import flatten from 'lodash/flatten';

export const filterByLevel = (responseData, level) =>
  responseData.filter((dataEntry) => level === dataEntry.data.level);

export const extractComponentIds = (responseData) => {
  const ids = flatten(responseData.map((dataEntry) => dataEntry.data.component_subject_ids));

  const uniqueIds = [...new Set(ids)];
  return uniqueIds;
};

export const filterByIds = (responseData, ids) => responseData.filter(({ id }) => ids.includes(id));

const removeUnderscores = (stringArray) =>
  stringArray.map((s) => s.replace(new RegExp('_', 'g'), ' '));

const parseVocabResponseData = (vocabResponseData) =>
  vocabResponseData.map(({ id, data }) => ({
    id,
    level: data.level,
    url: data.document_url,
    characters: data.characters,
    meanings: data.meanings.map((m) => m.meaning),
    readings: data.readings.map((r) => r.reading),
    partsOfSpeech: removeUnderscores(data.parts_of_speech),
    kanjiList: data.component_subject_ids,
  }));

const parseKanjiResponseData = (kanjiResponseData, vocabList) =>
  kanjiResponseData.map(({ id, data }) => ({
    id,
    level: data.level,
    url: data.document_url,
    kanji: data.characters,
    vocabList: vocabList.filter((v) => v.kanjiList.includes(id)).map((v) => v.id),
  }));

export const parseLevelResponseData = (level, vocabData, kanjiData) => {
  const vocabList = parseVocabResponseData(vocabData);
  const kanjiList = parseKanjiResponseData(kanjiData, vocabList);

  return {
    level,
    vocabList,
    kanjiList,
  };
};
