import { types } from 'mobx-state-tree';

const GameSettingsStore = types
  .model('GameSettingsStore', {
    showKanjiHints: true,
    showVocabHints: true,
    autoFocusInput: false,
    keepKanjiInView: false,
    increaseKanjiSize: false,
  })
  .actions((self) => ({
    setShowKanjiHints(showKanjiHints) {
      self.showKanjiHints = showKanjiHints;
    },
    setShowVocabHints(showVocabHints) {
      self.showVocabHints = showVocabHints;
    },
    setAutoFocusInput(autoFocusInput) {
      self.autoFocusInput = autoFocusInput;
    },
    setKeepKanjiInView(keepKanjiInView) {
      self.keepKanjiInView = keepKanjiInView;
    },
    setIncreaseKanjiSize(increaseKanjiSize) {
      self.increaseKanjiSize = increaseKanjiSize;
    },
  }));

export default GameSettingsStore;
