import React, { Component } from 'react';
import NewTabLink from 'components/shared/NewTabLink';

class WaniKaniLink extends Component {
  render() {
    const { goToAPIKey, children } = this.props;
    const href = goToAPIKey
      ? 'https://wanikani.com/settings/personal_access_tokens'
      : 'https://wanikani.com';

    return <NewTabLink href={href}>{children || 'WaniKani'}</NewTabLink>;
  }
}

export default WaniKaniLink;
