import classNames from 'classnames';
import withMouseAndKeyboardSelection from 'components/hoc/withMouseAndKeyboardSelection';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/RaisedButton.scss';

class RaisedButton extends Component {
  render() {
    const { className, focusOverride, disabled, ...otherProps } = this.props;

    return (
      <div
        className={classNames(className, 'raised-button', {
          'raised-button-focused': focusOverride,
          'raised-button-disabled': disabled,
        })}
        role="button"
        tabIndex={0}
        {...otherProps}
      />
    );
  }
}

export default withMouseAndKeyboardSelection(observer(RaisedButton));
