import { Collapse } from 'antd';
import SwitchItem from 'components/shared/SwitchItem';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/GamePreferences.scss';

const { Panel } = Collapse;

class GamePreferences extends Component {
  toggleShowKanjiHints = (checked) => {
    const { gameSettingsStore } = this.props;
    gameSettingsStore.setShowKanjiHints(checked);
  };

  toggleShowVocabHints = (checked) => {
    const { gameSettingsStore } = this.props;
    gameSettingsStore.setShowVocabHints(checked);
  };

  toggleAutoFocusInput = (checked) => {
    const { gameSettingsStore } = this.props;
    gameSettingsStore.setAutoFocusInput(checked);
  };

  toggleKeepKanjiInView = (checked) => {
    const { gameSettingsStore } = this.props;
    gameSettingsStore.setKeepKanjiInView(checked);
  };

  toggleIncreaseKanjiSize = (checked) => {
    const { gameSettingsStore } = this.props;
    gameSettingsStore.setIncreaseKanjiSize(checked);
  };

  render() {
    const { gameSettingsStore } = this.props;
    const { showKanjiHints, showVocabHints, autoFocusInput, keepKanjiInView, increaseKanjiSize } =
      gameSettingsStore;

    return (
      <Collapse defaultActiveKey="1">
        <Panel key="1" header="Game Preferences" showArrow>
          <div className="game-preferences">
            <SwitchItem
              checked={showKanjiHints}
              onChange={this.toggleShowKanjiHints}
              text="Show kanji hints"
            />
            <SwitchItem
              checked={showVocabHints}
              onChange={this.toggleShowVocabHints}
              text="Show vocab hints"
            />
            <SwitchItem
              checked={autoFocusInput}
              onChange={this.toggleAutoFocusInput}
              text="Auto focus input"
            />
            <div className="game-preferences game-preferences-desktop-only">
              <SwitchItem
                checked={keepKanjiInView}
                onChange={this.toggleKeepKanjiInView}
                text="Keep kanji in view"
              />
              <SwitchItem
                checked={increaseKanjiSize}
                onChange={this.toggleIncreaseKanjiSize}
                text="Increase kanji size"
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    );
  }
}

export default inject('gameSettingsStore')(observer(GamePreferences));
