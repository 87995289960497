import { Popconfirm } from 'antd';
import { action, observable, reaction, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

class ResetConfirmation extends Component {
  visible = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      visible: observable,
      confirm: action,
      cancel: action,
      handleVisibleChange: action,
    });
  }

  componentDidMount() {
    const { forceVisibleTrigger } = this.props;

    if (forceVisibleTrigger) {
      reaction(forceVisibleTrigger, this.handleVisibleChange);
    }
  }

  confirm = () => {
    this.visible = false;
    const { onReset } = this.props;
    onReset();
  };

  cancel = () => {
    this.visible = false;
  };

  handleVisibleChange = (visible) => {
    if (!visible) {
      // allow the dialog to close when the user clicks away
      this.visible = false;
      return;
    }

    const { gameStore } = this.props;
    if (gameStore.hasProgress && !gameStore.isWon) {
      this.visible = true;
    } else {
      // continue without showing the dialog
      this.confirm();
    }
  };

  render() {
    const { children, placement } = this.props;

    return (
      <Popconfirm
        title="This will clear your progress. Proceed?"
        okText="Yes"
        cancelText="No"
        placement={placement || 'bottom'}
        onConfirm={this.confirm}
        onCancel={this.cancel}
        visible={this.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        {children}
      </Popconfirm>
    );
  }
}

export default inject('gameStore')(observer(ResetConfirmation));
