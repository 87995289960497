import { flow, getRoot } from 'mobx-state-tree';

const withLevelLoading = (GameStore) =>
  GameStore.props({
    loading: false,
  })
    .views((self) => ({
      get dataStore() {
        return getRoot(self).dataStore;
      },
      get level() {
        return self.dataStore.level;
      },
    }))
    .actions((self) => ({
      afterAttach: flow(function* afterCreate() {
        if (!self.level) {
          yield self.loadDefaultLevel();
        }
      }),
      loadDefaultLevel: flow(function* loadDefaultLevel() {
        const success = yield self.loadLevel(1);
        return success;
      }),
      loadLevel: flow(function* loadLevel(level) {
        if (self.loading || self.level === level) return undefined;

        self.loading = true;
        const success = yield self.dataStore.loadLevel(level, self.onDataLoad);
        self.loading = false;
        return success;
      }),
    }));

export default withLevelLoading;
