import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import IconButton from 'components/shared/IconButton';
import MaterialIcon from 'components/shared/MaterialIcon';
import ResetConfirmation from './ResetConfirmation';

class InstantWinButton extends Component {
  instantWin = () => {
    const { gameStore } = this.props;
    gameStore.instantWin();
    message.success('Instant Win');
  };

  render() {
    const { className, gameStore } = this.props;

    return (
      <ResetConfirmation onReset={this.instantWin} placement="bottomRight">
        <IconButton
          className={className}
          title="Instant Win"
          disabled={gameStore.loading || gameStore.isWon}
        >
          <MaterialIcon>cake</MaterialIcon>
        </IconButton>
      </ResetConfirmation>
    );
  }
}

export default inject('gameStore')(observer(InstantWinButton));
