import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import 'styles/KanjiGrid.scss';
import KanjiCell from './KanjiCell';

class KanjiGrid extends Component {
  render() {
    const { gameStore } = this.props;

    return (
      <div
        className="kanji-grid"
        lang="ja"
        style={{
          gridTemplateColumns: `repeat(${gameStore.columnCount}, 1fr)`,
        }}
      >
        {gameStore.kanjiList.map((kanjiData) => (
          <KanjiCell key={kanjiData.id} kanjiData={kanjiData} />
        ))}
      </div>
    );
  }
}

export default inject('gameStore')(observer(KanjiGrid));
