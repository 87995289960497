import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import IconButton from 'components/shared/IconButton';
import MaterialIcon from 'components/shared/MaterialIcon';

class AutoSolveButton extends Component {
  handleClick = () => {
    const { gameStore } = this.props;
    gameStore.autoSolve();
    message.success('Auto Solved 1 Word');
  };

  render() {
    const { className, gameStore } = this.props;

    return (
      <IconButton
        className={className}
        title="Auto Solve"
        onClick={this.handleClick}
        disabled={gameStore.loading || gameStore.isWon}
      >
        <MaterialIcon>skip_next</MaterialIcon>
      </IconButton>
    );
  }
}

export default inject('gameStore')(observer(AutoSolveButton));
